import React, { useState, useEffect, useRef } from 'react'
import Popup from 'reactjs-popup';
import TopMenu from '../../components/TopMenu'
// import EnterTradeWarning from '../../components/warnings/EnterTradeWarning'

import Good from '../../assets/green-mark.svg'
import Copy from '../../assets/copy.svg'
import { Link, useLocation } from 'react-router-dom';

import Cookies from 'universal-cookie'
import axios from 'axios'

import { w3cwebsocket as WebSocket } from "websocket";
 

const cookies = new Cookies();
const token = cookies.get("TOKEN");
 
const START_TRADE_URL = 'https://api.lanealitrade.com/common/start-trading';
const STOP_TRADE_URL = 'https://api.lanealitrade.com/common/place-sell-order';
const SINGLE_GROUP_URL = 'https://api.lanealitrade.com/common/trading-segment-accounts/';


const GroupTrade = () => {

  
const [successful, setSuccessful] = useState(false);

const [err, setErr] = useState(false);

// const [theAccountId, setTheAccountID] = useState('');

 const [loading, setLoading ] = useState(false)

const [user, getUser ] = useState('');
const [theUsdt, setUsdt ] = useState('');
const [target, setTargetPair ] = useState('');
// const [error, getError ] = useState('');

const [pValue, getPValue ] = useState('');


const location = useLocation();


// const [chartData, setChartData] = useState(null);

// const [orderBookData, setOrderBookData] = useState(null);

const [tickerData, setTickerData] = useState(null);

const [webOrderBookData, setWebOrderBookData] = useState(null);

const [marketData, setMarketData] = useState(null);


const [stop, setStop] = useState("");

// const [price, setPrice] = useState("");

const [limit, setLimit] = useState("");

const [pairs, setPairs] = useState([]);

const [currencyPair, getCurrencyPair ] = useState('BTC');

const [activeTab, setActiveTab] = useState('section1');

const [pcent, setPsent] = useState('section1');

const [sellAmount, setSellAmount] = useState();

// BUY SELL TAB

const handleTabClick = (tab) => {
  setActiveTab(tab);
};




//   console.log(props, "this props yo");
//   console.log(location, "this location set");

const data = location.state;

//   console.log(location.state, "this location data?");
//   console.log(SINGLE_ACC_URL+data)


// const targetUser = user?.accountSegments?.account?.accountBalances?.find(accountBalances  => accountBalances.asset === "USDT").freeBalance;
// console.log(targetUser, "taget user usdt");

// const calcPercent = pValue / 100;

const [amount, setAmount] = useState();

 
// getting pairs
const newPair = toLowerCase(currencyPair)+"usdt";

const justPair = toUpperCase(currencyPair);

// make it uppercase
const uppercasePair = toUpperCase(newPair);

// const targetUserBTC = user?.accountBalances?.[1]?.find(accountBalances  => accountBalances.asset === currencyPair)?.freeBalance;

const getUserData = () => {
  axios.get(
      SINGLE_GROUP_URL+data,
      {
          headers: {
          'Content-Type': 'application/json',
            'accept': '*/*',
            Authorization: `Bearer ${token}`,
            }
      }
      ).then(
      (response) => {
          // console.log(response);
          console.log('single user response.data');
          console.log(response.data);
          const theUser = response.data;
          const putTargetPair = theUser?.accountSegments?.account?.accountBalances?.find(accountBalances => accountBalances.asset === currencyPair)
          console.log(putTargetPair, "put targetpsir");
          theUser?.accountSegments?.map((person) => 
          {
            const targetUSDT = person?.account?.accountBalances?.find(accountBalances  => accountBalances.asset === "USDT");
            const targetPair = person?.account?.accountBalances?.find(accountBalances  => accountBalances.asset === currencyPair)?.freeBalance;
            console.log(targetUSDT?.freeBalance, 'DOLAR?');
              setUsdt(targetUSDT?.freeBalance)
              setTargetPair(targetPair)
              console.log(target, 'na him'); 
          });
          // console.log(summap, 'summap?');
          getUser(theUser);
      }
  ).catch((error) => {
      console.log(error);
      console.log(error.message);
      const theError = error.message;
      // getError(theError);
  } )
}

  
const startTrade = async (e) => {
  e.preventDefault();
  console.log(e.currentTarget.id, );
//  setTheAccountID(e.currentTarget.id); 

  const tradingConfig = {
    method: "post",
    url: START_TRADE_URL,
    data: {
      segmentId: user.id,
      accountId: null,
      orderType: null,
      timeInForce: null,
      symbol: justPair+"/USDT",
      price: limit,
      limitType: "STOP_LOSS_LIMIT",
      priceType: "PERCENT", 
      stopPrice: stop,
      quantity: amount,
    },
     headers: {
        'Content-Type': 'application/json',
           'accept': '*/*',
            Authorization: `Bearer ${token}`,
        },
        // withCredentials: true
  };

  try {
    setLoading(true)
    await axios(tradingConfig).then((result) => {
      console.log(result); 
      console.log(result.data.token);
      console.log(result);
      
      const theError = result.data

        theError.map((sort) => {
          // console.log(sort.account, 'account');
          // console.log(sort.error, 'error');

          if (sort.error == null) {
            console.log(sort.order, 'order');
            setSuccessful(true);
            
          } else {
            console.log(sort.error, 'error');
            setSuccessful(false);
            setErr('failed')
            
          }
        })
        // console.log(calcPercent, "percentage");
        setLoading(false);
    }).catch((error )=> {
      console.log("this error");
      console.log(error);
      console.log(error.response.data.message);  
     
    }
    )

  } catch (err) {

    // this part might just be redundant but leave it here
     
    // errRef.current.focus();
  }

}

 const allFalse = (e) => {
    e.preventDefault()
    setErr(false)
    setSuccessful(false) 
    return null
  }

  
  useEffect(() => {
    setErr(false);
  }, [])

  useEffect(() => {
    setSuccessful(false);
  }, [])
    
  
const sellTrade = async (e) => {
  e.preventDefault();
  console.log(e.currentTarget.id, );
//  setTheAccountID(e.currentTarget.id);
  // console.log(users.id);

  // const getAccountID = theAccountId;
  const tradingConfig = {
    method: "post",
    url: STOP_TRADE_URL,
    data: {
      segmentId: user.id,
      accountId: null,
      orderType: null,
      timeInForce: null,
      symbol: justPair+"/USDT",
      price: limit,
      limitType: "STOP_LOSS_LIMIT", 
      stopPrice: stop,
      quantity: sellAmount,
    },
     headers: {
        'Content-Type': 'application/json',
           'accept': '*/*',
            Authorization: `Bearer ${token}`,
        },
        // withCredentials: true
  };

  try {
    setLoading(true)
    await axios(tradingConfig).then((result) => {
      console.log(result);
      // const decoded = jwtDecode(result.data.token);
      console.log(result.data.token);
      console.log(result);

      const theError = result.data

        theError.map((sort) => {
          // console.log(sort.account, 'account');
          // console.log(sort.error, 'error');

          if (sort.error == null) {
            console.log(sort.order, 'order');
            setSuccessful(true);
            
            // window.location.href = "/";
            // alert('word')
          } else {
            console.log(sort.error, 'error');
            setSuccessful(false);
            setErr('failed')
            
          }
        })
      
      // window.location.href = "/";
      setLoading(false);
      // alert("yes");
    }).catch((error )=> {
      console.log("this error");
      console.log(error);
      console.log(error.response.data.message); 
    }
    )
  } catch (err) {
    // this part might just be redundant but leave it here
    // errRef.current.focus();
  }
}



// useEffect(() => {
//   const fetchChartData = async () => {
//     try {
//       // const response = await axios.get(`https://api.binance.com/api/v3/klines?symbol=${uppercasePair}&interval=12h`);
//       // const data = response.data;

//       // const chartLabels = data.map((d) => new Date(d[0]).toLocaleTimeString());
//       // const chartPrices = data.map((d) => parseFloat(d[1]));

//       // const chartData = {
//       //   labels: chartLabels,
//       //   datasets: [
//       //     {
//       //       label: 'BTCUSDT Price',
//       //       data: chartPrices,
//       //       fill: false,
//       //       borderColor: 'rgb(75, 192, 192)',
//       //       tension: 0.1
//       //     }
//       //   ]
//       // };

//       // setChartData(chartData);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   fetchChartData();
// }, []);

useEffect(() => {
  const fetchOrderBookData = async () => {
    try {
      const response = await axios.get(`https://api.binance.com/api/v3/depth?symbol=${uppercasePair}`);
      const data = response.data;

      const bids = data.bids.slice(0, 10).map((bid) => ({
        price: parseFloat(bid[0]),
        quantity: parseFloat(bid[1])
      }));

      const asks = data.asks.slice(0, 10).map((ask) => ({
        price: parseFloat(ask[0]),
        quantity: parseFloat(ask[1])
      }));

      // setOrderBookData({ bids, asks });
    } catch (error) {
      console.error(error);
    }
  };

  fetchOrderBookData();
}, [newPair]);


//   BINANCE MARKET DATA
  useEffect(() => {
  const fetchTickerData = async () => {
    try {
      const response = await axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=${uppercasePair}`);
      const data = response.data;
      // console.log("{tickerData.volume} data ");
      // console.log(data);
      setTickerData(data);
    } catch (error) {
      console.error(error);
    }
  };

  fetchTickerData();
}, [newPair]);



// websocket
useEffect(() => {
  const socket = new WebSocket(
    `wss://stream.binance.com:9443/ws/${newPair}@depth`
  );

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    const webBid = data.bids;
    // console.log(data.b.slice(0, 5))
    setWebOrderBookData(data);
  };

  return () => {
    socket.close();
  };
}, [currencyPair]);

// web soc market data
useEffect(() => {
  const socket = new WebSocket(
    `wss://stream.binance.com:9443/ws/${newPair}@ticker`
  );

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    // console.log(data);
    setMarketData(data);
  };

  return () => {
    socket.close();
  };
}, [currencyPair]);


  //   
  // 
useEffect(() => {
  getUserData();
  }, []);

  // Get Pairs
  useEffect(() => {
    axios
      .get("https://api.binance.com/api/v3/exchangeInfo")
      .then((response) => {
        const usdtPairs = response.data.symbols.filter(
          (symbol) => symbol.quoteAsset === "USDT"
        );
        const pairsWithoutUsdt = usdtPairs.map((pair) =>
          pair.baseAsset.toUpperCase()
        );
        const sortedPairs = pairsWithoutUsdt.sort();
        setPairs(sortedPairs);
      })
      .catch((error) => 
      console.log(error)
      );
  }, []);
  const selectPercent = (e) => {
    // getPValue(e.target.value);

    var thePerc = e.target.value;
    setPsent(thePerc)
    var calc = thePerc / 100
    getPValue(calc);

    // BUY CALC
    const massPercent = theUsdt * calc;
    const getQuantity = massPercent / limit;
    console.log(thePerc, "percentage value")
    console.log(limit, "limit value")
    console.log(calc, "calc percentage is") 
    console.log(getQuantity, "quantity percent")

    // SELL CALC
    const sellPercent = target * calc;
    // const sellQuantity = sellPercent / limit;
    console.log(sellPercent, "sell percentage is") 
    // console.log(sellQuantity.toFixed(8), "sell quantity amount", targetUserBTC)
    
    setAmount(getQuantity.toFixed(8)) 
    setSellAmount(sellPercent.toFixed(8)) 
  }
  useEffect(() => {
    console.log(pValue, "useEffect percentage is")
  }, [pValue, target, currencyPair]);


  const selectCurrencyPair = (e) => {
    // getCurrencyPair(e.target.value);
    var thePair =  e.target.value;
    getCurrencyPair(thePair);
    console.log(currencyPair, "currencyPair");
    console.log(thePair, "the pair");

  }

  function toUpperCase(currencyPair) {
    if (typeof currencyPair !== 'string') {
      throw new Error('The argument must be a string');
    }
  
    if (currencyPair.toUpperCase() === currencyPair) {
      return currencyPair;
    }
  
    return currencyPair.toUpperCase();
  }

  // MAKE PAIR LOWER CASE
  function toLowerCase(currencyPair) {
    if (typeof currencyPair !== 'string') {
      throw new Error('The argument must be a string');
    }
  
    if (currencyPair.toLowerCase() === currencyPair) {
      return currencyPair;
    }
  
    return currencyPair.toLowerCase();
  }


  const textRef = useRef(null);

function copyToClipboard() {
  const text = marketData?.c;
  const tempInput = document.createElement("input");
  document.body.appendChild(tempInput);
  tempInput.value = text;
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
}

  




return (
  <div>
      <TopMenu />
      <div className='lg-flex h-100vh align-center pl-4 pr-4'>
          {/* left */}
          <div className='w-100'>
              <div className='lg-flex j-sb'>
              <div className='w-50'>
                      <p className='fw-700 grey'>Account Name</p> 
                  </div>
                  <div className='w-100'>
                      <h2 className='capitalize'> {user.name}     
                      {/* ${user.accountBalances[6].freeBalance} */}
                      </h2>
                  </div>
              </div>
              <div className='lg-flex mb-flex  align-center j-sb pb-1 pt-1'>
                  <div className='w-50'>
                      <p className='fw-700 grey'>Platform</p> 
                  </div>
                  <div className='w-100'>
                      {/* <select className='w-100 pt-1 pb-1 border-radius-10 f-16 fw-600'> */}
                          <h2 className='capitalize'>{user.platform}</h2>
                      {/* </select> */}
                  </div>
              </div>
              <div className='lg-flex mb-flex align-center j-sb'>
                  <div className='w-50'>
                      <p className='fw-700 grey'>Currency pair</p> 
                  </div>
                  <div className='w-100 pb-1'>
                  <select onChange={selectCurrencyPair} className='w-100 pt-1 pb-1 border-radius-10 f-16 fw-600'>
                          <option value={'BTC'}>BTC/USDT</option>
                          {pairs.map((pair) => <option value={pair} key={pair}>{pair}/USDT</option>)};
                          {/* <option value={"btc"}>BTC/USDT</option>
                          <option value={"bnb"}>BNB/USDT</option>
                          <option value={"eth"}>ETH/USDT</option>
                          <option value={"xrp"}>XRP/USDT</option> */}
                      </select>
                  {/* <h1 className='w-100 pt-1 pb-1 pl-1 border-1 border-radius-10 f-16 fw-600'>
                          {tickerData?.symbol}
                      </h1> */}
                  </div>
              </div>
              <div className='lg-flex mb-flex'>
                      <div className='w-50'></div>
                  <div className='w-100 lg-flex'>
                      <div className='pr-1'>
                          <p className='fw-700'>24h Price Change</p>
                          <span className='f-12'>${marketData?.p}</span>
                      </div>
                      <div>
                          <hr />
                      </div>
                      <div>
                          <p className='fw-700'>24h Change % </p>
                          
                          <span className='f-12'>{
                              marketData?.P[0] === "-" ? 
                                  <div className='red fw-700'>{marketData?.P}%</div> : 
                                  <div className='green fw-700'>{marketData?.P}%</div>
                              }
                          </span>
                      </div>
                  </div>
              </div>
              <div className='lg-flex mb-flex'>
                  <div className='w-50'></div>
                  <div className='w-100 lg-flex'>
                      <div className='pr-1'>
                          <p className='fw-700'>24h High</p>
                          <span className='f-12'>{marketData?.h}</span>
                      </div>
                      <div>
                          <p className='fw-700'>24h Low</p> 
                          <span className='f-12'>{marketData?.l}</span>
                      </div>
                  </div>
              </div>
              <div className='lg-flex'>
                  <div className='w-50'>
                  </div>
                  <div className='w-100'>
                      <hr />
                      <p className='fw-700'>24h Volume</p> 
                      <span className='f-12'> {marketData?.v}</span>
                  </div>
              </div>
              <div className='lg-flex'>
                  <div className='w-50'>
                  </div>
                  <div className='w-100'>
                      <hr />
                      <div>
      {/* <h1>Binance websocket Order Book Data for BTCUSDT:</h1> */}
      {webOrderBookData ? (
        <div className=' pb-1'>
        <div className='pr-2'>
          {/* Bids: */}
          <div className='pt-1 lg-flex'>
            <p className='fw-700 pr-2'>
              Price (USDT)
            </p>
            <p className='fw-700'>
              Amount ({justPair})
            </p>
          </div>
          <div>
            {webOrderBookData?.b?.slice(0, 5).map((bid, index) => (
              <div className='lg-flex'
                key={index}
              >
                <p className='f-12 pr-2'>
                  {`${bid[0]}`}
                </p>
                <p className='f-12 '>
                  {`${bid[1]}`}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className='lg-flex pl-2'>

          {/* CURRENT MARKET */}
        {marketData?.c}
        </div>
        <div>
          {/* Asks: */}
          <div>
            {webOrderBookData?.a?.slice(0, 5).map((ask, index) => (
              <div 
              className='red lg-flex' 
              key={index}>
                <p className='f-12 pr-2'>
                  {`${ask[0]}`}
                </p>
                <p className='f-12 '>
                  {`${ask[1]}`}
                </p>
                </div>
            ))}
          </div>
        </div>
      </div>
      ) : (
        <p>Loading order book data...</p>
      )}
    </div>
                  </div>
              </div>

              <div>
                  
              </div>


<div>
   
  </div>

          </div>

          {/*  */}
          <div className='pl-2 h-50 pr-2'>
              <hr className='w-50' />
          </div>
          {/* right */}

          <div className='w-100 '>
            <div className='tab'>
          
              <button className={activeTab === "section1" ? "active popup-btn bg-purple white mr-1 fw-400  pt-1 pb-1" : "popup-btn  mr-1 fw-400  pt-1 pb-1"} onClick={() => handleTabClick("section1")}>Buy</button>
              <button className={activeTab === "section2" ? "active popup-btn bg-purple white mr-1 fw-400  pt-1 pb-1" : "popup-btn  mr-1 fw-400  pt-1 pb-1"} onClick={() => handleTabClick("section2")}>Sell</button>
          
            </div>

            {/* BUY TAB */}
            <div className='content'>
            {activeTab === "section1" && (
              <div>
                 <div>
                  
                  <div className=' pt-2 pb-1'>
                    <input
                      value={stop} placeholder={`Stop (${justPair})`}
                      onChange={(event) => setStop(event.target.value)}
                    />
                  </div>
                  <div className='pb-1'>
                  <h2 className=''>current Price</h2>
                  <p className=' lg-flex align-center'> 
                    <div className='f-12 fw-700 pb-1'>{tickerData?.lastPrice}</div>
                    <img onClick={copyToClipboard} src={Copy} className='pb-1 pointer' width={"2%"} alt="Click to copy"/>
                  </p>
                    <input
                      value={limit} placeholder='Limit'
                      onChange={(event) => setLimit(event.target.value)}
                    />
                  </div>
                  <div className='pb-1'>
                    <input
                      value={amount || ''} placeholder={`Amount ${justPair}`}
                      onChange={(event) => setAmount(event.target.value)}
                    />
                  </div>
                  <div className='pb-1'>
                    <select onChange={selectPercent} className='  '>
                      <option>Trade percentage</option>
                      <option value={"1"}>1</option>
                      <option value={"10"}>10</option>
                      <option value={"15"}>15</option>
                      <option value={"20"}>20</option>
                    </select>
                  </div>
                  
                  <div>
                    Available: {theUsdt} USDT<br />

                  </div>
                  

                  <Popup trigger={<button  className= 'popup-btn bg-purple white mr-1 fw-400  pt-1 pb-1'><p>Buy {justPair}</p></button>}
                      modal
                      nested
                  >
                      {
                          close => (
                              <div className="modal lg-flex mb-flex j-center align-center">
                                  <div className=" lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-50 w-50 bg-white">
                                      <div className=" pl-2 pr-2 text-center">
                                          <div className="">
                                              <img src={Good} alt="account creation successful"/>
                                          </div>
                                          <div className="pt-4">
                                              <h1 className="f-14 fw-700 pr-1 ">Are you sure you want to trade {pcent}% of {user.name} balance </h1>
                                          </div>
                                          <div className="pt-4">
                                            {
                                              loading ?
                                              (<div>
                                                please wait...
                                              </div>) : successful ? (
                                              <div className="l  j-center">
                                            
                                                {/* <Link to={'/home'}>
                                                    <button onClick={close} className=" popup-btn bg-purple white mr-1 h-100 fw-100"   ><p className=" f-12 fw-100">Finish</p></button>
                                                </Link>
                                                     */}
                                                     <p>Order Created</p>
                                                <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Finish</p></button>
                                              </div> 
                                              ) : err ? (

                                              <div> 
                                                <p>{err}</p> <br />
                                                <div className="lg-flex mb-flex j-center">
                                                  <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-purple white " onClick={allFalse}><p className="f-12">Try again</p></button>
                                                  <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Cancel</p></button>
                                                </div>
                                              
                                              </div> 
                                              ) : (
                                              <>
                                              <div className="lg-flex mb-flex j-center">
                                            
                                            <Link to={'/home'}>
                                                <button onClick={startTrade} className=" popup-btn bg-purple white mr-1 h-100 fw-100"   ><p className=" f-12 fw-100">Trade</p></button>
                                            </Link>
                                                
                                            <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={close}><p className="f-12">Cancel</p></button>
                                          </div>
                                              </>
                                              )
                                              
                                            }
                                          </div>
                                          
                                      </div>
                                  </div>
                              </div>
                          )
                      }
              </Popup> 
                </div>
              </div>
            )}


            {/* SELL TAB */}
            {activeTab === "section2" && (
              <div>
                <div>
                  
                  <div className=' pt-2 pb-1'>
                    <input
                      value={stop} placeholder={`Stop (${justPair})`}
                      onChange={(event) => setStop(event.target.value)}
                    />
                  </div>
                  <div className='pb-1'>
                  <h2 className=''>current Price</h2>
                  <p className=' '> 
                    <div className='f-12 fw-700 pb-1'>{tickerData?.lastPrice}</div>
                  </p>
                    <input
                      value={limit} placeholder='Limit'
                      onChange={(event) => setLimit(event.target.value)}
                    />
                  </div>
                  <div className='pb-1'>
                  <input
                      value={amount || ''} placeholder={`Amount ${justPair}`}
                      onChange={(event) => setAmount(event.target.value)}
                    />
                  </div>
                  <div className='pb-1'>
                    <select onChange={selectPercent} className='  '>
                      <option>Trade percentage</option>
                      <option value={"1"}>1</option>
                      <option value={"10"}>10</option>
                      <option value={"15"}>15</option>
                      <option value={"20"}>20</option>
                    </select>
                  </div>

                  <div className='lg-flex align-center'>
                    Available: {target == null ? <p className='pl-1'> 0</p> : target} {justPair}<br />

                   
                  </div>
                  

                  <Popup trigger={<button className= 'popup-btn bg-purple white mr-1 fw-400  pt-1 pb-1'><p>Sell {justPair}</p></button>}
                      modal
                      nested
                  >
                      {
                          close => (
                              <div className="modal lg-flex mb-flex j-center align-center">
                                  <div className=" lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-50 w-50 bg-white">
                                      <div className=" pl-2 pr-2 text-center">
                                          <div className="">
                                              <img src={Good} alt="account creation successful"/>
                                          </div>
                                          <div className="pt-4">
                                              <h1 className="f-14 fw-700 pr-1 ">Are you sure you want to trade {pcent}% of {user.name} balance </h1>
                                          </div>
                                          <div className="pt-4">
                                            {
                                              loading ?
                                              (<div>
                                                please wait...
                                              </div>) : successful ? (
                                              <div className="l  j-center">
                                            
                                                {/* <Link to={'/home'}>
                                                    <button onClick={close} className=" popup-btn bg-purple white mr-1 h-100 fw-100"   ><p className=" f-12 fw-100">Finish</p></button>
                                                </Link>
                                                     */}
                                                     <p>Order Created</p>
                                                <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Finish</p></button>
                                              </div> 
                                              ) : err ? (

                                              <div> 
                                                <p>{err}</p> <br />
                                                <div className="lg-flex mb-flex j-center">
                                                  <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-purple white " onClick={allFalse}><p className="f-12">Try again</p></button>
                                                  <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Cancel</p></button>
                                                </div>
                                              
                                              </div> 
                                              ) : (
                                              <>
                                              <div className="lg-flex mb-flex j-center">
                                            
                                            <Link to={'/home'}>
                                                <button onClick={sellTrade} className=" popup-btn bg-purple white mr-1 h-100 fw-100"   ><p className=" f-12 fw-100">Trade</p></button>
                                            </Link>
                                                
                                            <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Cancel</p></button>
                                          </div>
                                              </>
                                              )
                                              
                                            }
                                          </div>
                                          
                                      </div>
                                  </div>
                              </div>
                          )
                      }
              </Popup> 
                </div>
              </div>
            )}
               
                  
            </div> 
          
          </div>
      </div>
  </div>
)
}

export default GroupTrade
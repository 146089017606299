import React from 'react';
import {Link } from 'react-router-dom';
import '../../App.css'
import LogoBird from '../../assets/logo-bird-2.svg'


const SplashScreen = () => {
    return (
        <div>
            <div className='h-100vh lg-flex mm-flex align-center j-center'>
                <div className='splash w-70 h-sm-vh h-70 lg-flex border-radius-30 mb-flex j-center align-center' >
                        <div className=' pl-2 pr-2 w-100'>
                            <div className='pt-1 pb-1'>
                                <img src={LogoBird} alt='logo bird' className='w-50' />
                            </div>
                            <div className='w-50'>
                                <div>
                                <Link to={'/signin'} className='w-50'>
                                    <button className='button black border-0'>
                                        Sign in
                                    </button>
                                </Link>
                                </div>
                            </div>
                            <div className='mb-flex j-center w-50 pt-1 pb-1'>
                                <p className='capitalize white'>
                                    digital trading platform
                                </p>
                            </div>
                        </div>
                        <div className=' w-100 bg-grey mb-hidden'>
                            {/* <h1></h1> */}
                        </div>
                </div>
            </div>
        </div>
      )
}

export default SplashScreen
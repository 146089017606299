import React from 'react'

const UnAuthorized = () => {
  return (
    <div>
        <h1 className='f-100'>
            You are not authorized here
        </h1>
        <p>go back</p>
    </div>
  )
}

export default UnAuthorized
// import { Link } from 'react-router-dom'


// import HomeIcon from '../assets/home.svg'
// import HistoryIcon from '../assets/transact-history.svg'
// import LoginsIcon from '../assets/lock.svg'
// import SettingsIcon from '../assets/setting.svg'


import CloudIcon from '../assets/cloud.svg'
import GroupUser from '../assets/group-user.svg'
import {  NavLink } from 'react-router-dom'
import AddAPIPopup from './AddAPIPopup'
import { useState } from 'react'
import Popup from 'reactjs-popup'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import axios from 'axios'

import Cookies from 'universal-cookie'
import { useEffect } from 'react'

const cookies = new Cookies();
const token = cookies.get("TOKEN");



const Header = () => {

  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'
    },
    duration: 4000,
    dismissable: true,
  })

  // const successToast = () => {
  //   notyf.success("Oh Yeah")
  // } 

  // const [users, getUsers ] = useState('');
  
  // const [successful, setSuccessful] = useState(false);

  // const [error, getErrors ] = useState('');
  
  const [loading, setLoading] = useState(false);

  // const [status, setStatus ] = useState('')

  const backup = async () => {
    try {
      setLoading(true)
      await axios.get(
          'https://api.lanealitrade.com/backup',
          {
              headers: {
              'Content-Type': 'application/json',
                'accept': '*/*',
                Authorization: `Bearer ${token}`,
                }
          }
          ).then(
          (response) => {
              // console.log(response.data);
              // const allUsers = response.data;
              // getUsers(allUsers);
               
              // // console.log(filteredStatus, 'filleds');
              setLoading(false)
              notyf.success("Successful")
          }
      ).catch((error) => {
          // console.log(error);
          // console.log(error.message);
          // const theError = error.message;
          // getErrors(theError);
          setLoading(false) 
          notyf.error('Failed: Something went wrong')
      } )
      setLoading(false) 
    } catch (error) {
      setLoading(false)
    }
  }


  
    useEffect(() => {
        // backup();
        // startTrade();
    },[])


  // const [openModal, setOpenModal ] = useState (false);
  return (
    <div className='pt-4-5'>
    <div className=' fixed bg-white w-100 ml-6 lg-flex  '>
        <NavLink to={'/trading-groups'} className='link'>
            <div className='icon-box-text mr-1 mb-1  '>
                <img src={GroupUser}  alt='logo' width={'20%'} />
                <span>My trading groups</span>
        </div>
        </NavLink>
        {
          loading ? (
        <NavLink  className='link'>
            <div className='icon-box-text mr-1 mb-1 '>
                <img src={CloudIcon}  alt='logo' width={'20%'} />
                <span>Loading</span>
        </div>
        </NavLink>) :
         (
        <NavLink onClick={backup} className='link'>
            <div className='icon-box-text mr-1 mb-1 '>
                <img src={CloudIcon}  alt='logo' width={'20%'} />
                <span>Backup to cloud</span>
        </div>
        </NavLink>)
        }
        <Popup trigger={<button className=' pointer mb-1 mr-1 link bg-white lg-flex mb-flex align-center pl-1 pr-1 border-radius-30 dropshadow border-1'>
            <h1 className='purple fw-400'>+</h1>
            <p className=''>Add new account</p>
        </button>}
        modal
        nested
         >
          {
            close => (

              <AddAPIPopup onClose={close} />
            )
          }
         </Popup>
        
    </div>
  </div>
  )
}

export default Header
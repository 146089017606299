import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import TopMenu from '../../components/TopMenu'

import Cookies from 'universal-cookie'
import axios from 'axios'

const cookies = new Cookies();
const token = cookies.get("TOKEN");


const LOGIN_URL = 'https://api.lanealitrade.com/common/gross-summary-per-login-time';

const Logins = () => {

  
  const [loading, setLoading] = useState(false);
  const [error, getErrors ] = useState('');
  const [logins, setLogins ] = useState('')
  
  const getLogins = async () => {
    try {
      setLoading(true)
      await axios.get(
          LOGIN_URL,
          {
              headers: {
              'Content-Type': 'application/json',
                'accept': '*/*',
                Authorization: `Bearer ${token}`,
                }
          }
          ).then(
          (response) => {
              // // console.log(response);
              // // console.log('response.data');
              // console.log(response.data);
              // console.log(response);
              const allUsers = response.data;
              setLogins(allUsers);
              setLoading(false)
          }
      ).catch((error) => {
          // console.log(error);
          // console.log(error.message);
          const theError = error.message;
          getErrors(theError);
          setLoading(false) 
      } )
      setLoading(false) 
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getLogins();
  },[])



  return (
    <div >
      <TopMenu />
      <SideNav />
    <Header />
      <div className='main-doc'>
        <div className=' mt-2 ml-1 pb-2'>
            

          {/* TABLE FORM */}
          <div className='wrapper border-1 border-radius-5 mb-2'>
            <div class="table w-100">
                    <div class="row fw-600 light-blue">
                        <div class="cell">SN</div>
                        <div class="cell">Time</div>
                        <div class="cell">Device</div>
                        <div class="cell">Location</div>
                        <div class="cell">Transactions Summary</div>
                        <div class="cell">Account accessed</div>
                    </div>

                    {
                      logins?.length > 0 ? (
                        logins?.map((login, index) => {
                          return (
                            <div class="row f-14">
                                <div class="cell">{index + 1}</div>
                                <div class="cell">{login.loginTime}</div>
                                <div class="cell">{login.operatingSystem}</div>
                                <div class="cell">{login.ipAddress}</div>
                                <div class="cell">{login.browser}</div>
                                <div class="cell">767S77632HJ</div>
                            </div>                     

                          )
                        })
                      ) : (
                        <div>
                          no logins
                          </div>
                      )
                    }
                                         
            </div>
          </div>

          

        </div>
      </div>
    </div>
  )
}

export default Logins
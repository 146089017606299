// import React, { useState } from 'react';

const RangeSlider = ({ min, max, value, step, handleChange  }) => {

  
  
  return (
    <div className='w-100'> 
      <input className='w-100'
        type="range"
        min={min}
        max={max}
        value={value} 
        step={step}
        onChange={handleChange}
      />
      <p>{value}%</p>
    </div>
  );
};

export default RangeSlider;

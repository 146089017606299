import React, { useState, useEffect, useRef } from 'react'
import Popup from 'reactjs-popup';
import TopMenu from '../../components/TopMenu'
// import EnterTradeWarning from '../../components/warnings/EnterTradeWarning'

import Good from '../../assets/green-mark.svg'
import Copy from '../../assets/copy.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie'
import axios from 'axios'

import { w3cwebsocket as WebSocket } from "websocket";

// import TradeViewChart from 'react-crypto-chart';
import CustomLoader from '../../components/CustomLoader';
import RangeSlider from '../../components/RangeSlider';
import ChartJs from '../../components/Chart';

const cookies = new Cookies();
const token = cookies.get("TOKEN");



const START_TRADE_URL = 'https://api.lanealitrade.com/common/start-trading';
const STOP_TRADE_URL = 'https://api.lanealitrade.com/common/place-sell-order';
const SINGLE_ACC_URL = 'https://api.lanealitrade.com/common/trading-segment-accounts/';


const TradeFuturesGroup = () => {

    // const [openModal, setOpenModal ] = useState (false);

    
  const [successful, setSuccessful] = useState(false);

  const [err, setErr] = useState(false);

  const [theAccountId, setTheAccountID] = useState('');

  const [loading, setLoading ] = useState(false)
  
  const [user, getUser ] = useState('');
  const [error, getError ] = useState('');

  const [pValue, getPValue ] = useState('');

  const location = useLocation();

  
  const [chartData, setChartData] = useState(null);
  
  const [orderBookData, setOrderBookData] = useState(null);

  const [tickerData, setTickerData] = useState(null);
  
  const [webOrderBookData, setWebOrderBookData] = useState(null);

  const [marketData, setMarketData] = useState(null);

  
  const [stop, setStop] = useState("");

  // const [price, setPrice] = useState("");

  const [limit, setLimit] = useState("");

  const [pairs, setPairs] = useState([]);

  const [currencyPair, getCurrencyPair ] = useState('BTC');

  // const [activeTab, setActiveTab] = useState('section1');

  const [limitTab, setLimitTab] = useState('LIMIT');

  const [theOrderTab, setOrderTab] = useState('LIMIT');

  // const [spotTab, setSpotTab] = useState('future');

  const [pcent, setPsent] = useState(''); 
  
  const [bids, setBids] = useState([]);
  const [asks, setAsks] = useState([]);
  
  const handleLimitClick = (tab) => {
    setLimitTab(tab);
    // console.log(tab, 'tab')
    setOrderTab(tab)
    // // console.log(theOrderTab, 'the tab')
    setLimit('');
    setAmount('');
    setStop('');
  };

  useEffect(() => {
    // Callback function to execute when theOrderTab changes
    // console.log(theOrderTab, 'the tab');
    // Additional logic here...
  }, [theOrderTab]);

//   // console.log(props, "this props yo");
//   // console.log(location, "this location set");
  
  const data = location.state;
  
//   // console.log(location.state, "this location data?");
//   // console.log(SINGLE_ACC_URL+data)

const [futureBal, setFutureBal] = useState('');

const [theSegmentId, setSegmentId] = useState('');

const targetUser = futureBal?.accountBalances?.find(accountBalances  => accountBalances.asset === "USDT").balance;
// // console.log(user, 'tar')

 
const [amount, setAmount] = useState('');

const [marketAmount, setTheMarketAmount] = useState('');

const [marketLimit, setTheMarkeLimit] = useState('');

const [sellAmount, setSellAmount] = useState('');




// getting pairs
const newPair = toLowerCase(currencyPair)+"usdt";

const justPair = toUpperCase(currencyPair);

// make it uppercase
const uppercasePair = toUpperCase(newPair);

const targetUserBTC = user?.accountBalances?.find(accountBalances  => accountBalances.asset === currencyPair)?.balance;

 
  const getUserData = () => {
    axios.get(
        SINGLE_ACC_URL+data,
        {
            headers: {
            'Content-Type': 'application/json',
              'accept': '*/*',
              Authorization: `Bearer ${token}`,
              }
        }
        ).then(
        (response) => {
            // // console.log(response);
            // console.log('single user response.data');
            // console.log(response.data);
            const theUser = response.data;
            // console.log(theUser, 'user')
            getUser(theUser);
            theUser.accountSegments.map((account) => {
              // console.log(account.account)
              // console.log(account , 'accounts')
              setFutureBal(account.account)
              setSegmentId(account.segmentId);
              // console.log(account.segmentId, 'segment')
            })
        }
    ).catch((error) => {
        // console.log(error);
        // console.log(error.message);
        const theError = error.message;
        getError(theError);
    } )
}
 
    
  const startTrade = async (e) => {
    e.preventDefault();
    // console.log(e.currentTarget.id, );
   setTheAccountID(e.currentTarget.id); 
   // console.log(theOrderTab, 'ordertype?')

    const tradingConfig = {
      method: "post",
      url: START_TRADE_URL,
      data: {
        side: 'BUY',
        segmentId: theSegmentId,
        accountId: null,
        orderType: theOrderTab,
        timeInForce: null,
        symbol: justPair+"/USDT",
        price: limit === '0' || limit === null || limit === '' ? marketLimit : limit,
        // limitType: ,
        priceType: "PERCENT", 
        stopPrice: stop,
        quantity: pValue,
        // quantity: amount === 'Infinity' ? marketAmount : amount,
      },
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*',
              Authorization: `Bearer ${token}`,
          },
          // withCredentials: true
    };

    try {
      setLoading(true)
      await axios(tradingConfig).then((result) => {
        // console.log(result);
        // const decoded = jwtDecode(result.data.token);
        // console.log(result.data.token);
        // console.log(result);
        // console.log(result.data);

        const theError = result.data

        theError.map((sort) => {
          // // console.log(sort.account, 'account');
          // // console.log(sort.error, 'error');

          if (sort.error == null) {
            // console.log(sort.order, 'order');
            setSuccessful(true);
            
            // window.location.href = "/";
            // alert('word')
          } else {
            // console.log(sort.error, 'error');
            setSuccessful(false);
            setErr(sort.error)
            
          }
        })
        setLoading(false)
        
        // setSuccessful(true);
        // window.location.href = "/";
        // alert("yes");
      }).catch((error )=> {
        setLoading(false)
        // console.log("this error");
        // console.log(error);
        setErr(error.response.data.message  )
        // console.log(error.response.data.message);  
        
      }
      )

    } catch (err) {

      // this part might just be redundant but leave it here
       
      // errRef.current.focus();
    }
  
  }

  const allFalse = (e) => {
    e.preventDefault()
    setErr(false)
    setSuccessful(false) 
    return null
  }

  // useEffect(() => {
  //   allFalse()
  // }, [])

  useEffect(() => {
    setErr(false);
  }, [])

  useEffect(() => {
    setSuccessful(false);
  }, [])
    
  const sellTrade = async (e) => {
    e.preventDefault();
    // console.log(e.currentTarget.id, );
   setTheAccountID(e.currentTarget.id);
    // // console.log(users.id);
 
    // const getAccountID = theAccountId;
    const tradingConfig = {
      method: "post",
      url: STOP_TRADE_URL,
      data: {
        side: 'SELL',
        segmentId:theSegmentId,
        accountId:  null,
        orderType: theOrderTab,
        timeInForce: null,
        symbol: justPair+"/USDT",
        price: limit === null || limit === '0' ? marketLimit : limit,
        // limitType: ,
        priceType: "PERCENT", 
        stopPrice: stop,
        quantity: pValue,
        // quantity: amount === 'Infinity' ? marketAmount : amount,
      },
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*',
              Authorization: `Bearer ${token}`,
          },
          // withCredentials: true
    };

    try {
      setLoading(true)
      await axios(tradingConfig).then((result) => {
        // console.log(result);
        // const decoded = jwtDecode(result.data.token);
        // console.log(result.data.token);
        // console.log(result);

        const theError = result.data

        theError.map((sort) => {
          // // console.log(sort.account, 'account');
          // // console.log(sort.error, 'error');

          if (sort.error == null) {
            // console.log(sort.order, 'order');
            setSuccessful(true);
            
            // window.location.href = "/";
            // alert('word')
          } else {
            // console.log(sort.error, 'error');
            setSuccessful(false);
            setErr(sort.error)
            
          }
        })
        setLoading(false);
        // alert("yes");
      }).catch((error )=> {
        // console.log("this error");
        // console.log(error);
        setErr(error)
        // console.log(error.response.data.message);  
        // // console.log(users.id);
      }
      )
    } catch (err) {
      // this part might just be redundant but leave it here
      // errRef.current.focus();
    }
  }


  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get(`https://api.binance.com/api/v3/klines?symbol=${uppercasePair}&interval=12h`);
        const data = response.data;

        const chartLabels = data.map((d) => new Date(d[0]).toLocaleTimeString());
        const chartPrices = data.map((d) => parseFloat(d[1]));

        const chartData = {
          labels: chartLabels,
          datasets: [
            {
              label: 'BTCUSDT Price',
              data: chartPrices,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }
          ]
        };

        setChartData(chartData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchChartData();
  }, []);

  useEffect(() => {
    const fetchOrderBookData = async () => {
      try {
        const response = await axios.get(`https://api.binance.com/api/v3/depth?symbol=${uppercasePair}`);
        const data = response.data;

        const bids = data.bids.slice(0, 10).map((bid) => ({
          price: parseFloat(bid[0]),
          quantity: parseFloat(bid[1])
        }));

        const asks = data.asks.slice(0, 10).map((ask) => ({
          price: parseFloat(ask[0]),
          quantity: parseFloat(ask[1])
        }));

        setOrderBookData({ bids, asks });
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrderBookData();
  }, [newPair]);


//   BINANCE MARKET DATA
    useEffect(() => {
    const fetchTickerData = async () => {
      try {
        const response = await axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=${uppercasePair}`);
        const data = response.data;
        // // console.log("{tickerData.volume} data ");
        // // console.log(data);
        setTickerData(data);
        // // console.log(tickerData?.lastPrice, 'click')
      } catch (error) {
        console.error(error);
      }
    };

    fetchTickerData();
  }, [newPair, tickerData]);



  // websocket
  useEffect(() => {
    const socket = new WebSocket(
      `wss://stream.binance.com:9443/ws/${newPair}@depth`
    );

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const webBid = data.bids;
      // // console.log(data.b.slice(0, 5))
      // // console.log(webOrderBookData, 'web order');
      setWebOrderBookData(data);
    };

    return () => {
      socket.close();
    };
  }, [currencyPair]);

  // web soc market data
  useEffect(() => {
    const socket = new WebSocket(
      `wss://stream.binance.com:9443/ws/${newPair}@ticker`
    );

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // // console.log(data);
      setMarketData(data);
    };

    return () => {
      socket.close();
    };
  }, [currencyPair]);


    //   
    // 
  useEffect(() => {
    getUserData();
    }, []);

    // Get Pairs
    useEffect(() => {
      axios
        .get("https://api.binance.com/api/v3/exchangeInfo")
        .then((response) => {
          const usdtPairs = response.data.symbols.filter(
            (symbol) => symbol.quoteAsset === "USDT"
          );
          const pairsWithoutUsdt = usdtPairs.map((pair) =>
            pair.baseAsset.toUpperCase()
          );
          const sortedPairs = pairsWithoutUsdt.sort();
          setPairs(sortedPairs);
        })
        .catch((error) => 
        console.log(error));
    }, []);

    // handle range change
    const [theValue, setValue ] = useState(0)

    // Adding leverage
    const [leverage, setLeverage] = useState('20');
    const [initMargin, setInitMargin] = useState('');
    const [markInitMargin, setMarkInitMargin] = useState('');
    const [shortMarkInitMargin, setShortMarkInitMargin] = useState('');
    const [dShortCost, setShortCost] = useState('');


    const selectLeverage = (e) => {
      // getPValue(e.target.value);

      var theLev = e.target.value;
      // console.log(theLev, 'leverage')
      setLeverage(theLev)

      
    }
    useEffect(() => {
      // console.log(leverage, "useEffect lev is")
    }, [leverage]);

    

    // const amountQty = targetUser / (contractMultiplier * (assumingPriceIM) + Math.abs(Math.min(0, buySide * (tickerData?.lastPrice - tickerData?.lastPrice))))
    const amountQty = targetUser * leverage / tickerData?.lastPrice
    const amountShortQty = targetUser * leverage / tickerData?.lastPrice
    // const amountShortQty = targetUser / (contractMultiplier * (assumingPriceIM) + Math.abs(Math.min(0, sellSide * (tickerData?.lastPrice - tickerData?.lastPrice))))
    const amtCut = amountQty.toFixed(3)
    const amtShortCut = amountShortQty.toFixed(3)
    // setDQtyAmount(amountQty) 

    const longOrderAssumingPrice = asks.length > 0 ? asks[0][0] * (1 + 0.05/100) : 0;
    const shortOrderAssumingPrice = bids.length > 0 ? bids[0][0] : 0;
    
 
    const selectPercent = (e) => {
      setValue(Number(e.target.value));
      getPValue(Number(e.target.value));
      var tage = Number(e.target.value);
      // const selectedValue = Number(e.target.value);
      // var thePerc = e.target.value;
      var thePerc = amtCut * Number(e.target.value);
      setPsent(tage)
      // console.log(amtCut, 'amtcut pierce')
      // console.log(Number(e.target.value), 'the vals')
      // console.log(thePerc, 'the pierce')
      var calc = thePerc / 100
      // console.log(calc, '3 or 2 the pierce')

      // SIZE CALC
      if(calc > 1) {
        const pres = calc.toFixed(0)
        getPValue(pres);
      } 
      else if (calc < 0.01) {
        const pres = calc.toFixed(3)
        getPValue(pres);
      } 
      else if (calc > 0.1){
        const pres = calc.toFixed(1)
        getPValue(pres);
      }
      else if (calc > 0.01){
        const pres = calc.toFixed(2)
        getPValue(pres);
      }
      else if(calc > 0.001) {
        const pres = calc.toFixed(3)
        getPValue(pres);
      }

      // BUY CALC
      const massPercent = targetUser * calc;
      const getQuantity = massPercent / limit;

      // const marketMass = targetUser * calc;

      const getMarketQty = massPercent / tickerData?.lastPrice;
     
      // SELL CALC
      const sellPercent = targetUserBTC * calc;
      // const sellQuantity = sellPercent / limit;
      // console.log(sellPercent, "sell percentage is") 

      // // console.log(sellQuantity.toFixed(8), "sell quantity amount", targetUserBTC)

      setAmount(getQuantity.toFixed(8)) 

      setTheMarketAmount(getMarketQty.toFixed(8));
      setSellAmount(sellPercent.toFixed(8)) 
      setTheMarkeLimit(tickerData?.lastPrice)

      // // console.log(marketLimit, "mark limit is") 
      // console.log(amountQty, "mark amount Qty") 
 
      // const limses = limit === '' ? marketLimit : limit
 
      // const liMount = amount === Infinity ? marketAmount : amount;

      const notionalValue = limit * calc;
      const InitialMargin = notionalValue / leverage

      // notional value for limit and spot
      const markNotionalValue = longOrderAssumingPrice * calc;
      const markShortNotionalValue = shortOrderAssumingPrice * calc;
      // // console.log(longOrderAssumingPrice, 'longOrderAssumingPrice')
      // // console.log(shortOrderAssumingPrice, 'shortOrderAssumingPrice')
      
      // notional for market
      const markInitialMargin = markNotionalValue / leverage
      const markShortInitialMargin = markShortNotionalValue / leverage

      // console.log(markShortInitialMargin, 'markShortInitialMargin')

      const numOfOpenContracts = calc

      // console.log(calc, 'calc')

      // open loss
      const lossLong = numOfOpenContracts * Math.abs(Math.min(0, 1 * (tickerData?.lastPrice - limit)));
      const lossShort = numOfOpenContracts * Math.abs(Math.min(0, -1 * (tickerData?.lastPrice - limit)));
      const openLossLong = lossLong
      
      // open loss market
      const lossShortMark = numOfOpenContracts * Math.abs(Math.min(0, -1 * (tickerData?.lastPrice - shortOrderAssumingPrice)));

      // limit stop cost
      const cost = InitialMargin + openLossLong
      const shortCost = InitialMargin + lossShort

      // market order cost
      const markCost = markInitialMargin + openLossLong
      const markShortCost = markShortInitialMargin + lossShortMark

      
      setInitMargin(cost.toFixed(2));
      
      setMarkInitMargin(markCost.toFixed(2));
      
      setShortMarkInitMargin(markShortCost.toFixed(2));

      setShortCost(shortCost.toFixed(2));

      // // console.log(limses,  liMount, 'trys')
      // console.log(leverage, 'leverage')
      // console.log(notionalValue, 'notion val')
      // console.log(calc, 'calc perc notion val')
      // console.log(InitialMargin.toFixed(2), 'init marg')
      // console.log(cost, 'this cost alot');
    }


    useEffect(() => {
      // console.log(pValue, "useEffect percentage is")
    }, [pValue]);

    useEffect(() => {
      // // console.log(pValue, "useEffect percentage is")
    }, [targetUserBTC]);


    const [chartingPairs, getChartPair ] = useState('BTCUSDT');
    const [chartVisible, setChartVisible] = useState(true);

    
  // const [currencyPair, getCurrencyPair ] = useState('BTC');
    const selectCurrencyPair = (e) => {
      // getCurrencyPair(e.target.value);
      var thePair =  e.target.value;
      var joining = e.target.value+'USDT';
      // var chartPairing = joining;
      getCurrencyPair(thePair);
      // console.log(currencyPair, "currencyPair");
      getChartPair(joining)
      setChartVisible(false); // Hide the chart temporarily while the pair is changed

      getPValue(0) // set size to 0 when pair changes

      // console.log(chartingPairs, 'chart pairs')
      // console.log(thePair, "the pair");
      // console.log(uppercasePair, "upper case pair");

    }

    useEffect(() => {
      setChartVisible(true); // Show the chart again after the pair has changed
    }, [chartingPairs]);
  

    function toUpperCase(currencyPair) {
      if (typeof currencyPair !== 'string') {
        throw new Error('The argument must be a string');
      }
    
      if (currencyPair.toUpperCase() === currencyPair) {
        return currencyPair;
      }
    
      return currencyPair.toUpperCase();
    }

    // MAKE PAIR LOWER CASE
    function toLowerCase(currencyPair) {
      if (typeof currencyPair !== 'string') {
        throw new Error('The argument must be a string');
      }
    
      if (currencyPair.toLowerCase() === currencyPair) {
        return currencyPair;
      }
    
      return currencyPair.toLowerCase();
    }


    // const textRef = useRef(null);

  function copyToClipboard() {
    const text = marketData?.c;
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = text;
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }


  // FUTURES MARKET DATA

  // const [newUppercasePair, setUppercasePair] = useState('');

  const fetchMarketData = async () => {
    try {
      // Make an HTTP GET request to fetch the order book data for BTCUSDT symbol
      const response = await axios.get('https://fapi.binance.com/fapi/v1/depth', {
        params: {
          symbol: uppercasePair,
          limit: 5, // Number of bids/asks to retrieve
        },
      });
      // // console.log(uppercasePair, 'set');
  
      // Extract the bids and asks data from the response
      const { bids, asks } = response.data;
  
      // Update the bids and asks state with the new data
      setBids(bids);
      setAsks(asks);
    } catch (error) {
      console.error('Error fetching market data:', error);
    }
  };

  useEffect(() => {
    // Fetch market data initially
    fetchMarketData();
  
    // Set up an interval to fetch market data every 5 seconds (adjust the interval as needed)
    const interval = setInterval(fetchMarketData, 1000);
  
    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [uppercasePair]);
  
   

  const renderOrderBook = (orders, isBid) => {
    return orders.map((order, index) => {
      const [price, quantity] = order;

      // Determine the color based on the previous order
      const prevOrder = index > 0 ? orders[index - 1] : null;
      const prevPrice = prevOrder ? prevOrder[0] : null;
      const color = prevPrice && price > prevPrice ? 'green' : 'red';

      return (
        <div key={index} style={{ color }}>
          {isBid ? 'Bid' : 'Ask'}: Price {price} - Quantity {quantity}
        </div>
      );
    });
  };
   
  const toNavigate = useNavigate();
  const goBack = () => {
    toNavigate(-1);
  }
    
 

 

  return (
    <div>
        <TopMenu />
        <div>
        <div className='right-4 top-10 lg-flex absolute'>
            <button  className=" popup-btn bg-purple white p-1 h-100 fw-100 pointer"  onClick={goBack} >
                Back
            </button>
        </div>
        
        </div>
        
        <div className='lg-flex align-top pt-4 pl-4 pr-4'>
        {/* left */}
        <div className='w-100'>
           {/* names plat */}
          <div className='lg-flex pt-2 align-top w-100'>
            <div className='pr-4'>
              <div className='w-100'>
                    <p className='fw-700 grey'>Account Name</p> 
              </div>
              <div className='w-100'>
                  <h2 className='capitalize'> {user.name}     
                  {/* ${user.accountBalances[6].freeBalance} */}
                  </h2>
              </div>
            </div>
            <div className=' align-center j-sb pb-1 pr-4'>
                <div className=' '>
                    <p className='fw-700 grey'>Platform</p> 
                </div>
                <div className=' '>
                    {/* <select className='w-100 pt-1 pb-1 border-radius-10 f-16 fw-600'> */}
                        <h2 className='capitalize'>{user.platform}</h2>
                    {/* </select> */}
                </div>
            </div>
            <div className=' align-center j-sb'>
                <div className='w-100'>
                    <p className='fw-700 grey'>Currency pair</p> 
                </div>
                <div className='w-100 pb-1'>
                <select onChange={selectCurrencyPair} className='w-100 pt-1 pb-1 border-radius-10 f-16 fw-600'>
                        <option value={'BTC'}>BTC/USDT</option>
                        {pairs.map((pair) => <option value={pair} key={pair}>{pair}/USDT</option>)};
                         
                    </select> 
                </div>
            </div>
            <div>
              <div className='pl-2 lg-flex mb-flex'>
                {/* <div className='w-50'></div> */}
                  <div className='w-100 lg-flex'>
                      <div className='pr-1'>
                          <p className='fw-700'>24h Price Change</p>
                          <span className='f-12'>${marketData?.p}</span>
                      </div>
                      <div>
                          <hr />
                      </div>
                      <div>
                          <p className='fw-700'>24h Change % </p>
                          
                          <span className='f-12'>{
                              marketData?.P[0] === "-" ? 
                                  <div className='red fw-700'>{marketData?.P}%</div> : 
                                  <div className='green fw-700'>{marketData?.P}%</div>
                              }
                          </span>
                      </div>
                  </div>
              </div>
              <div className='lg-flex mb-flex'>
                  {/* <div className='w-50'></div> */}
                  <div className='pl-2 w-100 lg-flex'>
                    <div className='pr-1 w-100'>
                        <p className='fw-700'>24h High</p>
                        <span className='f-12'>{marketData?.h}</span>
                    </div>
                    <div className='w-100'>
                        <p className='fw-700'>24h Low</p> 
                        <span className='f-12'>{marketData?.l}</span>
                    </div>
                    <div className='w-100'>
                  {/* <hr /> */}
                        <p className='fw-700'>24h Vol</p> 
                        <span className='f-12'> {marketData?.v}</span>
                    </div>
                  </div>
              </div>
            </div>
            
          </div>

          {/* CHART */}
            {/* <div className='lg-flex mb-flex'>
                    <div className='w-50'></div>
                <div className='w-100 lg-flex'>
                    <div className='pr-1'>
                        <p className='fw-700'>24h Price Change</p>
                        <span className='f-12'>${marketData?.p}</span>
                    </div>
                    <div>
                        <hr />
                    </div>
                    <div>
                        <p className='fw-700'>24h Change % </p>
                        
                        <span className='f-12'>{
                            marketData?.P[0] === "-" ? 
                                <div className='red fw-700'>{marketData?.P}%</div> : 
                                <div className='green fw-700'>{marketData?.P}%</div>
                            }
                        </span>
                    </div>
                </div>
            </div> */}
            {/* <div className='lg-flex mb-flex'>
                <div className='w-50'></div>
                <div className='w-100 lg-flex'>
                    <div className='pr-1'>
                        <p className='fw-700'>24h High</p>
                        <span className='f-12'>{marketData?.h}</span>
                    </div>
                    <div>
                        <p className='fw-700'>24h Low</p> 
                        <span className='f-12'>{marketData?.l}</span>
                    </div>
                </div>
            </div> */}
            <div className='lg-flex'>
                <div className='w-50'>
                </div>
               
            </div>
            {/* <div className='lg-flex'>
                <div className='w-50'>
                </div>
                <div className='w-100'>
                    <hr />
                    <div>
                        <h1>Binance websocket Order Book Data for BTCUSDT:</h1>
                        <div>
                        <h2>Bids</h2>
                        {bids.map((bid, index) => (
                          <div key={index}>{`Price: ${bid[0]}, Quantity: ${bid[1]}`}</div>
                        ))}

                        <h2>Asks</h2>
                        {asks.map((ask, index) => (
                          <div key={index}>{`Price: ${ask[0]}, Quantity: ${ask[1]}`}</div>
                        ))}
                      </div>
                      
                    </div>
                </div>
            </div> */}
            <div className='fw-700'>
              {[uppercasePair]}
            </div>
          <div className="parent"> 
          {/* {chartVisible  && ( */}
            <ChartJs tradePair={uppercasePair} />
          {/* )} */}
              
          </div>


            <div>
            
            </div>

        </div>

        {/*  */}
        <div className='pl-2 h-50 pr-2'>
            <hr className='w-50' />
        </div>
        {/* right */}

        <div className='w-50 lg-flex align-top h-90vh '> 

          {/* BUY TAB */}
          <div className='content '>
          {
          // activeTab === "section1" && 
          (
            <div>
              <div>
              <div>
                  <div className='pt-2 lg-flex'>
                    <p className='pr-1'>
                    Leverage 
                    </p>
                    <select onChange={selectLeverage} className='  '>
                      <option>{leverage}</option>
                      <option value={"1"}>1</option>
                      <option value={"2"}>2</option>
                      <option value={"4"}>4</option>
                      <option value={"6"}>6</option>
                      <option value={"8"}>8</option>
                      <option value={"10"}>10</option>
                      <option value={"12"}>12</option>
                      <option value={"14"}>14</option>
                      <option value={"16"}>16</option>
                      <option value={"20"}>20</option> 
                      <option value={"30"}>30</option> 
                      <option value={"40"}>40</option> 
                      <option value={"50"}>50</option> 
                      <option value={"60"}>60</option> 
                      <option value={"70"}>70</option> 
                    </select>
                  </div>
                </div>
                <div className='lg-flex pt-1 j-sb w-50'>
                  <p className={limitTab === "LIMIT" ? "active  pointer red  mr-1 fw-400  pt-1 pb-1" : " pointer  mr-1 fw-400  pt-1 pb-1"} onClick={() => handleLimitClick("LIMIT")}>LIMIT</p>
                  <p className={limitTab === "MARKET" ? "active pointer red  mr-1 fw-400  pt-1 pb-1" : " pointer mr-1 fw-400  pt-1 pb-1"} onClick={() => handleLimitClick("MARKET")}>MARKET</p>
                  <p className={limitTab === "STOP" ? "active  pointer red  mr-1 fw-400  pt-1 pb-1" : " pointer  mr-1 fw-400  pt-1 pb-1"} onClick={() => handleLimitClick("STOP")}>STOP_LIMIT</p>
                
                </div>
                

                {limitTab === 'LIMIT' && (
                  <div>
                    <div className=' pt-1'>
                      
                      <input className='border-radius-5 pl-1 pr-1 border-1'
                        value={limit} placeholder='PRICE'
                        onChange={(event) => setLimit(event.target.value)}
                      />
                    </div>
                    <div className='pb-1'>
                      <h2 className=''>Market Price</h2>
                      <p className=' lg-flex align-center'> 
                        <div className='f-12 fw-700 pb-1'>{tickerData?.lastPrice}</div>
                        <img onClick={copyToClipboard} src={Copy} className='pb-1 pointer' width={"2%"} alt="Click to copy"/>
                      </p> 
                    </div>
                    <div className='pb-1'>
                      <input className='border-radius-5 pl-1 pr-1 border-1'
                        value={pValue || ''} placeholder={`Amount ${justPair}`}
                        onChange={(event) => setAmount(event.target.value)}
                      />
                    </div>
                  </div>
                )
                
                }

                {limitTab === 'MARKET' && (
                  <div>
                    <div className='pb-1'>
                      <h2 className=''>Market Price</h2>
                      <p className=' lg-flex align-center'> 
                        <div className='f-12 fw-700 pb-1'>{tickerData?.lastPrice}</div>
                        <img onClick={copyToClipboard} src={Copy} className='pb-1 pointer' width={"2%"} alt="Click to copy"/>
                      </p> 
                    </div>
                    <div className='pb-1'>
                      <input className='border-radius-5 pl-1 pr-1 border-1'
                        value={pValue || ''} placeholder={`Amount ${justPair}`}
                        onChange={(event) => setAmount(event.target.value)}
                      />
                    </div>
                  </div>
                )
                
                }

                {limitTab === 'STOP' && (
                  <div>
                    <div className=' pt-1'>
                  
                  <input className='border-radius-5 pl-1 pr-1 border-1'
                    value={limit} placeholder='PRICE'
                    onChange={(event) => setLimit(event.target.value)}
                  />
                </div>
                    <div className='pb-1'>
                      <h2 className=''>Market Price</h2>
                      <p className=' lg-flex align-center'> 
                        <div className='f-12 fw-700 pb-1'>{tickerData?.lastPrice}</div>
                        <img onClick={copyToClipboard} src={Copy} className='pb-1 pointer' width={"2%"} alt="Click to copy"/>
                      </p>
                      <div className='pb-1'>
                        <input className='border-radius-5 pl-1 pr-1 border-1'
                          value={stop} placeholder={`'STOP (${justPair})'`}
                          onChange={(event) => setStop(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className='pb-1'>
                      <input className='border-radius-5 pl-1 pr-1 border-1'
                        value={pValue || ''} placeholder={`Amount ${justPair}`}
                        onChange={(event) => setAmount(event.target.value)}
                      />
                    </div>
                  </div>
                )
                
                }


                {/* <div className='pb-1'>
                  <select onChange={selectPercent} className='  '>
                    <option>Trade percentage</option>
                    <option value={"1"}>1</option>
                    <option value={"2"}>2</option>
                    <option value={"3"}>3</option>
                    <option value={"4"}>4</option>
                    <option value={"5"}>5</option>
                    <option value={"10"}>10</option>
                    <option value={"15"}>15</option>
                    <option value={"20"}>20</option>
                    <option value={"100"}>100</option>
                  </select>
                </div> */}

<div className='w-100 lg-flex'>
                  <RangeSlider
                    min={0}
                    max={100}
                    step={1}
                    value={theValue}
                    handleChange={selectPercent}
                  />
                </div>
                <div className='lg-flex j-sb'>
                  {/* long */}
                  <div>
                    {
                      limitTab === 'MARKET' && (
                    <div>
                      Cost: {markInitMargin} USDT<br />

                    </div> 

                      )
                    }
                    {
                      limitTab === 'STOP' && (
                      <div>
                        Cost: {initMargin} USDT <br />

                      </div> 
                      )
                    }
                    {
                      limitTab === 'LIMIT' && (
                      <div>
                        Cost: {initMargin} USDT <br />

                      </div> 
                      )
                    }
                    <div>
                      Max: {amtCut} {justPair}<br />

                    </div>
                  </div>
                  {/* short */}
                  <div>

                    {/* <div>
                      Cost: {dShortCost} USDT<br /> 
                    </div>  */}
                    {
                      limitTab === 'MARKET' && (
                    <div>
                      Cost: {shortMarkInitMargin} USDT<br />

                    </div> 

                      )
                    }
                    {
                      limitTab === 'STOP' && (
                      <div>
                        Cost: {dShortCost} USDT <br />

                      </div> 
                      )
                    }
                    {
                      limitTab === 'LIMIT' && (
                      <div>
                        Cost: {dShortCost} USDT <br />

                      </div> 
                      )
                    }
                    <div>
                      Max: {amtShortCut} {justPair}<br />

                    </div>
                  </div>
                </div>

                {/* <div>
                  Cost: {initMargin} USDT<br />
                </div> */}
                
                {/* <div>
                  Available: {targetUser} USDT<br />

                </div> */}
                

                <Popup trigger={<button value='Buy' className= 'popup-btn bg-purple white mr-1 fw-400  pt-1 pb-1'><p>Buy/Long</p></button>}
                    modal
                    nested
                >
                    {
                        close => (
                            <div className="modal lg-flex mb-flex j-center align-center">
                                <div className=" lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-50 w-50 bg-white">
                                    <div className=" pl-2 pr-2 text-center">
                                        <div className="">
                                            <img src={Good} alt="account creation successful"/>
                                        </div>
                                        <div className="pt-4">
                                            <h1 className="f-14 fw-700 pr-1 ">Are you sure you want to trade {pcent}% of {user.name} balance </h1>
                                        </div>
                                        <div className="pt-4">
                                          {
                                            loading ?
                                            (
                                            // <div>
                                            //   please wait...
                                            // </div>
                                            <CustomLoader />
                                            ) : successful ? (
                                            <div className="l  j-center">
                                          
                                              {/* <Link to={'/home'}>
                                                  <button onClick={close} className=" popup-btn bg-purple white mr-1 h-100 fw-100"   ><p className=" f-12 fw-100">Finish</p></button>
                                              </Link>
                                                  */}
                                                  <p>Order Created</p>
                                              <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Finish</p></button>
                                            </div> 
                                            ) : err ? (

                                            <div> 
                                              <p>{err}</p> <br />
                                              <div className="lg-flex mb-flex j-center">
                                                <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-purple white " onClick={allFalse}><p className="f-12">Try again</p></button>
                                                <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Cancel</p></button>
                                              </div>
                                            
                                            </div> 
                                            ) : (
                                            <>
                                            <div className="lg-flex mb-flex j-center">
                                          
                                          <Link to={'/home'}>
                                              <button onClick={startTrade} className="pointer popup-btn bg-purple white mr-1 h-100 fw-100"   ><p className=" f-12 fw-100">Trade</p></button>
                                          </Link>
                                              
                                          <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Cancel</p></button>
                                        </div>
                                            </>
                                            )
                                            
                                          }
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    }
            </Popup> 
            <Popup trigger={<button className= 'popup-btn bg-red white mr-1 fw-400  pt-1 pb-1'><p>Sell/Short</p></button>}
                    modal
                    nested
                >
                    {
                        close => (
                            <div className="modal lg-flex mb-flex j-center align-center">
                                <div className=" lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-50 w-50 bg-white">
                                    <div className=" pl-2 pr-2 text-center">
                                        <div className="">
                                            <img src={Good} alt="account creation successful"/>
                                        </div>
                                        <div className="pt-4">
                                            <h1 className="f-14 fw-700 pr-1 ">Are you sure you want to trade {pcent}% of {user.name} balance </h1>
                                        </div>
                                        <div className="pt-4">
                                          {
                                            loading ?
                                            (
                                            // <div>
                                            //   please wait...
                                            // </div>
                                            <CustomLoader />
                                            ) : successful ? (
                                            <div className="l  j-center">
                                          
                                              
                                                  <p>Order Created</p>
                                              <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Finish</p></button>
                                            </div> 
                                            ) : err ? (

                                            <div> 
                                              <p>{err}</p> <br />
                                              <div className="lg-flex mb-flex j-center">
                                                <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-purple white " onClick={(e) => {allFalse(e)}}><p className="f-12">Try again</p></button>
                                                <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Cancel</p></button>
                                              </div>
                                            
                                            </div> 
                                            ) : (
                                            <>
                                            <div className="lg-flex mb-flex j-center">
                                          
                                          <Link to={'/home'}>
                                              <button onClick={sellTrade} className="pointer popup-btn bg-purple white mr-1 h-100 fw-100"   ><p className=" f-12 fw-100">Trade</p></button>
                                          </Link>
                                              
                                          <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={(e) => {allFalse(e); close()}}><p className="f-12">Cancel</p></button>
                                        </div>
                                            </>
                                            )
                                            
                                          }
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    }
            </Popup> 
              </div>
            </div>
          )
          }
 
                
          </div> 
      
      </div>
    </div>
        
        
    </div>
  )
}

export default TradeFuturesGroup
import axios from "axios";
import { useEffect, useRef, useState } from "react"; 
import Cookies from "universal-cookie";
// import AccountCreatedPopup from "./AccountCreatedPopup";

import { Notyf } from "notyf";
import 'notyf/notyf.min.css'

const Account_URL = '/common/binance/add-account'


const AddAPIPopup = ({onClose}) => {

  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'
    },
    duration: 4000,
    dismissable: true,
  })

    
    const cookies = new Cookies();
    const token = cookies.get("TOKEN");

    // const [openModal, setOpenModal ] = useState (false);
    
    
    const userRef = useRef();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    // const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);

    const [accountName, setAccountName] = useState('');
    const [accountAPIKey, setAccountAPIKey] = useState('');
    const [theAccountType, setTheAccountType] = useState('');
    const [accountSecretKey, setAccountSecretKey] = useState('');


    useEffect(() => {
        setErrMsg('');
      }, [accountName, accountAPIKey, accountSecretKey ])
  

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        // // console.log(theAccountType, 'effe')
        try {
          setLoading(true)
            axios.post(
            "https://api.lanealitrade.com"+Account_URL,
            {
              name: accountName,
              apiKey: accountAPIKey,
              accountType: theAccountType,
              secretKey: accountSecretKey,
            },

           {
            headers: {
              'Content-Type': 'application/json',
                 'accept': '*/*',
                 Authorization: `Bearer ${token}`,
              },
            },
            
          ).then((result) => {
            // // console.log(result);
            // console.log(JSON.stringify(result.data.token));
            // // console.log(JSON.stringify(result.data));
            // // console.log(JSON.stringify(result));
            // setSuccessful(true);
            setLoading(false)
            notyf.success('created successful')
            // window.location.reload();
            window.location.href = "/";
            // alert('uploaded')
            
          }).catch((error )=> {
            // // console.log("this error");
            // console.log(error);
            // console.log(error.response.data);
            // console.log(error.response.data.statusCode);
            // console.log(error.response.data.message);
            // console.log(error.message);
            setLoading(false)
            notyf.error(error.message)
            if(!error) {
              setErrMsg(error.response.data.message);
            } else if(error.response.data.statusCode === 400) {
              setErrMsg(error.response.data.message);
            } else if(error.response.data.statusCode === 401) {
              setErrMsg(error.response.data.message);
            } else {
              setErrMsg(error.response.data.message);
            }
            // errRef.current.focus();
            
          })
    
        } catch (err) {
    
          // this part might just be redundant but leave it here
          if(!err) {
            setErrMsg(err.response.data.message);
          } else if(err.response.data.statusCode === 400) {
            alert(setErrMsg(err.response.data.message));
          } else if(err.response.data.statusCode === 401) {
            alert(setErrMsg(err.response.data.message));
          } else {
            setErrMsg(err.response.data.message);
          }
          // errRef.current.focus();
        }
      
      }

      const selectAccountType = (e) => { 
        setTheAccountType(e.target.value)
        // // console.log(theAccountType, 'wo wo')
      }

      useEffect(() => {}, [theAccountType])

      
    // if (!open) 
    //     return null
  return (
    <div>

            <form >
                <div className="modal lg-flex mb-flex j-center align-center">
                    <div className="modal-contents lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-50  bg-white">
                      <div className="pl-4 pr-4">
                      
                        <div><p className="red">{errMsg}</p></div>
                            <div className="mb-flex lg-flex j-sb align-center pt-1">
                                <h1 className="f-14 fw-600 w-40 grey pr-1">Account Name </h1>
                                <div className="border-radius-10 border-1 mb-flex lg-flex w-100">
                                    <input 
                                        type={'text'} 
                                        value={accountName} 
                                        ref={userRef}
                                        onChange={(e) => setAccountName(e.target.value)}
                                        className='border-radius-10 border-0 group-input' 
                                        width={'100%'}
                                    />
                                </div>
                                
                            </div>
                            <div className="mb-flex lg-flex j-sb align-center pt-1">
                                <h1 className="f-14 fw-600 w-40  grey pr-1">API key</h1>
                                <div className="border-radius-10 border-1 mb-flex lg-flex w-100">
                                    <input 
                                        type={'text'} 
                                        value={accountAPIKey} 
                                        ref={userRef}
                                        onChange={(e) => setAccountAPIKey(e.target.value)}
                                        className='border-radius-10 border-0 group-input' 
                                        width={'100%'}
                                    />
                                </div>
                                
                            </div>
                            <div className="mb-flex lg-flex j-sb align-center pt-1">
                                <h1 className="f-14 fw-600 w-40 grey pr-1">Secret key </h1>
                                <div className="border-radius-10 border-1 mb-flex lg-flex w-100">
                                    <input 
                                        type={'text'} value={accountSecretKey} ref={userRef}
                                        onChange={(e) => setAccountSecretKey(e.target.value)} 
                                        className='border-radius-10 border-0 group-input' 
                                        width={'100%'}
                                    />
                                </div>
                                
                            </div>
                            <div className="mb-flex lg-flex j-sb align-center pt-1">
                                <h1 className="f-14 fw-600 w-40 grey pr-1">Wallet Type </h1>
                                <select className="border-radius-10 group-input border-1 mb-flex lg-flex w-100"
                                  onChange={selectAccountType} 
                                
                                >
                                    <option  
                                    > select account type </option>
                                    <option value={'spot'}
                                        // type={'text'} value={theAccountType} ref={userRef}
                                        // className='border-radius-10 border-0' 
                                        // width={'100%'}
                                    > spot </option>
                                    <option value={'features'} > futures </option>
                                </select>
                                
                            </div>
                            <div className="mb-flex lg-flex j-sb pt-2" >
                                <div className="w-10 pr-6">
                                </div>
                                {
                        loading ? (
                          <>Please wait</>
                        ) 
                        // : 
                        // successful ? (
                        //   <>
                        //     <AccountCreatedPopup />
                        //   </>
                        // ) 
                        : (
                          <>
                                <div className="lg-flex mb-flex j-center">
                                        <button className="pointer popup-btn bg-purple white mr-1 fw-100" onClick={handleSubmit} ><p className=" f-12 fw-100">Create Account</p></button>
                                        {/* {
                                            successful ?
                                            <AccountCreatedPopup open={openModal} onClose={()=> setOpenModal(false)}  />
                                            :
    
                                            <div>
                                                
                                            </div>
                                        } */}
                                    <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={onClose}><p className="f-12">Close</p></button>
                                </div>
                        </>
                        )
                      }
                            </div>
                      </div>
                    </div>
                </div>
    
            </form>
       
    </div>
  )
}

export default AddAPIPopup
import {useRef, useState, useEffect } from 'react'
import '../../../src/App.css'
import Lmp from '../../assets/logo-name.svg'
import LogoPurple from '../../assets/logo-purple.svg'
import Google from '../../assets/google-icon.png'
import {   Link  } from 'react-router-dom'

import Cookies from 'universal-cookie';

import OtpInput from 'react-otp-input';

import { useGoogleLogin } from '@react-oauth/google';

import 'notyf/notyf.min.css';

import { Notyf } from 'notyf';

import axios from 'axios';
const Login_URL = '/auth/login';
const Login_OTP_URL = '/auth/login-otp';


const SignIn = () => {

  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'
    },
    duration: 4000,
    dismissable: true,
  })

  const cookies = new Cookies();

  // const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  // const [otp, setOtp] = useState('');
  
  const [errMsg, setErrMsg] = useState('');
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const [googleEmail, setGoogleEmail] = useState('');


  
  const authGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
        // console.log("tokken response");
        // // console.log(tokenResponse);
       
       try {
        var make = await axios.post(
            "https://api.lanealitrade.com/auth/google/auth",
            {
                accessToken: tokenResponse.access_token,
            },
            {
            headers: {
                        'Content-Type': 'application/json',
                        'accept': '*/*',
                        // Authorization: `Bearer ${token}`,
                    },
            }
          ).then(function (result) { 
        // // console.log(result);
        // // console.log(tokenResponse);
        // const googleEmail = result.data.data;
        setGoogleEmail(result.data.data);
        // // console.log("email");
        // // console.log(googleEmail);
        setSuccessful(true);
        } );
        
      } catch(err) {
        // console.log(err);
      }
      // console.log("got data ?");
      // console.log(make);
    },
    onError: (e) => {
        // console.log('Login Failed');
        // console.log(e);
      },
  });


  // setfocus on first input wjen component loads
  // useEffect(() => {
  //   userRef.current.focus();
  // }, [])


  // empty out error msg if user chabges text of input
  useEffect(() => {
    setErrMsg('');
  }, [email, password, 
    // otp
  ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg('');

    const loginConfig = {
      method: "post",
      url: "https://api.lanealitrade.com"+Login_URL,
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*'
          },
          // withCredentials: true
        data: {
          login: email,
          password: password,
        },
    };

    try {
      setLoading(true)
      await axios(loginConfig).then((result) => {
        // // console.log(result);
        // // console.log(JSON.stringify(result.data.token));
        // // console.log(JSON.stringify(result.data));
        // // console.log(JSON.stringify(result));
        setSuccessful(true);
        setLoading(false)
        notyf.success('OTP sent to your mail')
      }).catch((error )=> {
        // console.log("this error");
        // // console.log(error);
        // // console.log(error.response.data);
        // // console.log(error.response.data.statusCode);
        // // console.log(error.response.data.message);
        // console.log(error.message);
        setLoading(false)
        notyf.error(error.message)
        if(!error) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 400) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 401) {
          setErrMsg(error.response.data.message);
        } else {
          setErrMsg(error.response.data.message);
        }
        errRef.current.focus();
      })

    } catch (err) {

      // this part might just be redundant but leave it here
      if(!err) {
        setErrMsg(err.response.data.message);
      } else if(err.response.data.statusCode === 400) {
        alert(setErrMsg(err.response.data.message));
      } else if(err.response.data.statusCode === 401) {
        alert(setErrMsg(err.response.data.message));
      } else {
        setErrMsg(err.response.data.message);
      }
      // errRef.current.focus();
    }
  
  }


    

  const user = email;
  // const googleUser = useMail;

  const [OTP, setOTP ] = useState('')

  function handleChange(OTP) {
    setOTP(OTP);
  }

  
  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    
    const newOTP = OTP;

    // console.log(newOTP);

    const loginConfig = {
      method: "post",
      url: "https://api.lanealitrade.com"+Login_OTP_URL,
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*'
          },
          // withCredentials: true
        data: {
          login: user || googleEmail,
          otp: newOTP,
        },
    };

    try {
      setLoading(true)      
      await axios(loginConfig).then((result) => {
        // // console.log(result);
        // const decoded = jwtDecode(result.data.token);
        // // console.log(JSON.stringify(result.data.token));
        // // console.log(JSON.stringify(result));
        cookies.set("TOKEN", result.data.token, {
          // expires: new Date(decoded.exp * 2592000000),
          path: "/",
        });
        window.location.href = "/"; 
        setSuccessful(true);
        setLoading(false)
        notyf.success('Logged in')
        // alert("we're in");
      }).catch((error )=> {
        // console.log("this error");
        // // console.log(error);
        // // console.log(error.response.data);
        // // console.log(error.response.data.statusCode);
        // // console.log(error.response.data.message);
        // console.log(error.message);
        setLoading(false)
        notyf.error(error.message)
        if(!error) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 400) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 401) {
          setErrMsg(error.response.data.message);
        } else {
          setErrMsg(error.response.data.message);
        }
        errRef.current.focus();
      })

    } catch (err) {

      // this part might just be redundant but leave it here
      if(!err) {
        setErrMsg(err.response.data.message);
      } else if(err.response.data.statusCode === 400) {
        alert(setErrMsg(err.response.data.message));
      } else if(err.response.data.statusCode === 401) {
        alert(setErrMsg(err.response.data.message));
      } else {
        setErrMsg(err.response.data.message);
      }
      // errRef.current.focus();
    }
  
  }

  return (
    
    <div>
      <div className='h-100vh lg-flex mb-flex j-center align-center'>
        <div className=' w-100 h-100 ph-big-10 bg-grey mb-hidden mb-flex lg-flex align-center'>
          <div  >
            <div>
              <img src={Lmp} width='100%' alt='logo' />
            </div>
            <p className='capitalize purple '>digital trading platform</p>
          </div>
        </div>
        <div className='w-100 ph-big-10 '>
          <div className=' lg-hidden j-center text-center align-center'>
            <div >
              <div>
                <img src={LogoPurple} alt='logo purple' width='70%' />
              </div>
              <p className='capitalize purple  pt-1'>digital trading platform</p>
            </div>
          </div>

          {/*OTP VERIFY LOGIN */}
          <>
      {successful ? (

          <div className=''>
            <form 
            // onSubmit={handleOTPSubmit}
            >
              <h1 className='text-center hidden f-40 pt-1'>
                  Verify OTP
              </h1>
              <p className='pb-6'>OTP has been sent to your email</p>
              <p ref={errRef} className={errMsg ? 'red' : 'hidden'} aria-live='assertive' >
                {errMsg}
              </p>
               
              <div className='pb-1 lg-flex mb-flex j-center'>
                
                {/* <OTPInput
                  onChange={handleChange}
                  value={OTP}
                  inputStyle="inputStyle"
                  numInputs={4}
                  separator={<span></span>}
                /> */}
                <OtpInput
                inputStyle="inputStyle"
                  value={OTP}
                  onChange={handleChange}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
                {/* <OtpInput /> */}
              </div>
              <div>
                {
                  loading ? (
                <button onClick={(e) => e.preventDefault()} className='button bg-purple white border-0'>
                  Loading...
                </button>
                    
                  ) : (
                <button onClick={handleOTPSubmit} className='button bg-purple white border-0'>
                  Verify
                </button>
                  )
                }
                {/* <Link to={'/home'}>
                <input className='button bg-purple white border-0' type='button' value='Sign in' placeholder={'password'} />
                </Link> */}
              </div>
              <div className='pt-1 text-center'>
                  <p className='purple pt-1'>
                <Link to={'/forgot-password'}>
                    forgot password?
                </Link>
                  </p>
              </div>
            </form>
          </div>
      ) :(

        <div >
          <form 
          // onSubmit={handleSubmit}
          >
            <h1 className='text-center f-40 pt-1'>
                Sign in
            </h1>
            <p ref={errRef} className={errMsg ? 'red' : 'hidden'} aria-live='assertive' >
              {errMsg}
            </p>
            <div className='pb-1'>
              <input 
              type='email' 
              name='email'
              placeholder='Email address' 
              className='log-input pt-1 '  
              value={email}
              // id='username'
              autoComplete='off'
              // ref={userRef}
              onChange={(e) => setEmail(e.target.value)}
              // required
              />
            </div>
            <div className='pb-1'>
              <input 
              type='password' 
              name='password'
              value={password}
              placeholder='password' 
              onChange={(e) => setPassword(e.target.value)}
              className='log-input pt-1 ' 
              autoComplete='off'
              // id='password'
              // required
              />
            </div>
            <div className='lg-flex mb-flex pb-1 align-center'>
            <Link onClick={() => authGoogle()} className='link'>
                <div className='lg-flex mb-flex pb-1 align-center pr-6'>
                    <img src={Google} alt='google logo' height={'30px'} className='pr-1' />
                    <p className='fw-700'>Signin with google</p>
                </div>
            </Link>
          </div>
          {
            loading ? (
            <div>
              <button type='submit' onClick={(e) => e.preventDefault()} className='button bg-purple white border-0'>
                Loading...
              </button>
            </div>
            ) : (
            <div>
              <button type='submit' onClick={(e) => handleSubmit(e)} className='button bg-purple white border-0'>
                Sign in
              </button>
            </div>
            )
          }
            <div className='pt-1 text-center'>
                <p className='purple pt-1'>
              <Link to={'/forgot-password'}>
                  forgot password?
              </Link>
                </p>
            </div>
          </form>
          
        </div>

      )  }</>
            
        </div> 
      </div>
    </div>
  
    
  )
}

export default SignIn
import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://api.lanealitrade.com/'
});

export default instance;

// export default axios.create({
//     baseURL: 'https://api.lanealitrade.com'
// });
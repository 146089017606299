import React from 'react'
import '../../App.css'
import Header from '../../components/Header'
 
import SideNav from '../../components/SideNav'
import TopMenu from '../../components/TopMenu'

const Settings = () => {
  return (
    <div>
        <TopMenu />
      <SideNav />
    <Header />     
        <div className='main-doc'>
          <div className='ml-1 mt-2'>

            Settings
          </div>
        </div>
    </div>
  )
}

export default Settings
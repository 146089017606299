import { useState } from 'react'
import TopMenu from '../../components/TopMenu'

import Trade from '../../assets/trade.svg';


import {  Link, useLocation, useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie'
import axios from 'axios'
import { useEffect } from 'react';

const cookies = new Cookies();
const token = cookies.get("TOKEN");

const SINGLE_GOUP_URL = 'https://api.lanealitrade.com/common/trading-segment-accounts/';

const ViewTradingGroups = (props) => {

    
    
//   const [successful, setSuccessful] = useState(false);
//   const [theAccountId, setTheAccountID] = useState('');
  
  const [group, getGroup ] = useState('');
  const [error, getError ] = useState('');

  const location = useLocation();
  const toNavigate = useNavigate();

//   // console.log(props, "this props yo");
//   // console.log(location, "this location set");
  
  const data = location.state;

  const goBack = () => {
    toNavigate(-1);
  }

    // // console.log(SINGLE_Group_URL+data, "it is?");

    const [spots, getSPots ] = useState('');
  
    const [futures, getFutures ] = useState('');
  
    const [spotTab, setSpotTab] = useState('future');
  
    
  const getGroupMembers = () => {
    axios.get( SINGLE_GOUP_URL+data,
        {
            headers: {
            'Content-Type': 'application/json',
              'accept': '*/*',
              Authorization: `Bearer ${token}`,
              }
        }
        ).then(
        (response) => {
            // console.log(response);
            // console.log('single user response.data');
            // console.log(response.data);
            const theGroup = response.data;
            getGroup(theGroup);
            const spotAccounts = theGroup.accountSegments.filter(theType => theType.account.accountType === "spot");
             
            const futuresAccounts = theGroup.accountSegments.filter(theType => theType.account.accountType === "features");

            // console.log(spotAccounts, 'spot')
            // console.log(futuresAccounts, 'spot')

            getSPots(spotAccounts)
              getFutures(futuresAccounts)
           
        }
    ).catch((error) => {
        // console.log(error);
        // console.log(error.message);
        const theError = error.message;
        getError(theError);
    } )
}


  useEffect(() => {
      getGroupMembers();
      // startTrade();
  },[])

  const handleSpotClick = (tab) => {
    setSpotTab(tab);
  };


  return (
    <div>
        <div>
            <TopMenu />
        </div>
        <div className=' pt-6 ml-6 h-100 '>
            <div className='right-10 lg-flex absolute'>
                <button  className=" popup-btn bg-purple white mr-1 h-100 fw-100 pointer"  onClick={goBack} >
                    Back
                </button>
            </div>   

             {/*  */}
             <div className='h-90vh lg-flex column'>

                <div className='lg-flex flex-wrap flex- 0-auto pt-4'>
                    <div className=' w-20 mr-4'>
                        <h1 className='f-38 purple lh-1 fw-700'>{group.name}</h1>
                    </div>
                    <div className='border-1-purple border-radius-10 w-20 mr-4 p-1'>
                        <h2 className='purple f-14'>Total number of accounts</h2>
                        <p className='fw-700 f-24 txt-light-blue'>{group.accountSegments?.length}</p>
                    </div>
                    {/* <div className='border-1-purple border-radius-10 w-20 mr-4 mb-1 p-1'>
                        <h2 className='purple f-14'>Group rule</h2>
                        <p className='fw-700 f-24 txt-light-blue'>0 - 200$</p>
                    </div> */}
                    {/* <div className='border-1-purple border-radius-10 w-20 mr-4 mb-1 p-1'>
                        <h2 className='purple f-14'>Sum in deposits</h2>
                        <p className='fw-700 f-24 txt-light-blue'>1,900,800</p>
                    </div> */}
                    {/* <div className='border-1-purple border-radius-10 w-20 mr-4 mb-1 p-1'>
                        <h2 className='purple f-14'>Sum in withdrawals</h2>
                        <p className='fw-700 f-24 txt-light-blue'>1,809,890</p>
                    </div> */}
                    {/* <div className='border-1-purple border-radius-10 w-20 mr-4 mb-1 p-1'>
                        <h2 className='purple f-14'>Sum in profit</h2>
                        <p className='fw-700 f-24 txt-light-blue'>1,893,942</p>
                    </div> */}
                    {/* <div className='border-1-purple border-radius-10 w-20 mr-4 mb-1 p-1'>
                        <h2 className='purple f-14'>Sum in loss</h2>
                        <p className='fw-700 f-24 txt-light-blue'>0</p>
                    </div> */}
                    {/* <div className='border-1-purple border-radius-10 w-20 mr-4 mb-1 p-1'>
                        <h2 className='purple f-14'>Group ID</h2>
                        <p className='fw-700 f-24 txt-light-blue'>BI8273799</p>
                    </div> */}
                </div>

                <div className=' pt-1'>
                        <h2  className='purple fw-600 f-20'>
                            Accounts in this group
                        </h2>
                    </div>
                {/* DETAILS */}

                <div className='h-50 w-95 flex-auto'>
                <div className='tab lg-flex j-sb pt-1 pb-1 '>
                    <div className='bg-grey w-27 p-1 border-radius-10 lg-flex j-center'>
                        <button className={spotTab === "future" ? " active popup-btn bg-spot white mr-1 fw-400 f-12 " : " popup-btn bg-white mr-1 fw-400 f-12 "} onClick={() => handleSpotClick("future")}>Futures account</button>
                        <button className={spotTab === "spot" ? "active popup-btn bg-spot white fw-400 f-12 " : "popup-btn bg-white fw-400 f-12 "} onClick={() => handleSpotClick("spot")}>Spot account</button>
                    </div>
                    <div>
                        {spotTab === 'spot' && (
                            <Link to={'/group-trade'} state={group.id} id={group.id} className='link' >
                                <div className='icon-box mb-flex j-center lg-flex align-center'>
                                    <img src={Trade} alt='trade' />
                                </div>
                                <h2 className='fw-700  '>Trade</h2>
                            </Link>
                        )}
                        {spotTab === 'future' && (
                            <Link to={'/trade-futures-group'} state={group.id} id={group.id} className='link' >
                                <div className='icon-box mb-flex j-center lg-flex align-center'>
                                    <img src={Trade} alt='trade' />
                                </div>
                                <h2 className='fw-700  '>Trade</h2>
                            </Link>
                        )}
                    </div>
                
                </div>
                {spotTab === 'spot' && (
                    <div className='wrapper border-1 border-radius-5 mb-2'>
                    <div class="table w-100 ">
                            <div class="row fw-600 light-blue">
                                <div class="cell">SN</div>
                                <div class="cell">Account name</div>
                                <div class="cell">Currency</div>
                                <div class="cell">Balance</div>
                                {/* <div class="cell">Email</div>
                                <div class="cell">Account ID</div>
                                <div class="cell">Grouping rule</div>
                                <div class="cell">Total in deposit</div>
                                <div class="cell">Total withdrawal</div>
                                <div class="cell">Total Profit</div>
                                <div class="cell">Total Loss</div> */}
                            </div>
                            {spots.length > 0 ? (
                            spots.map((groupOne, index) => {
                                // // console.log(group.accountSegments);
                                const targetUser = group.accountSegments[index]?.account?.accountBalances?.find(accountBalances  => accountBalances.asset === "USDT");
                                // // console.log(targetUser, "e get?");
                                return (
                                    <div class="row f-14">
                                        <div class="cell">{index+1}</div>
                                        <div class="cell">{groupOne.account.name}</div>
                                        <div class="cell">{targetUser.asset}</div>
                                        <div class="cell">{targetUser.freeBalance}</div>
                                        {/* <div class="cell">Lagos Nigeria</div>
                                        <div class="cell">0.002009</div>
                                        <div class="cell">767S77632HJ</div>
                                        <div class="cell">767S77632HJ</div>
                                        <div class="cell">767S77632HJ</div>
                                        <div class="cell">767S77632HJ</div>
                                        <div class="cell">767S77632HJ</div> */}
                                    </div>                     
                                )
                            })) : (
                                <div></div>
                            )}
                                                
                    </div>
                </div>
                )}

                {spotTab === 'future' && (
                    <div className='wrapper border-1 border-radius-5 mb-2'>
                        <div class="table w-100 ">
                                <div class="row fw-600 light-blue">
                                    <div class="cell">SN</div>
                                    <div class="cell">Account name</div>
                                    <div class="cell">Currency</div>
                                    <div class="cell">Balance</div>
                                    {/* <div class="cell">Email</div>
                                    <div class="cell">Account ID</div>
                                    <div class="cell">Grouping rule</div>
                                    <div class="cell">Total in deposit</div>
                                    <div class="cell">Total withdrawal</div>
                                    <div class="cell">Total Profit</div>
                                    <div class="cell">Total Loss</div> */}
                                </div>
                                {futures.length > 0 ? (
                                futures.map((groupOne, index) => {
                                    // // console.log(group.accountSegments);
                                    const targetUser = group.accountSegments[index]?.account?.accountBalances?.find(accountBalances  => accountBalances.asset === "USDT");
                                    // // console.log(targetUser, "e get?");
                                    return (
                                        <div class="row f-14">
                                            <div class="cell">{index+1}</div>
                                            <div class="cell">{groupOne.account.name}</div>
                                            <div class="cell">{targetUser.asset}</div>
                                            <div class="cell">{targetUser.availableBalance}</div>
                                            {/* <div class="cell">Lagos Nigeria</div>
                                            <div class="cell">0.002009</div>
                                            <div class="cell">767S77632HJ</div>
                                            <div class="cell">767S77632HJ</div>
                                            <div class="cell">767S77632HJ</div>
                                            <div class="cell">767S77632HJ</div>
                                            <div class="cell">767S77632HJ</div> */}
                                        </div>                     
                                    )
                                })) : (
                                    <div></div>
                                )}
                                                    
                        </div>
                    </div>
                )}
                </div>
             </div>
        </div>
    </div>
  )
}

export default ViewTradingGroups
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import SignIn from './pages/auth/SignIn';
import SplashScreen from './pages/auth/Splash';
import ForgotPassword from './pages/auth/ForgotPassword';
// import Layout from './components/Layout';
import Home from './pages/dashboard/Home';
import Logins from './pages/dashboard/Logins';
import History from './pages/dashboard/History';
import Settings from './pages/dashboard/Settings';
import TradingGroups from './pages/dashboard/TradingGroups';
import Page404 from './components/404/Page404';
import UnAuthorized from './components/404/UnAuthorized';
import Trade from './pages/dashboard/Trade';
import OTP from './pages/auth/OTP';
import ProtectedRoutes from './ProtectedRoutes';
import MailCheck from './pages/auth/MailCheck';
import ViewTradingGroups from './pages/dashboard/ViewTradingGroups';
import GroupTrade from './pages/dashboard/GroupTrade';
import ViewAccount from './pages/dashboard/ViewAccount';
import ViewFuturesAccount from './pages/dashboard/ViewFuturesAccount';
import TradeFutures from './pages/dashboard/TradeFutures';
import TradeFuturesGroup from './pages/dashboard/TradeFuturesGroup';
import Example from './pages/dashboard/Test';
// import CandlestickChart from './components/Chart';


function App() {
  return (
    <Router>
      <Routes>
        
        {/* PROTECTED */}
          <Route element={<ProtectedRoutes />} >
            <Route path='/' element={<Home />} exact />
            <Route path='logins' element={<Logins />} />
            <Route path='history' element={<History />} />
            <Route path='settings' element={<Settings />} />
            <Route path='trading-groups' element={<TradingGroups />} />
            <Route path='view-trading-groups' element={<ViewTradingGroups />} />
            <Route path='trade' element={<Trade />} />
            <Route path='group-trade' element={<GroupTrade />} />
            <Route path='account' element={<ViewAccount />} />
            <Route path='futures-account' element={<ViewFuturesAccount />} />
            <Route path='trade-futures' element={<TradeFutures />} />
            <Route path='trade-futures-group' element={<TradeFuturesGroup />} />
            {/* <Route path='chart' element={<CandlestickChart />} /> */}
          </Route>

        {/* PUBLIC */}
        <Route path='welcome' element={<SplashScreen />} />
        <Route path='signin' element={<SignIn />} />
        <Route path='otp' element={<OTP />} />
        <Route path='verify' element={<MailCheck />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='unauthorized' element={<UnAuthorized />} />
        <Route path='example' element={<Example />} />

          

        {/* CATCH */}
        <Route path='*' element={<Page404 /> } />
      </Routes>
    </Router>
  );
}

export default App;

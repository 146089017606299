import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

import Cookies from 'universal-cookie'
import axios from 'axios'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import CustomLoader from './CustomLoader';

const cookies = new Cookies();
const token = cookies.get("TOKEN");


const START_TRADE_URL = 'https://api.lanealitrade.com/common/start-trading';
const STOP_TRADE_URL = 'https://api.lanealitrade.com/common/place-sell-order';

const OpenPositionAssetRealTime = ({ accountId }) => {

  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'
    },
    duration: 4000,
    dismissable: true,
  })

  // const [successful, setSuccessful] = useState(false);

  // const [err, setErr] = useState(false);

  // const [theAccountId, setTheAccountID] = useState('');

  const [loading, setLoading ] = useState(false)
  

  const [socket, setSocket] = useState(null);

  const [pnls, setPnls] = useState([]);

  // const [pAmt, setPAmt] = useState('');

  // const [pSymbol, setPSymbol] = useState('');

  useEffect(() => {
    const s = io('https://api.lanealitrade.com');
    setSocket(s);
    // console.log('position updating:', accountId)
    // console.log('position soc:', s)
    return () => {
        if (socket) {
            socket.emit('unsubscribeAccount', { id: accountId });
            socket.close();
            // console.log('position soc pass:', socket)
        }
    };
  }, []); // Only run once, on mount

  useEffect(() => {
    if (socket) {
      socket.emit('subscribeAccount', { id: accountId });
      socket.on('positionRisk', updates => {
        const newPnls = [];
        // const newPnls = { ...pnls };
        if(updates && Array.isArray(updates)){

          updates.forEach(update => {
            // // console.log('position Risk update:', update);
            const entryPrice = parseFloat(update.entryPrice);
            const markPrice = parseFloat(update.markPrice);
            const positionAmt = parseFloat(update.positionAmt);
            // const posSymbol = parseFloat(update.symbol);
            const leverage = parseFloat(update.leverage);
            if (entryPrice && markPrice && positionAmt) {
              // const pnl = ((markPrice - entryPrice) / entryPrice) * positionAmt * 100;
              const pnl = (markPrice - entryPrice) * positionAmt;
              const roe = [pnl / (markPrice * positionAmt/ leverage )] * 100;
              // newPnls[update.symbol] = pnl.toFixed(2);
              // setPAmt(positionAmt);
              // setPSymbol(posSymbol)
              newPnls.push({
                ...update, 
                entryPrice,
                markPrice,
                positionAmt,
                roe: roe.toFixed(2),
                pnl: pnl.toFixed(2)
              })
            }
          });
        } else {
          // console.log(updates, 'updates')
        }
        setPnls(newPnls);
      });
    }
  }, [socket, accountId, pnls]);

  
  // closr POSITION
  // if positionAmt == - use this guy
  const startTrade = async (positionAmt, symbol) => {

    // console.log('symbol:', positionAmt.toString);
    const remMinus = positionAmt.toString().replace('-', '')
    // console.log('positionAmt:', remMinus);
    
  //   e.preventDefault();
  //   // console.log(e.currentTarget.id, );
  //  setTheAccountID(e.currentTarget.id); 
   
   
    const tradingConfig = {
      method: "post",
      url: START_TRADE_URL,
      data: {
        side: 'BUY', 
        accountId: accountId,
        orderType: 'MARKET',
        symbol: symbol,
        priceType: "PERCENT", 
        quantity: remMinus,
      },
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*',
              Authorization: `Bearer ${token}`,
          },
          // withCredentials: true
    };
// console.log(tradingConfig, 'start tradingConfig')
    try {
      setLoading(true)
      await axios(tradingConfig).then((result) => {
        // console.log(result);
        // const decoded = jwtDecode(result.data.token);
        
        // console.log(result);
        // console.log(result.data);

        const theError = result.data

        theError.map((sort) => {
          // // console.log(sort.account, 'account');
          // // console.log(sort.error, 'error');

          if (sort.error == null) {
            // console.log(sort.order, 'order');
            // setSuccessful(true);
            notyf.success("Successful")
            
            // window.location.href = "/";
            // alert('word')
          } else {
            // console.log(sort.error, 'error');
            // setSuccessful(false);
            notyf.error(sort.error)
            // setErr(sort.error)
            
          }
        })
        setLoading(false)
        
        // setSuccessful(true);
        // window.location.href = "/";
        // alert("yes");
      }).catch((error )=> {
        setLoading(false)
        // console.log("this error");
        // console.log(error);
        // setErr(error.response.data.message  )
        // console.log(error.response.data.message);  
        
      }
      )

    } catch (err) {

      // this part might just be redundant but leave it here
       
      // errRef.current.focus();
    }
  
  }

  // if positionAmt == + use this guy
  const sellTrade = async (positionAmt, symbol) => {
  
    const tradingConfig = {
      method: "post",
      url: STOP_TRADE_URL,
      data: {
        side: 'SELL', 
        accountId: accountId,
        orderType: 'MARKET',
        symbol: symbol,
        priceType: "PERCENT", 
        quantity: positionAmt,
      },
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*',
              Authorization: `Bearer ${token}`,
          },
          // withCredentials: true
    };

    // console.log(tradingConfig, 'selltradingconfig')

    try {
      setLoading(true)
      await axios(tradingConfig).then((result) => {
        // console.log(result);
        // const decoded = jwtDecode(result.data.token);
        // console.log(result.data.token);
        // console.log(result);

        const theError = result.data

        theError.map((sort) => {
          // // console.log(sort.account, 'account');
          // // console.log(sort.error, 'error');

          if (sort.error == null) {
            // console.log(sort.order, 'order');
            notyf.success("Successful")
            // setSuccessful(true);
            
            // window.location.href = "/";
            // alert('word')
          } else {
            // console.log(sort.error, 'error');
            // setSuccessful(false);
            notyf.error(sort.error)
            // setErr(sort.error)
            
          }
        })
        setLoading(false);
        // alert("yes");
      }).catch((error )=> {
        // console.log("this error");
        // console.log(error);
        // setErr(error)
        // console.log(error.response.data.message);  
        // // console.log(users.id);
      }
      )
    } catch (err) {
      // this part might just be redundant but leave it here
      // errRef.current.focus();
    }
  }

  
  // TAKE P STOP L
  // if positionAmt == - use this guy
  const [priceValue, setPriceValue ] = useState('')

  const [qtyValue, setQtyValue ] = useState('')

  const takeProfit = async (symbol, priceValue, qtyValue) => {

    // console.log('position price:', priceValue);
  // console.log('position the qty:', qtyValue); 
  // console.log('position symbol:', symbol); 

    const tradingConfig = {
      method: "post",
      url: START_TRADE_URL,
      data: {
        side: 'BUY', 
        accountId: accountId,
        orderType: 'MARKET',
        symbol: symbol,
        price: priceValue,
        priceType: "PERCENT", 
        quantity: qtyValue,
      },
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*',
              Authorization: `Bearer ${token}`,
          },
          // withCredentials: true
    };

    try {
      setLoading(true)
      await axios(tradingConfig).then((result) => {
        // console.log(result);
        // const decoded = jwtDecode(result.data.token);
        // console.log(result.data.token);
        // console.log(result);
        // console.log(result.data);

        const theError = result.data

        theError.map((sort) => {

          if (sort.error == null) {
            // console.log(sort.order, 'order');
            // setSuccessful(true);
            notyf.success("Successful")
            
          } else {
            // console.log(sort.error, 'error');
            // setSuccessful(false);
            notyf.error(sort.error)
            // setErr(sort.error)
            
          }
        })
        setLoading(false)
         
      }).catch((error )=> {
        setLoading(false)
        // console.log("this error");
        // console.log(error);
        // setErr(error.response.data.message  )
        // console.log(error.response.data.message);  
        
      }
      )

    } catch (err) {

      // this part might just be redundant but leave it here
       
      // errRef.current.focus();
    }
  
  }

  useEffect(() => {
    
  }, [priceValue]);

  useEffect(() => {
    
  }, [qtyValue]);

  // if positionAmt == + use this guy
  const stopLoss = async (symbol, priceValue, qtyValue) => {
    // console.log('position price:', priceValue);
    // console.log('position the qty:', qtyValue); 
    // console.log('position symbol:', symbol); 

    const tradingConfig = {
      method: "post",
      url: STOP_TRADE_URL,
      data: {
        side: 'SELL', 
        accountId: accountId,
        orderType: 'MARKET',
        symbol: symbol,
        price: priceValue,
        priceType: "PERCENT", 
        quantity: qtyValue,
      },
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*',
              Authorization: `Bearer ${token}`,
          },
          // withCredentials: true
    };

    try {
      setLoading(true)
      await axios(tradingConfig).then((result) => {
        // console.log(result);
        // const decoded = jwtDecode(result.data.token);
        // console.log(result.data.token);
        // console.log(result);

        const theError = result.data

        theError.map((sort) => { 

          if (sort.error == null) {
            // console.log(sort.order, 'order');
            // setSuccessful(true);
            
            // window.location.href = "/";
            // alert('word')
          } else {
            // console.log(sort.error, 'error');
            notyf.error(sort.error)
            // setSuccessful(false);
            // setErr(sort.error)
            
          }
        })
        setLoading(false);
        // alert("yes");
      }).catch((error )=> {
        // console.log("this error");
        // console.log(error);
        // setErr(error)
        // console.log(error.response.data.message);  
        // // console.log(users.id);
      }
      )
    } catch (err) {
      // this part might just be redundant but leave it here
      // errRef.current.focus();
    }
  }

  return (
    <div>
      {/* <h1>Binance Order Updates</h1> */}
      {loading ? 
      <>
        <CustomLoader />
      </> : pnls.map(item => {
        // console.log('here is item', item.pnl, item.positionAmt)
        return (
          <>
        {/* <h3 key={item.symbol}>PNL (ROE) for Symbol {item.symbol}: {item.pnl}USDT
          marketPrice: {item.markPrice}
          <br />
          entryPrice: {item.entryPrice}
        </h3> */}
          <div class="tab le w 0">
                          
              {/* <div class="row"> */}
                  <div class="row fw-700">
                      <div className="cell f-12">Symbol</div>
                      <div className="cell f-12">Size</div>
                      <div className="cell f-12">Entry Price</div>
                      <div className="cell f-12">Mark Price</div> 
                      <div className="cell f-12">Liq. Price</div>
                      {/* <div class="cell">Type</div>
                      <div class="cell">Trade Type</div> */}
                      <div className="cell f-12">PNL</div>
                      <div className="cell f-12">ROE</div>
                      <div className="cell f-12"></div>
                      <div className="cell f-12"></div>
                      <div className="cell f-12"></div>
                      <div className="cell f-12"></div>
                      {/* <div class="cell">TP/SL</div> */}
                  </div>
                
                    
                  <div key={item.symbol} class="row  f-12">
                      <div className="cell"><p className='fw-400 f-12'>{item.symbol}</p></div>
                      <div className="cell"><p className='fw-400 f-12'>{item.positionAmt}{item.symbol.replace("USDT", "")}</p></div>
                      <div className="cell"><p className='fw-400 f-12'>{item.entryPrice}</p></div>
                      <div className="cell"><p className='fw-400 f-12'>{item.markPrice}</p></div> 
                      <div className="cell"><p className='fw-400 f-12'>{item.liquidationPrice}</p></div>
                      {
                        item.pnl[0] === '-' ? (
                          <div className="cell fw-400 f-12 lg-flex"> <p className='red  fw-400 f-12'>{item.pnl}</p>USDT</div>
                          ) : (
                            <div className="cell fw-400 f-12 "> <p className='green  fw-400 f-12'> +{item.pnl}</p>USDT</div>
                        )
                      }
                      {
                        item.positionAmt.toString()[0] === '-' && item.pnl[0] !== '-' 
                        ? (
                            <div clasNames="cell green fw-400 f-12"><p className='green f-12'>+{item.roe.replace('-', '')}%</p></div>
                        ) 
                        : 
                        item.positionAmt.toString()[0] !== '-' && item.pnl[0] === '-' 
                        ? (
                          <div className="cell red fw-400 f-12"> {item.roe}%</div>
                          ) : 
                          item.positionAmt.toString()[0] !== '-' && item.pnl[0] !== '-' ?
                        (
                          <><div className="cell green fw-400 f-12">{item.roe}%</div></>
                        ) : <></>
                      }
                      {/* {
                        item.roe[0] === '-' ? (
                          <div class="cell red">{item.roe} % </div>
                          ) : (
                            <div class="cell green">+ {item.roe} %</div>
                        )
                      } */}
                      {
                        item.positionAmt.toString()[0] === '-' ? (
                          <div class="cell"><p className='pointer f-12' onClick={() => startTrade(item.positionAmt, item.symbol)}>cancel position</p> </div>
                          ) : (
                             
                            <div className="cell"> <p className='pointer f-12' onClick={() => sellTrade(item.positionAmt, item.symbol)}>Close position</p></div>
                        )
                      }
                      <div class="cell ">
                        <div>
                          <label className=' f-12'>Price</label>
                        </div>
                        <div> 
                          <input onChange={(e) => setPriceValue(e.target.value)} className='tp-input' type='text' required />
                        </div>
                      </div>
                      <div class="cell">
                        <div>
                          <label className=' f-12'>Qty</label>
                        </div>
                        <div>
                          <input onChange={(e) => setQtyValue(e.target.value)} className='tp-input' type='text'  required />
                        </div>
                      </div>
                      {
                        item.positionAmt.toString()[0] === '-' ? (
                          <div class="cell red"><p className='pointer' onClick={() => takeProfit(item.symbol, priceValue, qtyValue)}>close</p> </div>
                          ) : ( 
                            <div className="cell green"> <p className='pointer' onClick={() => stopLoss(item.symbol, priceValue, qtyValue)}>close at</p></div>
                        )
                      }
                  </div>
                  
                  

          </div>
        </>
      )})}
      {/* {Object.keys(pnls).map(symbol => (
        <h3 key={symbol}>PNL (ROE) for Symbol {symbol}: {pnls[symbol]}%</h3>
      ))} */}
    </div>
  );
};

export default OpenPositionAssetRealTime;
import { Link } from 'react-router-dom'
import Mail from '../../assets/mail.svg'

const MailCheck = () => {
    return (
        <div>
          <div className=' mb-flex h-100vh lg-flex j-center align-center pl-4 pr-4'>
            <div className='grid align-center pl-2 pr-2 pt-2 pb-2 h-50 w-50 text-center border-radius-30 border-1'>
              <div className='text-center'>
                <img src={Mail} alt='logo bird ' width={'20%'} />
              </div>
              <div className='bg-white pl-2 pr-2  '>
                <p className=' fw-700 uppercase'>please check your mail</p>
                <p className='pb-1'>Follow the instructions as seen in your mail</p>
                  <Link to={'/verify-email'}>
                <button className='w-50 bg-black pointer'>
                  <p className='text-center pt-1 pb-1 white '>Ok</p>
                
                </button>
                  </Link>
                
              </div>
            </div>
          </div>
        </div>
      )
}

export default MailCheck
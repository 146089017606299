import { useRef, useState } from 'react'
import '../../../src/App.css'
import Lmp from '../../assets/logo-name.svg'
// import Mail from '../../assets/forgot-password.svg'
import Bird from '../../assets/logo-bird.svg'
import { Link } from 'react-router-dom'

import axios from '../../api/axios'

import Cookies from 'universal-cookie'
import OTPInput from 'react-otp-input'
import jwtDecode from 'jwt-decode' 

import { Notyf } from 'notyf'
// import 'notyf/notyf.min.css'

const ForgotPassword = () => {

  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'
    },
    duration: 4000,
    dismissable: true,
  })


  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  // const [otp, setOtp] = useState('');
  
  const [errMsg, setErrMsg] = useState('');
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const [passwordReset, setPasswordReset] = useState(false);

  const ForgotPassword_URL = '/auth/password-reset';
  const Login_OTP_URL = '/auth/login-otp';
  const NEW_PASSWORD_URL = '/auth/change-password';

  
  const cookies = new Cookies();

  const token = cookies.get("TOKEN");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(email)


    const loginConfig = {
      method: "post",
      url: "https://api.lanealitrade.com"+ForgotPassword_URL,
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*'
          },
          // withCredentials: true
        data: {
          login: email,
        },
    };

    try {
      setLoading(true)
      await axios(loginConfig).then((result) => {
        // // console.log(result);
        // // console.log(JSON.stringify(result.data.token));
        // // console.log(JSON.stringify(result.data));
        // // console.log(JSON.stringify(result));
        setSuccessful(true);
        setLoading(false)
        notyf.success('OTP sent to your email')
      }).catch((error )=> {
        // console.log("this error");
        // console.log(error);
        // console.log(error.response.data);
        // console.log(error.response.data.statusCode);
        // console.log(error.response.data.message);
        // console.log(error.message);
        setLoading(false)
        notyf.error(error.message)
        if(!error) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 400) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 401) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 404) {
          setErrMsg('user ' + error.response.data.message);
        } else {
          setErrMsg(error.response.data.message);
        }
        errRef.current.focus();
      })

    } catch (err) {

      // this part might just be redundant but leave it here
      if(!err) {
        setErrMsg(err.response.data.message);
      } else if(err.response.data.statusCode === 400) {
        alert(setErrMsg(err.response.data.message));
      } else if(err.response.data.statusCode === 401) {
        alert(setErrMsg(err.response.data.message));
      } else {
        setErrMsg(err.response.data.message);
      }
      // errRef.current.focus();
    }
  
  }


  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    // console.log(email)


    const loginConfig = {
      method: "post",
      url: "https://api.lanealitrade.com"+NEW_PASSWORD_URL,
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*',
             Authorization: `Bearer ${token}`,
          },
          // withCredentials: true
        data: {
          password: password,
        },
    };

    try {
      setLoading(true)
      await axios(loginConfig).then((result) => {
        // // console.log(result);
        // // console.log(JSON.stringify(result.data.token));
        // // console.log(JSON.stringify(result.data));
        // // console.log(JSON.stringify(result));
        // setPasswordReset(true);
        
        cookies.remove("TOKEN", { path: "/" });
        setLoading(false)
        notyf.success('password reset successful')
        window.location.href = "/signin";
      }).catch((error )=> {
        // console.log("this error");
        // // console.log(error);
        // // console.log(error.response.data);
        // // console.log(error.response.data.statusCode);
        // // console.log(error.response.data.message);
        // console.log(error.message);
        setLoading(false)
        notyf.success(error.message)
        if(!error) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 400) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 401) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 404) {
          setErrMsg('user ' + error.response.data.message);
        } else {
          setErrMsg(error.response.data.message);
        }
        errRef.current.focus();
      })

    } catch (err) {

      // this part might just be redundant but leave it here
      if(!err) {
        setErrMsg(err.response.data.message);
      } else if(err.response.data.statusCode === 400) {
        alert(setErrMsg(err.response.data.message));
      } else if(err.response.data.statusCode === 401) {
        alert(setErrMsg(err.response.data.message));
      } else {
        setErrMsg(err.response.data.message);
      }
      // errRef.current.focus();
    }
  
  }

  const user = email;

  const [OTP, setOTP ] = useState('')

  function handleChange(OTP) {
    setOTP(OTP);
  }

  
  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    
    const newOTP = OTP;

    // console.log(newOTP);

    const loginConfig = {
      method: "post",
      url: "https://api.lanealitrade.com"+Login_OTP_URL,
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*',
            //  Authorization: `Bearer ${token}`,
          },
          // withCredentials: true
        data: {
          login: user,
          otp: newOTP,
        },
    };

    try {
      setLoading(true)
      await axios(loginConfig).then((result) => {
        // // console.log(result);
        // const decoded = jwtDecode(result.data.token);
        // // console.log(JSON.stringify(result.data.token));
        // // console.log(JSON.stringify(result));
        cookies.set("TOKEN", result.data.token, {
          // expires: new Date(decoded.exp * 2592000000),
          path: "/",
        });
        // window.location.href = "/";
        // setEmail(decoded);
        setPasswordReset(true);
        setLoading(false)
        notyf.success('OTP valid')
        // setSuccessful(true);
        // alert("we're in");
      }).catch((error )=> {
        // console.log("this error");
        // // console.log(error);
        // // console.log(error.response.data);
        // // console.log(error.response.data.statusCode);
        // // console.log(error.response.data.message);
        // console.log(error.message);
        setLoading(false)
        notyf.error(error.message)
        if(!error) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 400) {
          setErrMsg(error.response.data.message);
        } else if(error.response.data.statusCode === 401) {
          setErrMsg(error.response.data.message);
        } else {
          setErrMsg(error.response.data.message);
        }
        errRef.current.focus();
      })

    } catch (err) {

      // this part might just be redundant but leave it here
      if(!err) {
        setErrMsg(err.response.data.message);
      } else if(err.response.data.statusCode === 400) {
        alert(setErrMsg(err.response.data.message));
      } else if(err.response.data.statusCode === 401) {
        alert(setErrMsg(err.response.data.message));
      } else {
        setErrMsg(err.response.data.message);
      }
      // errRef.current.focus();
    }
  
  }


  return (
    <div>
      <div className='h-100vh lg-flex mb-flex j-center align-center'>
        
        {/* LEFT */}
        <div className=' w-100 h-100 ph-big-10 bg-purple-grad mb-fps-hidden mb-flex lg-flex align-center'>
          <div>
            <div>
              <img src={Lmp} width='100%' alt='logo' />
            </div>
            <p className='capitalize like-brown '>digital trading platform</p>
          </div>
        </div>

        {/* RIGHT */}

      <>
        {!successful ? (
          <div className='w-100 ph-big-10 ph-sm-4'>
           <div>
              <div className='pb-2  j-center text-center align-center'>
              <div >
                <div>
                  <img src={Bird} width='70%' alt='mail' />
                </div>
                
              </div>
              </div>
              <div>
                  <form 
                  // onSubmit={handleSubmit}
                  >
                      
                    <p ref={errRef} className={errMsg ? 'red' : 'hidden'} aria-live='assertive' >
                      {errMsg}
                    </p>
                    <div className='pb-1'>
                      <input 
                      type='email' 
                      name='email'
                      placeholder='Enter email' 
                      className='log-input pt-1 '  
                      value={email} 
                      autoComplete='off'
                      ref={userRef}
                      onChange={(e) => setEmail(e.target.value)} 
                      />
                    </div>
                    {
                      loading ? (
                      <div>
                        <button type='submit' onClick={(e) => e.preventDefault()} className='button bg-black white border-0'>
                          Loading...
                        </button> 
                      </div> 
                      ) : (
                      <div>
                        <button type='submit' onClick={(e) => handleSubmit(e)} className='button bg-black white border-0'>
                          Reset password
                        </button> 
                      </div> 
                      )
                    }
                  </form>
                
                  
                
              </div>
              <div className='pt-1 text-center mb-flex lg-flex j-center'>
                  <p className='purple pt-1 f-14'>
                    You remember your password? 
                  </p>
                <Link to={'/signin'}>
                  <p className='pt-1 black fw-600 f-14 pl-1'>
                    Sign in
                  </p>
                </Link>
              </div>
              <h1 className='text-center pt-1'>
                  
              </h1>
            </div> 
          </div> 

        ) : (
          
            <div className='w-100 ph-big-10 ph-sm-4'>
                <>
                  {!passwordReset ? (
                    <div>
                      <div>
                          
                        <form 
                        // onSubmit={handleOTPSubmit}
                        >
                          <h1 className='text-center hidden f-40 pt-1 uppercase'>
                              Enter OTP
                          </h1>
                          {/* <p className='pb-2'>OTP has been sent to your email</p> */}
                          <p ref={errRef} className={errMsg ? 'red' : 'hidden'} aria-live='assertive' >
                            {errMsg}
                          </p>
                          <div className='pb-1 lg-flex mb-flex j-center'>
                            
                            <OTPInput
                              onChange={handleChange}
                              value={OTP}
                              inputStyle="inputStyle"
                              numInputs={4}
                              separator={<span></span>}
                            />
                          </div>
                          {
                            loading ? (
                          <div>
                            <button onClick={(e) => e.preventDefault()} className='button bg-purple white border-0'>
                              Loading...
                            </button>
                          </div>
                            ) : (
                          <div>
                            <button onClick={handleOTPSubmit} className='button bg-purple white border-0'>
                              Verify
                            </button>
                          </div>
                            )
                          }
                          
                        </form>
                        
                      </div>
                      
                      <h1 className='text-center pt-1'>
                          
                      </h1>
                    </div> 
              
                    ): (
                    
                      <div>
                        <div className='pb-4  j-center text-center align-center'>
                        <div >
                          <div>
                            <img src={Bird} width='70%' alt='mail' />
                          </div>
                          
                        </div>
                        </div>
                        <div >
                            <form 
                            // onSubmit={handlePasswordSubmit}
                            >
                                
                              <p ref={errRef} className={errMsg ? 'red' : 'hidden'} aria-live='assertive' >
                                {errMsg}
                              </p>
                              <div className='pb-1'>
                                <input 
                                type='password' 
                                name='password'
                                placeholder='password' 
                                className='log-input pt-1 '  
                                value={password} 
                                autoComplete='off'
                                ref={userRef}
                                onChange={(e) => setPassword(e.target.value)} 
                                />
                              </div>
                              {
                                loading ? (

                              <div className='pt-2'>
                                <button type='submit' onClick={(e) => e.preventDefault()} className='button bg-black white border-0'>
                                  Loading...
                                </button> 
                              </div> 
                                ) : (
                              <div className='pt-2'>
                                <button type='submit' onClick={(e) => handlePasswordSubmit(e)} className='button bg-black white border-0'>
                                  Set new password
                                </button> 
                              </div> 
                                )
                              }
                            </form>
                          
                            
                          
                        </div>
                          
                      </div> 
                    )}
                </>
            </div>
          ) }
        </>

      </div>
    </div>
  )
}

export default ForgotPassword
import React from 'react'
import { LineWave } from 'react-loader-spinner'
// import Popup from 'reactjs-popup'

const CustomLoader = () => {
  return (
    <div>
         
        <div className='loading lg-flex mb-flex flex j-center align-center'>
            <div className=' lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-30 w-30 bg-white'>
                <div className=' '>
                    <LineWave color="green" middleLineColor="red" lastLineColor="green" />
                    <p>
                        Please wait
                    </p>
                </div>
            </div>
        </div>
 
    </div>
  )
}

export default CustomLoader
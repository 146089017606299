import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";

const ChartJs = ({ tradePair }) => {
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    // console.log(tradePair.toLowerCase(), 'lowering')
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fapi.binance.com/fapi/v1/klines",
          {
            params: {
              symbol: tradePair.toLowerCase(),
              interval: "1h",
              limit: 250,
            },
          }
        );

        const initialData = response.data.map((item) => [
          Number(item[0]),
          Number(item[1]),
          Number(item[2]),
          Number(item[3]),
          Number(item[4]),
        ]);

        setData(initialData);
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    };

    fetchData();
  }, [tradePair]);

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get("https://fapi.binance.com/fapi/v1/klines", {
          params: {
            symbol: tradePair.toLowerCase(),
            interval: "1h",
            limit: 1,
          },
        })
        .then((response) => {
          const newData = response.data.map((item) => [
            Number(item[0]),
            Number(item[1]),
            Number(item[2]),
            Number(item[3]),
            Number(item[4]),
            // console.log(item)
          ]);

          setData((prevData) => [...prevData, ...newData]);

          if (chartRef.current) {
            const series = chartRef.current.series[0];
            series.addPoint(newData[0], true, true);
          }
        })
        .catch((error) => {
          console.error("Error fetching market data:", error);
        });
    }, 30000); // Update every 30 seconds

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [tradePair]);

  const chartOptions = {
    chart: {
      height: 600, // Adjust the height as needed
      backgroundColor: "#f0f0f0", // Change the background color
    },
    rangeSelector: {
      selected: 120,
    },
    title: {
      text: tradePair,
    },
    series: [
      {
        type: "candlestick",
        name: tradePair,
        data: data,
        dataGrouping: {
          units: [
            // ["minute", [1, 3, 6]], // Group by 1, 3, and 6 minutes
            ["hour", [1, 2, 4]], // Group by 1, 2, and 4 hours
            ["day", [1]], // Group by 1 day
          ],
        },
      },
    ],
    plotOptions: {
      candlestick: {
        upColor: "#00b300", // Change the color of green candlesticks
        color: "#a52714", // Red color for falling candlesticks
        lineColor: "#a52714", // Red color for candlestick lines
      },
    },
  };

  return (
    <div id="container">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={chartOptions}
        ref={chartRef}
      />
    </div>
  );
};

export default ChartJs;

// import React, { useEffect, useRef } from "react";
// import anychart from "anychart";
// import "anychart/dist/css/anychart-ui.min.css";
// import "anychart/dist/fonts/css/anychart-font.min.css";
// import "anychart/dist/js/anychart-data-adapter.min.js";
// import "anychart/dist/js/anychart-annotations.min.js";
// import axios from "axios";

// const CandlestickChart = ({ tradePair }) => {
//   const chartInstance = useRef(null);
//   const chartCreated = useRef(false);

//   // useEffect(() => {
//   //   chartCreated.current = false;
//   // }, [tradePair])


//   useEffect(() => {
//     if (!chartCreated.current) {
//       const fetchCandlestickData = async () => {
//         try {
//           const response = await axios.get(
//             "https://fapi.binance.com/fapi/v1/klines",
//             {
//               params: {
//                 symbol: tradePair.toLowerCase(),
//                 interval: "1h",
//                 limit: 200,
//               },
//             }
//           );

//           const data = response.data.map((item) => {
//             console.log("item");
//             console.log(item);
//             return item;
//           });

//           createChart(data);
//           console.log(data);
//         } catch (error) {
//           console.error("Error fetching candlestick data:", error);
//         }
        
//       };


//       const createChart = (data) => {
//         const dataTable = anychart.data.table();
//         dataTable.addData(data);
//         console.log("dataTable");
//         console.log(dataTable);
//         console.log("data");
//         console.log(data);

//         const mapping = dataTable.mapAs({
//           open: 1,
//           high: 2,
//           low: 3,
//           close: 4,
//         });

//         console.log("mapping");
//         console.log(mapping);

//         const chart = anychart.stock();
//         anychart.theme("darkGlamour");

//         const plot = chart.plot(0);
//         plot.yGrid(true).xGrid(true).yMinorGrid(true).xMinorGrid(true);

//         const series = plot.candlestick(mapping);
//         series.name(tradePair);
//         series.legendItem().iconType("rising-falling");

//         const rangePicker = anychart.ui.rangePicker();
//         rangePicker.render(chart);

//         const rangeSelector = anychart.ui.rangeSelector();
//         rangeSelector.render(chart);

//         series.fallingFill("#FF0D0D");
//         series.fallingStroke("#FF0D0D");
//         series.risingFill("#43FF43");
//         series.risingStroke("#43FF43"); 

//         const indicatorPlot = chart.plot(1);
//         const macdIndicator = indicatorPlot.macd(mapping);
//         macdIndicator.histogramSeries("area");
//         macdIndicator
//           .histogramSeries()
//           .normal()
//           .fill("green .3")
//           .stroke("green");
//         macdIndicator
//           .histogramSeries()
//           .normal()
//           .negativeFill("red .3")
//           .negativeStroke("red");
//         indicatorPlot.height("30%");

//         // chart.selectRange("2020-01-01", "2022-12-31");
//         chart.title(tradePair + " Chart");
//         chart.container("container");
//         chart.draw();

//         chartInstance.current = chart[0];
//         console.log(tradePair);
//         chartCreated.current = false;
//       };
//       fetchCandlestickData();
//       console.log(tradePair);
//       chartInstance.current = null
//       chartCreated.current = true;
//     }
    
//   }, [tradePair]);
 

//   return (
//     <>
//       {/* {isLoading ? (
//         <>
//           <CustomLoader />
//         </>
//       ) : ( */}
//       <div
//         id="container"
//         style={{ width: "100%", height: "100vh", margin: 0, padding: 0 }}
//       ></div>
//       {/* )} */}
//     </>
//   );
// };

// export default CandlestickChart;

// import Popup from 'reactjs-popup';
// import Bin from '../assets/binance.svg'
// import BinBlack from '../assets/lock.svg'

// const Chart = () => {
//   return (
//     <div>
//         <div className='chart '>
//             <Popup trigger={
//                 <div className='pointer'>
//                     <img src={Bin} alt='binance logo'/>
//                 </div>
//             }
//             nested
//             modal
//             >
//                 {
//                     close => (
//                         <div className="modal lg-flex mb-flex j-center align-end">
//                             <div className=" lg-flex mb-flex j-end h-90vh bg-white w-100  bg-white">
//                                 <div className=" pl-2">
//                                     <div className="pt-4 pr-6 w-100">
//                                         <div className="lg-flex mb-flex j-end">

//                                             <div className='pointer' onClick={close} >
//                                             <img src={Bin} alt='binance logo'/>
//                                         </div>
//                                             {/* <Link to={'/home'}>
//                                                 <button className=" popup-btn bg-purple white h-100 fw-100" onClick={close}  ><p className=" f-12 fw-100">Finish</p></button>
//                                             </Link> */}
//                                                 {/* <AccountCreatedPopup open={openModal} onClose={()=> setOpenModal(false)}  /> */}
//                                             {/* <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={close}><p className="f-12">Cancel</p></button> */}
//                                         </div>

//                                         <div>
//                                             <div className='w-100'>

//                                             </div>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                     )
//                 }

//             </Popup>
//         </div>

//     </div>
//   )
// }

// export default Chart

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './dropdown.css';

// import {  BrowserRouter, 
//   // createBrowserRouter,  
//   Route,  
//   // RouterProvider, 
//   Routes, } from "react-router-dom";

// import SplashScreen from './pages/auth/Splash';
// import SignIn from './pages/auth/SignIn';
// import ForgotPassword from './pages/auth/ForgotPassword';
// import VerifyEmail from './pages/auth/VerifyEmail';
// import Home from './pages/dashboard/Home';
// import History from './pages/dashboard/History';
// import Dashboard from './pages/dashboard/Dashboard';
// import Settings from './pages/dashboard/Settings';
// import Logins from './pages/dashboard/Logins';
// import TradingGroups from './pages/dashboard/TradingGroups';
// import Page404 from './components/404/Page404';
// import Trade from './pages/dashboard/Trade';
// import MailCheck from './pages/auth/MailCheck';
// import Chart from './components/Chart';
// import { AuthProvider } from './context/AuthProvider';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';



// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <SplashScreen />,
//   },
//   {
//     path: "/mail-check",
//     element: <MailCheck />,
//   },
//   {
//     path: "/signin",
//     element: <SignIn />,
//   },
//   {
//     path: "/forgot-password",
//     element: <ForgotPassword />,
//   },
//   {
//     path: "/verify-email",
//     element: <VerifyEmail />,
//   },
//   {
//     path: "/dashboard",
//     element: <Dashboard />,
//   },
//   {        
//     path: "/home",
//     element: <Home />,
//   },
//   {        
//     path: "/home/trade",
//     element: <Trade />,
//   },
//   {
//     path: "/history",
//     element: <History />,
//   },
//   {
//     path: "/logins",
//     element: <Logins />,
//   },
//   {
//     path: "/settings",
//     element: <Settings />,
//   },
//   {
//     path: "/trading-groups",
//     element: <TradingGroups />,
//   },
//   {
//     path: "/chart",
//     element: <Chart />,
//   },
//   {
//     path: "/*",
//     element: <Page404 />,
//   },


  
   
  
// ])

ReactDOM.createRoot(document.getElementById("root")).render(
  // <AuthProvider>
  //   <RouterProvider router={router} />
  // </AuthProvider>
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <GoogleOAuthProvider clientId="1066320863829-bp2b8t9citvdmor4vjf7usb6snah0lds.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
    {/* </BrowserRouter> */}
  </React.StrictMode>

);
 
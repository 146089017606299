import React from 'react'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import TopMenu from '../../components/TopMenu'
 
const History = () => {
    return (
        <div>
            <TopMenu />
      <SideNav />
    <Header />
            <div className='main-doc'>
                <div className='pt-1 pb-1 pl-1 pr-1'>
                    {/* <TopMenu /> */}
                
                    {/*  */}
                    <div className='pt-1 pb-2'>
                       
                    {/* TABLE FORM */}
                    <div className='wrapper border-1 border-radius-5 mb-2'>
                        <div class="table ">
                                <div class="row light-blue">
                                    <div class="cell">SN</div>
                                    <div class="cell">Time</div>
                                    <div class="cell">Type</div>
                                    <div class="cell">Deposit wallet</div>
                                    <div class="cell">Asset</div>
                                    <div class="cell">Amount</div>
                                    <div class="cell">Destination</div>
                                    <div class="cell">TxID</div>
                                    <div class="cell">Status</div>
                                    
                                    
                                </div>
                                <div class="row">
                                    <div class="cell">1</div>
                                    <div class="cell">2023-01-10</div>
                                    <div class="cell">inbound</div>
                                    <div class="cell">Spot wallet</div>
                                    <div class="cell">BTC</div>
                                    <div class="cell">0.002009</div>
                                    <div class="cell">ID2HGYYD776</div>
                                    <div class="cell">767S77632HJ</div>
                                    <div class="cell">completed</div>
                                </div>                     
                        </div>
                    </div>

                    {/*   */}
                    <div className='wrapper border-1 border-radius-5 mb-2'>
                        <div class="table ">
                                <div class="row light-blue">
                                    <div class="cell">SN</div>
                                    <div class="cell">Time</div>
                                    <div class="cell">Type</div>
                                    <div class="cell">Deposit wallet</div>
                                    <div class="cell">Asset</div>
                                    <div class="cell">Amount</div>
                                    <div class="cell">Destination</div>
                                    <div class="cell">TxID</div>
                                    <div class="cell">Status</div>
                                    
                                    
                                </div>
                                <div class="row">
                                    <div class="cell">2</div>
                                    <div class="cell">2023-01-10</div>
                                    <div class="cell">inbound</div>
                                    <div class="cell">Spot wallet</div>
                                    <div class="cell">BTC</div>
                                    <div class="cell">0.002009</div>
                                    <div class="cell">ID2HGYYD776</div>
                                    <div class="cell">767S77632HJ</div>
                                    <div class="cell">completed</div>
                                </div>                     
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
      )
}

export default History

import { Link } from 'react-router-dom'
import Bird from '../../assets/logo-bird.svg'
import Lmp from '../../assets/logo-name.svg'

const Page404 = () => {
  return (
    <div>
      <div className='h-100vh lg-flex mb-flex j-center align-center'>
        
        {/* LEFT */}
        <div className=' w-100 h-100 ph-big-10 bg-purple-grad mb-fps-hidden mb-flex lg-flex align-center'>
          <div>
            <div>
              <img src={Lmp} width='100%' alt='logo' />
            </div>
            <p className='capitalize like-brown '>digital trading platform</p>
          </div>
        </div>

        {/* RIGHT */}

      <>
          <div className='w-100 ph-big-10 ph-sm-4'>
           <div>
              <div className='pb-2  j-center text-center align-center'>
              <div >
                <div>
                  <img src={Bird} width='70%' alt='mail' />
                </div>
                
              </div>
              </div>
              <div>
                 
                
                  
                
              </div>
              <div className='pt-1 text-center mb-flex lg-flex j-center'>
                  <p className='purple pt-1 f-14'>
                    You are not supposed to be here.
                  </p>
                <Link to={'/'}>
                  <p className='pt-1 black fw-600 f-14 pl-1'>
                    Go home
                  </p>
                </Link>
              </div>
              <h1 className='text-center pt-1'>
                  
              </h1>
            </div> 
          </div> 

        
        </>

      </div>
    </div>
  )
}

export default Page404
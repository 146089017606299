import React, { useEffect, useState } from 'react'
// import TopMenu from '../../components/TopMenu'
// import TakeProfit from '../../assets/take-profit.svg'
import Trade from '../../assets/trade.svg'
// import StopLoss from '../../assets/stop-loss.svg'
import TopMenu from '../../components/TopMenu'
import { Link, useLocation } from 'react-router-dom'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'

// import Good from '../../assets/red-mark.svg'
// import Popup from 'reactjs-popup'

// import ShowAlert from '../../components/warnings/Alert'
 
 

import Cookies from 'universal-cookie'
import axios from 'axios'
import CustomLoader from '../../components/CustomLoader'

const cookies = new Cookies();
const token = cookies.get("TOKEN");

// const ARCHIVE_URL = 'https://api.lanealitrade.com/common/flag-account/';


const Home = () => {

  const [users, getUsers ] = useState('');
  
  const [spots, getSPots ] = useState('');
  
  const [futures, getFutures ] = useState('');

  const [spotTab, setSpotTab] = useState('future');
  
  // const [successful, setSuccessful] = useState(false);

  const [error, getErrors ] = useState('');
  // const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [showMore, setShowMore ] = useState(false)

  const [ theFiltered, setTheFiltered ] = useState('')


  const [status, setStatus ] = useState('')
  const [theTraderAsset, setTraderAssets ] = useState('')

  const getAllUsers = async () => {
    try {
      setLoading(true)
      await axios.get(
          'https://api.lanealitrade.com/common/trading-accounts',
          {
              headers: {
              'Content-Type': 'application/json',
                'accept': '*/*',
                Authorization: `Bearer ${token}`,
                }
          }
          ).then(
          (response) => {
              // // console.log(response);
              // // console.log('response.data');
              // console.log(response.data);
              // console.log(response);
              const allUsers = response.data;
              getUsers(allUsers);
              const spotAccounts = allUsers.filter(theType => theType.accountType === "spot");
              const futuresAccounts = allUsers.filter(theType => theType.accountType !== "spot");
              getSPots(spotAccounts)
              getFutures(futuresAccounts)
              // console.log(spotAccounts, 'spots')
              // console.log(futuresAccounts, 'futures')

              allUsers.map((trader) =>{
                // console.log(trader.tradingAssets, 'filesds');
                // const filteredStatus = trader.tradingAssets?.filter(item => item.status !== 'FILLED' )
                trader.tradingAssets.map((trade) => {
                  // console.log(trade.status, 'status');
                  setStatus(trade)
                })
                // console.log(trader.tradingAssets, 'statss');
                setTraderAssets(trader.tradingAssets)
                setTheFiltered(trader.tradingAssets)
                // console.log(theTraderAsset, 'shanks');
              })
              // // console.log(filteredStatus, 'filleds');
              setLoading(false)
          }
      ).catch((error) => {
          // console.log(error);
          // console.log(error.message);
          const theError = error.message;
          getErrors(theError);
          setLoading(false) 
      } )
      setLoading(false) 
    } catch (error) {
      setLoading(false)
    }
  }


  const handleSpotClick = (tab) => {
    setSpotTab(tab);
  };

 

  // const handleShowMore = (index) => {
  //   const newUsers = [...users];
  //   newUsers[index].showMore = !newUsers[index].showMore;
  //   getUsers(newUsers);
  //   // console.log('click')
  // };
  // const handleShowMoreTwo = (index) => {
  //   const newUsers = [...users];
  //   newUsers[index].showMore = !newUsers[index].showMore;
  //   getUsers(newUsers);
  //   // console.log('click')
  // };

  
    useEffect(() => {
        getAllUsers();
        // startTrade();
    },[])
    // useEffect(() => {
    //   // console.log(ARCHIVE_URL+theAccountId, "this one");
    // }, [theAccountId])

  return (
    <div>
      <div className='bg-white'>
        <TopMenu />
        <SideNav />
        <Header />
      </div>
      <div className=' '>
      </div>
      <div className='main-doc'>

      <div className='tab pt-4-5 pl-2'>
          <div className='bg-grey w-27 p-1 border-radius-10 lg-flex j-center'>
            <button className={spotTab === "future" ? " active popup-btn bg-spot white mr-1 fw-400 f-12 " : " popup-btn bg-white mr-1 fw-400 f-12 "} onClick={() => handleSpotClick("future")}>Futures account</button>
            <button className={spotTab === "spot" ? "active popup-btn bg-spot white fw-400 f-12 " : "popup-btn bg-white fw-400 f-12 "} onClick={() => handleSpotClick("spot")}>Spot account</button>
          </div>
      
        </div>


{/* spots */}

        {spotTab === "spot" && (

        <div className='pt-1 pb-1 pl-1 pr-1 z--1'>
          
              {/* <TopMenu /> */}
               

              {
                loading ? (
                  // <div className='flex j-sb align-center'>Loading...</div>
                  <CustomLoader />
                ) :
                spots.length > 0 ? (

                spots.map((user, index) => {
                  const targetUser = users[index]?.accountBalances?.find(accountBalances  => accountBalances.asset === "USDT");
                  
                  // // console.log(user.tradingAssets, "all users");
                  // // console.log(user.tradingAssets?.length, "the length");
                    return (
                <div className='pt-1 pb-2'>
                  <div className=' border-1 border-radius-5 '>
                    <div className='lg-flex align-center j-sb pl-2 pr-2 '>

                      {/* <div className=''>
                        <h2 className='fw-700   pr-2'>{index +1}</h2> 
                      </div> */}
                      
                      <div className='lg-flex flex-wrap pt-1'>
                        
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>SN </h2>
                          <p>{index + 1}</p>
                        </div>
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Account name</h2>
                          <p>{user.name}</p>
                        </div>
                        
                        {/* ADD TO GROUP */}
                        
                        {/* <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Actions</h2>
                            <button className='pointer border-0 bg-white' value={user.id} onClick={handleArchive}>Archive account</button> 
                        </div> */}

                        {/* <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Account ID </h2>
                          <p>BI8273799</p>
                        </div> */}
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Currency</h2>
                          <p>{targetUser.asset}</p>
                          {/* {user.accountBalances.find(obj => obj["USDT"] === 'USDT')?.freeBalance} */}
                        </div>
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Balance</h2>
                          <p>{targetUser.freeBalance}</p>
                        </div>
                       
                        {/* second */}
                        <div className='lg-flex mb-flex '>
                          {/* <div className='pr-2 pt-2'>
                            <h2 className='fw-700  '>Total loss</h2>
                            <p>$0</p>
                          </div> */}
                          <div className='  pr-2 pt-2 text-center pointer grid ji-center'>
                            <Link to={'/trade'} state={user.id} id={user.id}  className='text-none black'>
                              <div className='icon-box mb-flex j-center lg-flex align-center'>
                                <img src={Trade} alt='trade' />
                              </div>
                              <h2 className='fw-700  '>Trade</h2>
                            </Link>
                          </div>
                          <div className='  pr-2 pt-2 text-center pointer grid ji-center'>
                            

                            {/* <Popup trigger={<div className='  text-center pointer grid ji-center'>
                              <div className='icon-box mb-flex j-center lg-flex align-center'>
                                <img src={StopLoss} alt='stoploss' />
                              </div>
                              <h2 className='fw-700  '>Stop loss</h2>
                              </div>
                              }
                                    modal
                                    nested
                                >
                                    {
                                        close => (
                                            
                                            <div className='modal lg-flex mb-flex j-center align-center'>
                                              <div className=' lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-50 w-50 bg-white'>
                                              
                                              </div>
                                            </div>

                                        )
                                    }
                            </Popup> */}


                            {/*  */}


                            
                          </div>
                         
                        </div>
                      </div>
                      
                      <Link to={"/account"} className='popup-btn pt-1 text-none pb-1 bg-purple white mr-1 h-100 fw-100 pointer' state={user.id} id={user.id} > 
                        <div>
                          view account
                        </div>
                      </Link>
                      
                      
                    </div>
                    {/* {theTraderAsset !== '' ? (
                      user.tradingAssets.some(asset => asset.status !== "FILLED") && (
                        <div>
                          { theFiltered.length > 0 ? 
                            <div className='light-blue pl-2 pr-2'>
                              {!user.showMore ? (
                                <div className='lg-flex table align-center j-sb'>
                                  <div class="row f-14">
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Action</div>
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Symbol</div> 
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Buy price</div>
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Status</div>    
                                  </div>
                                  <div>
                                    <p className='pointer' onClick={() => handleShowMore(index)}>
                                      {user.showMore ? "Hide" : "Show more"}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {user.tradingAssets.some(asset => asset.status !== "FILLED") && (
                                    <div className='lg-flex j-sb pt-1'>
                                      <div className='table '>
                                        <div class="row f-14">
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Action</div>
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Symbol</div> 
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Buy price</div>
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Status</div>
                                        </div>        
                                        {user?.tradingAssets?.length > 0 ? (
                                          user.tradingAssets.map((asset, index) => {
                                            return (
                                              <>
                                                {asset.status !== "FILLED" && (
                                                  <div class="row">
                                                    <div class="table-cell f-12"> {asset.action}</div>
                                                    <div class="table-cell f-12"> {asset.symbol}</div>
                                                    <div class="table-cell f-12"> {asset.stopPrice}</div>
                                                    <div class="table-cell f-12"> {asset.status}</div>
                                                    <div class="table-cell f-12">  </div>
                                                    <div class="table-cell f-12">  </div>
                                                  </div>
                                                )}
                                              </>
                                            )
                                          })
                                        ) :
                                        <>
                                          No Active trades
                                        </>
                                        }
                                      </div>
                                      <p className='pointer' onClick={() => handleShowMore(index)}>
                                        {user.showMore ? "Hide" : "Show more"}
                                      </p>
                                    </div>
                                  )}
                                </>
                              )}
                            </div> 
                            :
                            <div></div>
                          }
                        </div>
                      )
                    ) : <></>} */}

                  </div>
                </div>

                  )
                  }
                  )
                  ) : 
                  (
                  <div>
                      {
                        !error ? (
                          <div>
                            No Accounts
                          </div>
                        ) : (
                          <div>
                            {error}
                          </div>
                        )
                      }
                  </div>
                  )

                }
             
        </div>
        )}


{/* futures */}

        {spotTab === "future" && (
          
        <div className='pt-1 pb-1 pl-1 pr-1 z--1'>
          
              {/* <TopMenu /> */}
              

              {
                loading ? (
                  // <div className='flex j-sb align-center'>Loading...</div>
                  <CustomLoader />
                ) :
                futures.length > 0 ? (

                futures.map((user, index) => {
                  const targetUser = futures[index]?.accountBalances?.find(accountBalances  => accountBalances.asset === "USDT");
                  const userId =user.id
                  // // console.log(targetUser, "this you");
                  // // console.log(user.tradingAssets?.length, "the length");
                    return (
                <div className='pt-1 pb-2'>
                  <div className=' border-1 border-radius-5 '>
                    <div className='lg-flex align-center j-sb pl-2 pr-2 '>

                      {/* <div className=''>
                        <h2 className='fw-700   pr-2'>{index +1}</h2> 
                      </div> */}
                      
                      <div className='lg-flex flex-wrap pt-1'>
                        
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>SN </h2>
                          <p>{index + 1}</p>
                        </div>
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Account name</h2>
                          <p>{user.name}</p>
                        </div>
                        
                        {/* ADD TO GROUP */}
                        
                        {/* <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Actions</h2>
                            <button className='pointer border-0 bg-white' value={user.id} onClick={handleArchive}>Archive account</button> 
                        </div> */}

                        {/* <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Account ID </h2>
                          <p>BI8273799</p>
                        </div> */}
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Currency</h2>
                          <p>{targetUser.asset}</p>
                          {/* {user.accountBalances.find(obj => obj["USDT"] === 'USDT')?.freeBalance} */}
                        </div>
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Balance</h2>
                          <p>{targetUser.availableBalance}</p>
                        </div>
                       
                        {/* second */}
                        <div className='lg-flex mb-flex '>
                          {/* <div className='pr-2 pt-2'>
                            <h2 className='fw-700  '>Total loss</h2>
                            <p>$0</p>
                          </div> */}
                          <div className='  pr-2 pt-2 text-center pointer grid ji-center'>
                            <Link to={'/trade-futures'} state={user.id} id={user.id}  className='text-none black'>
                              <div className='icon-box mb-flex j-center lg-flex align-center'>
                                <img src={Trade} alt='trade' />
                              </div>
                              <h2 className='fw-700  '>Trade</h2>
                            </Link>
                          </div>
                          <div className='  pr-2 pt-2 text-center pointer grid ji-center'>
                            

                            {/* <Popup trigger={<div className='  text-center pointer grid ji-center'>
                              <div className='icon-box mb-flex j-center lg-flex align-center'>
                                <img src={StopLoss} alt='stoploss' />
                              </div>
                              <h2 className='fw-700  '>Stop loss</h2>
                              </div>
                              }
                                    modal
                                    nested
                                >
                                    {
                                        close => (
                                            
                                            <div className='modal lg-flex mb-flex j-center align-center'>
                                              <div className=' lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-50 w-50 bg-white'>
                                              
                                              </div>
                                            </div>

                                        )
                                    }
                            </Popup> */}


                            {/*  */}


                            
                          </div>
                         
                        </div>
                      </div>
                      
                        <div className='mb-2 mt-1'>
                          <Link to={"/futures-account"} className='popup-btn pt-1 text-none pb-1 bg-purple white mr-1 h-100 fw-100 pointer' state={user.id} id={user.id} > 
                              view account
                          </Link>
                        </div>
                      
                      
                    </div>
                    {/* {theTraderAsset !== '' ? (
                      user.tradingAssets.some(asset => asset.status !== "FILLED") && (
                        <div>
                          { theFiltered.length > 0 ? 
                            <div className='light-blue pl-2 pr-2'>
                              {!user.showMore ? (
                                <div className='lg-flex table align-center j-sb'>
                                  <div class="row f-14">
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Action</div>
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Symbol</div> 
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Buy price</div>
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Status</div>    
                                  </div>
                                  <div>
                                    <p className='pointer' onClick={() => handleShowMore(index)}>
                                      {user.showMore ? "Hide" : "Show more"}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {user.tradingAssets.some(asset => asset.status !== "FILLED") && (
                                    <div className='lg-flex j-sb pt-1'>
                                      <div className='table '>
                                        <div class="row f-14">
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Action</div>
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Symbol</div> 
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Buy price</div>
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Status</div>
                                        </div>        
                                        {user?.tradingAssets?.length > 0 ? (
                                          user.tradingAssets.map((asset, index) => {
                                            return (
                                              <>
                                                {asset.status !== "FILLED" && asset.status !== "CANCELED" && (
                                                  <div class="row">
                                                    <div class="table-cell f-12"> {asset.action}</div>
                                                    <div class="table-cell f-12"> {asset.symbol}</div>
                                                    <div class="table-cell f-12"> {asset.stopPrice}</div>
                                                    <div class="table-cell f-12"> {asset.status}</div>
                                                    <div class="table-cell f-12"> shift </div>
                                                    <div class="table-cell f-12">  </div>
                                                  </div>
                                                )}
                                              </>
                                            )
                                          })
                                        ) :
                                        <>
                                          No Active trades
                                        </>
                                        }
                                      </div>
                                      <p className='pointer' onClick={() => handleShowMoreTwo(index)}>
                                        {user.showMore ? "Hide" : "Show more"}
                                      </p>
                                    </div>
                                  )}
                                </>
                              )}
                            </div> 
                            :
                            <div></div>
                          }
                        </div>
                      )
                    ) : <></>
                    } */}

                  </div>
                </div>

                  )
                  }
                  )
                  ) : 
                  (
                  <div>
                      {
                        !error ? (
                          <div>
                            No Accounts
                          </div>
                        ) : (
                          <div>
                            {error}
                          </div>
                        )
                      }
                  </div>
                  )

                }
             
        </div>
        )}


{/*  */}
        {/* <div className='pt-1 pb-1 pl-1 pr-1 z--1'>
           
              {
                loading ? (
                  <div className='flex j-sb align-center'>Loading...</div>
                ) :
                users.length > 0 ? (

                users.map((user, index) => {
                  const targetUser = users[index]?.accountBalances?.find(accountBalances  => accountBalances.asset === "USDT");
                  
                  
                    return (
                <div className='pt-1 pb-2'>
                  <div className=' border-1 border-radius-5 '>
                    <div className='lg-flex align-center j-sb pl-2 pr-2 '>

                    
                      <div className='lg-flex flex-wrap pt-1'>
                        
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>SN </h2>
                          <p>{index + 1}</p>
                        </div>
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Account name</h2>
                          <p>{user.name}</p>
                        </div>
                        
                     
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Account ID </h2>
                          <p>BI8273799</p>
                        </div>
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Currency</h2>
                          <p>{targetUser.asset}</p>
                         </div>
                        <div className='pr-2 pt-2'>
                          <h2 className='fw-700  '>Balance</h2>
                          <p>{targetUser.freeBalance}</p>
                        </div>
                       
                        
                        <div className='lg-flex mb-flex '>
                           <div className='  pr-2 pt-2 text-center pointer grid ji-center'>
                            <Link to={'/trade'} state={user.id} id={user.id}  className='text-none black'>
                              <div className='icon-box mb-flex j-center lg-flex align-center'>
                                <img src={Trade} alt='trade' />
                              </div>
                              <h2 className='fw-700  '>Trade</h2>
                            </Link>
                          </div>
                          <div className='  pr-2 pt-2 text-center pointer grid ji-center'>
                            
 
                            
                          </div>
                         
                        </div>
                      </div>
                      
                      <Link to={"/account"} className='popup-btn pt-1 text-none pb-1 bg-purple white mr-1 h-100 fw-100 pointer' state={user.id} id={user.id} > 
                        <div>
                          view account
                        </div>
                      </Link>
                      
                      
                    </div>
                    {theTraderAsset !== '' ? (
                      user.tradingAssets.some(asset => asset.status !== "FILLED") && (
                        <div>
                          { theFiltered.length > 0 ? 
                            <div className='light-blue pl-2 pr-2'>
                              {!user.showMore ? (
                                <div className='lg-flex table align-center j-sb'>
                                  <div class="row f-14">
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Action</div>
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Symbol</div> 
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Buy price</div>
                                    <div class="table-cell pr-1 purple fw-700 f-18"> Status</div>    
                                  </div>
                                  <div>
                                    <p className='pointer' onClick={() => handleShowMore(index)}>
                                      {user.showMore ? "Hide" : "Show more"}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <>
                                     <div className='lg-flex j-sb pt-1'>
                                      <div className='table '>
                                        <div class="row f-14">
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Action</div>
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Symbol</div> 
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Buy price</div>
                                          <div class="table-cell pr-1 purple fw-700 f-18"> Status</div>
                                        </div>        
                                        {user?.tradingAssets?.length > 0 ? (
                                          user.tradingAssets.map((asset, index) => {
                                            return (
                                              <>
                                                {asset.status !== "FILLED" && (
                                                  <div class="row">
                                                    <div class="table-cell f-12"> {asset.action}</div>
                                                    <div class="table-cell f-12"> {asset.symbol}</div>
                                                    <div class="table-cell f-12"> {asset.stopPrice}</div>
                                                    <div class="table-cell f-12"> {asset.status}</div>
                                                    <div class="table-cell f-12">  </div>
                                                    <div class="table-cell f-12">  </div>
                                                  </div>
                                                )}
                                              </>
                                            )
                                          })
                                        ) :
                                        <>
                                          No Active trades
                                        </>
                                        }
                                      </div>
                                      <p className='pointer' onClick={() => handleShowMore(index)}>
                                        {user.showMore ? "Hide" : "Show more"}
                                      </p>
                                    </div>
                                
                                </>
                              )}
                            </div> 
                            :
                            <div></div>
                          }
                        </div>
                      )
                    ) : <></>}

                  </div>
                </div>

                  )
                  }
                  )
                  ) : 
                  (
                  <div>
                      {
                        !error ? (
                          <div>
                            No Accounts
                          </div>
                        ) : (
                          <div>
                            {error}
                          </div>
                        )
                      }
                  </div>
                  )

                }
             
        </div> */}
      </div>
    </div>
  )
}

export default Home
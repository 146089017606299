// import {useRef, useState, useEffect } from 'react'
// import '../../../src/App.css'
// import Lmp from '../../assets/logo-name.svg'
// import LogoPurple from '../../assets/logo-purple.svg'
// import { Link, useLocation, useNavigate } from 'react-router-dom'
// import axios from '../../api/axios'
// import useAuth from '../../hooks/useAuth'

// const Login_URL = '/auth/login';
// const Login_OTP_URL = '/auth/login-otp';

// const OTP = () => {
//   const { setAuth } = useAuth();

//   const navigate = useNavigate();
//   const location = useLocation();
//   const from =  '/';

//   const userRef = useRef();
//   const errRef = useRef();

//   const [user, setUser] = useState('');
//   const [pwd, setPwd] = useState('');
//   const [errMsg, setErrMsg] = useState('');
//   const [successful, setSuccessful] = useState(false);


//   // setfocus on first input wjen component loads
//   useEffect(() => {
//     userRef.current.focus();
//   }, [])


//   // empty out error msg if user chabges text of input
//   useEffect(() => {
//     setErrMsg('');
//   }, [user, pwd])

//   var email = user;

  

//   const handleOTPSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         Login_OTP_URL,
//         JSON.stringify({login: email, otp: pwd}),
//         {
//           headers: {
//             'Content-Type': 'application/json',
//               'accept': '*/*'
//           },
//         }
//       );
//       // console.log(JSON.stringify(response.data));
//       setAuth({email, pwd});
//       setUser('');
//       setPwd('');
//       navigate(from, {replace: true});
//     } catch (err) {
//       if(!err) {
//         setErrMsg(err.response.data['message']);
//       } else if(err.response?.statusCode === 400) {
//         setErrMsg(err.response.data['message']);
//       } else if(err.response?.statusCode === 401) {
//         setErrMsg(err.response.data['message']);
//       } else {
//         setErrMsg(err.response.data['message']);
//       }
//       errRef.current.focus();
//     }
//   }

//   return (
    
//     <div>
//       <div className='h-100vh lg-flex mb-flex j-center align-center'>
//         <div className=' w-100 h-100 ph-big-10 bg-grey mb-hidden mb-flex lg-flex align-center'>
//           <div  >
//             <div>
//               <img src={Lmp} width='100%' alt='logo' />
//             </div>
//             <p className='capitalize purple '>digital trading platform</p>
//           </div>
//         </div>
//         <div className='w-100 ph-big-10 '>
//           <div className=' lg-hidden j-center text-center align-center'>
//             <div >
//               <div>
//                 <img src={LogoPurple} alt='logo purple' width='70%' />
//               </div>
//               <p className='capitalize purple  pt-1'>digital trading platform</p>
//             </div>
//           </div>

//           {/*OTP VERIFY LOGIN */}

//           <div className=''>
//             <form onSubmit={handleOTPSubmit}>
//               <h1 className='text-center f-40 pt-1'>
//                   Verify OTP
//               </h1>
//               <p ref={errRef} className={errMsg ? 'red' : 'hidden'} aria-live='assertive' >
//                 {errMsg}
//               </p>
//               <div className='pb-1'>
//                 <input 
//                 type='text' 
//                 placeholder='username' 
//                 className='log-input pt-1 '  
//                 id='username'
//                 autoComplete='off'
//                 ref={userRef}
//                 onChange={(e) => setUser(e.target.value)}
//                 value={user}
//                 // required
//                 />
//               </div>
//               <div className='pb-1'>
//                 <input 
//                 type='text' 
//                 placeholder='OTP' 
//                 className='log-input pt-1 ' 
//                 autoComplete='off'
//                 id='password'
//                 onChange={(e) => setPwd(e.target.value)}
//                 value={pwd}
//                 // required
//                 />
//               </div>
//               <div>
//                 <button className='button bg-purple white border-0'>
//                   Verify
//                 </button>
//                 {/* <Link to={'/home'}>
//                 <input className='button bg-purple white border-0' type='button' value='Sign in' placeholder={'password'} />
//                 </Link> */}
//               </div>
//               <div className='pt-1 text-center'>
//                   <p className='purple pt-1'>
//                 <Link to={'/forgot-password'}>
//                     forgot password?
//                 </Link>
//                   </p>
//               </div>
//             </form>
//           </div>


          
//         </div> 
//       </div>
//     </div>
  
    
//   )
// }

// export default OTP

import React, { useState, useRef } from 'react';

export default function OtpInput() {
  const [otp, setOtp] = useState(['', '', '', '']);
  const otpInputs = useRef([]);

  const handleChange = (index, event) => {
    const value = event.target.value;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    if (value && index < otpInputs.current.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      otpInputs.current[index - 1].focus();
    }
  };

  return (
    <div>
      {otp.map((value, index) => (
        <input
          key={index}
          type="text"
          value={value}
          maxLength={1}
          onChange={(event) => handleChange(index, event)}
          onKeyDown={(event) => handleKeyDown(index, event)}
          ref={(input) => {
            otpInputs.current[index] = input;
          }}
        />
      ))}
    </div>
  );
}

 
// import { Link } from 'react-router-dom'
import Logo from '../assets/logo-nav.svg'
import Google from '../assets/google-icon.png'

import User from '../assets/user.svg'
// import Bell from '../assets/bell.svg'
import Popup from 'reactjs-popup'

import Cookies from 'universal-cookie'
import { Link } from 'react-router-dom'
// import Notifications from './Notifications'
import axios from 'axios'
import { useGoogleLogin } from '@react-oauth/google'
import { useState, useEffect } from 'react'

 

const TopMenu = () => {

    const cookies = new Cookies();
    const token = cookies.get("TOKEN");
 
    const [user, setUser] = useState(false);

    useEffect(() => {
        
        try {     
            axios.get(
                "https://api.lanealitrade.com/user",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': '*/*',
                        Authorization: `Bearer ${token}`,
                    },
                }
            ).then((result) => {
            // // console.log('user result');
            // // console.log(result);
            // // console.log(result.data.oauthLogins);
            // // console.log(result.data.oauthLogins[0].provider);
            setUser(result.data)
            // // console.log("for parsing");
            // // console.log(user);
            // // console.log(user.oauthLogins[0].provider);
            }).catch((err) => {
                // console.log(err);
            })
        } catch (error) {
            // console.log(error);
        }
    },[])

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            // console.log(tokenResponse);
             
           try {
                
                  axios.post(
                    "https://api.lanealitrade.com/auth/google/connect",
                    {
                        accessToken: tokenResponse.access_token,
                    },
                    {
                    headers: {
                                'Content-Type': 'application/json',
                                'accept': '*/*',
                                Authorization: `Bearer ${token}`,
                            },
                    }
                  ).then((result) => 
                console.log(result)
                )
    
            // }
            // )
        //     .catch((error) => {
        //        // console.log(error)
        //    }) 
         } catch(err) {
            // console.log(err);
         }
        },
        onError: (e) => {
            // console.log('Login Failed');
            // console.log(e);
          },
      });
     
    const logout = () => {
        // destroy the cookie
        cookies.remove("TOKEN", { path: "/" });

        window.location.href = '/signin';
      }

       
  return (
        <div>
            <div>
            </div>
            <div className='bg-white'>       
                <div className=''>
                    <div className='top-0 right-0 left-0 fixed mb-flex lg-flex bg-white w-0 h-10 j-sb pl-1 pt-1 pr-2 '>
                        <Link to={'/'}>
                            <img src={Logo} alt='logo' className='logo-w' />
                        </Link>

                        {/* right end */}
                        <div className='lg-flex mb-flex align-center'>
                            {user  ? (
                                 <div className='lg-flex mb-flex pb-1 align-center pr-6'>
                                 {/* <img src={Google} alt='google logo' height={'30px'} className='pr-1' /> */}
                                 <p className='fw-700'>Google connected</p>
                             </div>
                            ) :
                            
                            <Link onClick={() => login()} className='link'>
                                <div className='lg-flex mb-flex pb-1 align-center pr-6'>
                                    <img src={Google} alt='google logo' height={'30px'} className='pr-1' />
                                    <p className='fw-700'>Link platform to google</p>
                                </div>
                            </Link>
                        }
                            {/* <input type='text' placeholder='Search' className='nav-search'/> */}
                            <div className='lg-flex mb-flex mb-hidden '>
                                {/* <div>
                                <Popup trigger={
                                   <div className='pl-1 pr-1 mb-flex lg-flex pointer'>
                                        <img src={Bell} alt='notification' />
                                        <p className='f-12 pl-2 pt-1 fw-700 absolute text-right'>3</p>
                                    </div>
                                }
                                nested
                                modal
                                >

                                    {
                                        close => (
                                            <div className=' h-50vh profile-modal top-0 bg-white'>
                                                <div className=' profile-modal-cont  '>
                                                <Notifications />
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                </Popup>
                                </div>  */}
                                <Popup trigger={
                                    <div className='profile  pointer'>
                                        <img src={User} alt='profile' />
                                    </div>
                                }
                                nested
                                modal
                                >

                                    {
                                        close => (
                                            <div className=' h-50vh profile-modal top-0 bg-white'>
                                                <div className=' profile-modal-cont  '>
                                                    <Link type='submit' onClick={() => logout()}>Logout</Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                </Popup>
                            </div>
                                <div className='profile lg-hidden'>
                                    <img src={User} alt='profile' />
                                </div>
                            <div>

                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
  )
}

export default TopMenu
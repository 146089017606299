import React, { useState } from 'react';

const Example = () => {
  const [usdtBalance, setUsdtBalance] = useState(10.94);
  const [leverage, setLeverage] = useState(20);
  const [numberOfContracts, setNumberOfContracts] = useState(0);
  const [orderDirection, setOrderDirection] = useState('long');
  const [marketPrice, setMarketPrice] = useState(238.61);

  const calculateInitialMargin = () => {
    return (usdtBalance * leverage) / marketPrice;
  };

  const calculateOpenLoss = () => {
    const directionMultiplier = orderDirection === 'long' ? 1 : -1;
    const orderPrice = marketPrice; // Assuming the order price is equal to the market price
    const openLoss = numberOfContracts * Math.abs(Math.min(0, directionMultiplier * (marketPrice - orderPrice)));
    return openLoss;
  };

  const calculateCost = () => {
    const initialMargin = calculateInitialMargin();
    const openLoss = calculateOpenLoss();
    const cost = initialMargin + openLoss;
    return cost.toFixed(2); // Rounding to two decimal places
  };

  const calculateMaxBNB = () => {
    const cost = parseFloat(calculateCost());
    const maxBNB = (usdtBalance - cost) / marketPrice;
    return maxBNB.toFixed(2); // Rounding to two decimal places
  };

  return (
    <div>
      <h2>Cost Calculation</h2>
      <div>
        <label>USDT Balance: </label>
        <input type="number" value={usdtBalance} onChange={(e) => setUsdtBalance(parseFloat(e.target.value))} />
      </div>
      <div>
        <label>Leverage: </label>
        <input type="number" value={leverage} onChange={(e) => setLeverage(parseInt(e.target.value))} />
      </div>
      <div>
        <label>Number of Contracts: </label>
        <input type="number" value={numberOfContracts} onChange={(e) => setNumberOfContracts(parseInt(e.target.value))} />
      </div>
      <div>
        <label>Order Direction: </label>
        <select value={orderDirection} onChange={(e) => setOrderDirection(e.target.value)}>
          <option value="long">Long</option>
          <option value="short">Short</option>
        </select>
      </div>
      <div>
        <label>Market Price: </label>
        <input type="number" value={marketPrice} onChange={(e) => setMarketPrice(parseFloat(e.target.value))} />
      </div>
      <div>
        <h3>Cost: {calculateCost()} USDT</h3>
        <h3>Max BNB Size: {calculateMaxBNB()} BNB</h3>
      </div>
    </div>
  );
};

export default Example;

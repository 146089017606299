import { useState } from 'react'
import TopMenu from '../../components/TopMenu'


import {  Link, useLocation, useNavigate } from 'react-router-dom';

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import Good from '../../assets/red-mark.svg'

import Cookies from 'universal-cookie'
import axios from 'axios'
import { useEffect } from 'react';
import Popup from 'reactjs-popup';
// import { client } from 'websocket';


import { w3cwebsocket as WebSocket, w3cwebsocket } from "websocket";
// import { io } from 'socket.io-client';
import { useRef } from 'react';
import OpenPositionAssetRealTime from '../../components/OpenPosition';
import CustomLoader from '../../components/CustomLoader';

// import Binance from 'node-binance-api';

// const binance = new Binance();


const cookies = new Cookies();
const token = cookies.get("TOKEN");

// const SINGLE_GOUP_URL = 'https://api.lanealitrade.com/common/trading-accounts/';
const TRADE_HISTORY = 'https://api.lanealitrade.com/common/trade-history?accountId=';
const TRADE_ORDERS = 'https://api.lanealitrade.com/common/trading-orders/'
const CANCEL_ORDER = 'https://api.lanealitrade.com/common/cancel-order/'
const TAKE_PROFIT = 'https://api.lanealitrade.com/common/take-profit'
const STOP_LOSS = 'https://api.lanealitrade.com/common/stop-loss'

const ARCHIVE_URL = 'https://api.lanealitrade.com/common/flag-account/';



// const API_URL = "https://api.binance.com/api/v3";


const ViewFuturesAccount = () => {

  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'
    },
    duration: 4000,
    dismissable: true,
  })
  const [successful, setSuccessful] = useState(false);
//   const [theAccountId, setTheAccountID] = useState('');

  const [history, getHistory ] = useState('');
  const [account, getAccount ] = useState('');

  const [theFilteredBalance, getFilteredBalance ] = useState('');

  const [sortedFilered, getStorts] = useState('');

  const [theMergedBalances, getMergedBalances ] = useState('');

  const [trades, getTrades ] = useState(''); 
  
  const [error, getError ] = useState('');

  const [market, setMarket ] = useState('')
  const [theAssetValues, setAssetValues ] = useState([])
  
  const [limitTab, setLimitTab] = useState('position');

  const location = useLocation();
  const toNavigate = useNavigate();



  const handleLimitClick = (tab) => {
    setLimitTab(tab);
    // console.log(tab, 'tab')
    // setOrderTab(tab)
    // // console.log(theOrderTab, 'the tab')
    // setLimit('');
    // setAmount('');
    // setStop('');
  };
//   // console.log(props, "this props yo");
//   // console.log(location, "this location set");
  
  const data = location.state;

  const goBack = () => {
    toNavigate(-1);
  }
  
  const tradingHistory = () => {
    axios.get( TRADE_HISTORY+data,
        // // console.log(TRADE_HISTORY+data),
        {
            headers: {
            'Content-Type': 'application/json',
              'accept': '*/*',
              Authorization: `Bearer ${token}`,
              }
        }
        ).then(
        (response) => {
            // // console.log(response);
            // // console.log(TRADE_HISTORY+data, );
            // // console.log('single history response.data');
            // // console.log(response.data);
            const theGroup = response.data;
            getHistory(theGroup);
        }
    ).catch((error) => {
        // console.log(error);
        // console.log(error.message);
        const theError = error.message;
        getError(theError);
    } )
}

const [tradePairs, setTradePairs ] = useState([])
const [tradeLoading, setTradeLoading ] = useState(false)
 
const tradeOrders = async () => {
  try {
    setTradeLoading(true)
    const response = await axios.get(TRADE_ORDERS + data, {
      headers: {
        'Content-Type': 'application/json',
        'accept': '*/*',
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log('single Trade order response.data');
    // console.log(response.data, 'the data');
    const theAccount = response.data.account;
    const theTrades = response.data.trades;

    const assetValues = theAccount.accountBalances.map(ass => ass.asset);
    const mike = theTrades.map(trade => trade.symbol.replace('/', '').toLowerCase());
    // const symbols = theTrades.filter(trade => trade.status === "FILLED").map(trade => trade.symbol.replace('/', ''));
    const filteredSortedAssets = theAccount.tradingAssets
      .filter(asset => asset.status === 'FILLED')
      .sort((a, b) => a.time - b.time);

    setTradePairs(mike);
    setAssetValues(assetValues);
    getAccount(theAccount);
    getStorts(filteredSortedAssets);

    const filteredBalances = theAccount.accountBalances?.filter(balance => parseFloat(balance.freeBalance) > 0.00000000);
    getFilteredBalance(filteredBalances);

    async function getConversionRate(asset) {
      try {
        const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=${asset}USDT`);
        const conversionRate = parseFloat(response.data.price);
        // // console.log(asset, 'what?');
        return conversionRate;
      } catch (error) {
        console.error(`Error retrieving conversion rate for ${asset}:`, error);
        return null;
      }
    }

    async function convertBalancesToUSDT(accountBalances) {
      for (const balance of accountBalances) {
        const asset = balance.asset;
        const freeBalance = parseFloat(balance.freeBalance);

        if (asset !== 'USDT' && freeBalance > 0.00000000) {
          const conversionRate = await getConversionRate(asset);
          if (conversionRate !== null) {
            const usdtBalance = freeBalance * conversionRate;
            balance.convertedBalance = usdtBalance.toFixed(8);
            balance.convertedAsset = 'USDT';
          }
        }
      }

      return accountBalances;
    }

    convertBalancesToUSDT(theAccount.accountBalances)
      .then(updatedBalances => {
        const filteredMass = updatedBalances?.filter(balance => parseFloat(balance.availableBalance) > 0.00000000)
        // // console.log(filteredMass, 'converts');
        getMergedBalances(filteredMass)
        setTradeLoading(false)
      })
      .catch(error => {
        console.error('Error converting balances to USDT:', error);
        setTradeLoading(false)
      });
      
      setTradeLoading(false)
    // Calculate change in price compared to market price
    const calculatePriceChange = async (symbol, tradePrice) => {
      try {
        const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`);
        const marketPrice = parseFloat(response.data.price);
        const priceChange = tradePrice - marketPrice;
        // // console.log(`Price change for ${symbol}: ${priceChange}`);
      } catch (error) {
        // console.error(`Error calculating price change for ${symbol}:`, error);
        setTradeLoading(false)
      }
    };

    // Iterate over trades and calculate price change for each trade
    for (const trade of theTrades) {
      const symbol = trade.symbol.replace('/', '');
      const tradePrice = parseFloat(trade.price);
      calculatePriceChange(symbol, tradePrice);
    }

    getTrades(theTrades);
    setLoading(false)
  } catch (error) {
    setLoading(false)
    // console.log(error);
    // console.log(error.message, 'na you?');
    const theError = error.message;
    getError(theError);
  }
};
 useEffect(() => {
    // Fetch trade data initially
    tradeOrders();
  
    // Set up an interval to fetch market data every 5 seconds (adjust the interval as needed)
    const interval = setInterval(tradeOrders, 10000);
  
    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);
  


// setInterval(tradeOrders, 5000); // Fetch trade orders every 5 seconds


// // Define a variable to hold the interval ID
// let intervalId;

// // Function to start fetching data at intervals
// const startDataFetch = () => {
//   // Fetch data immediately
//   tradeOrders();

//   // Set interval to fetch data every 5 seconds
//   intervalId = setInterval(tradeOrders, 60000);
// };

// // Function to stop fetching data at intervals
// const stopDataFetch = () => {
//   clearInterval(intervalId);
// };

// // Call the startDataFetch function to begin fetching data at intervals
// startDataFetch();

// // When you want to stop fetching data, call the stopDataFetch function
// // For example, you can call it in a cleanup function when the component unmounts:
// // useEffect(() => {
// //   return () => {
// //     stopDataFetch();
// //   };
// // }, []);



// useEffect(() => {

// }, [trades])

// Function to calculate the price change for a given symbol and trade price
// const calculatePriceChange = (symbol, tradePrice) => {
//   const marketPrice = market[symbol]?.lastPrice;
//   if (marketPrice) {
//     const priceChange = marketPrice - tradePrice;
//     // console.log(`Price change for ${symbol}: ${priceChange}`);
//     // You can perform further logic or store the price change value as needed
//   }
// };

useEffect(() => {

}, [trades])

useEffect(() => {

}, [sortedFilered])


// NORMAL AXIOS

useEffect(() => {
    const filteredSymbols = theAssetValues.filter(symbol => symbol !== "USDT");
    const marketData = {};
    // // console.log(filteredSymbols, 'where you');
    filteredSymbols.forEach((asset) => {
      const symbol = asset + 'USDT';
      axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=${symbol}`)
        .then((response) => {
          marketData[symbol] = response.data;
          setMarket(marketData);
        })
        .catch((error) => {
          // console.error(`Error fetching market data for ${symbol}:`, error);
        });
    });
  }, [theAssetValues]);
  

  
//   const [marketData, setMarketData] = useState({});

//   useEffect(() => {
//     // const filteredSymbols = tradePairs.filter(symbol => symbol !== "USDT");
//     const tradingPairs = tradePairs.map(symbol => symbol.toLowerCase());
//     // console.log(tradingPairs, 'tpaitres');
//     const client = new WebSocket(
//       `wss://stream.binance.com:9443/ws/${tradingPairs.join('@ticker/mini/')}@ticker/mini`
//     );
  
//     client.onopen = () => {
//       // console.log('WebSocket Client Connected');
//     };
  
//     client.onmessage = (message) => {
//       const data = JSON.parse(message.data);
//       setMarketData((prevData) => ({
//         ...prevData,
//         [data.s]: data.c,
//       }));
//       // console.log(marketData, 'market');
//     };
  
//     client.onclose = () => {
//       // console.log('WebSocket Client Disconnected');
//     };
  
//     return () => {
//       client.close();
//     };
//   }, [tradePairs]);

// WEBSOCKET 

// useEffect(() => {
//     // const socket = io('https://stream.binance.com:9443');
//     const socket = io('https://stream.binance.com:9443', { transports: ['websocket'], upgrade: false, path: "/ws" });

//     const filteredSymbols = theAssetValues.filter(symbol => symbol !== "USDT");

//     socket.on('connect', () => {
//       // console.log('Connected to Binance WebSocket');
//       filteredSymbols.forEach((asset) => {
//         const symbol = asset + 'USDT';
//         socket.emit('subscribe', `${symbol.toLowerCase()}@ticker`);
//       });
//     });

//     socket.on('ticker', (data) => {
//       const symbol = data.s;
//       setMarket(prevMarket => ({ ...prevMarket, [symbol]: data }));
//     });

//     socket.on('disconnect', () => {
//       // console.log('Disconnected from Binance WebSocket');
//     });

//     return () => {
//       socket.close();
//     };
//   }, [theAssetValues]);



  useEffect(() => {
    //   getGroupMembers();
      tradingHistory();
      tradeOrders();
      // startTrade();
  },[])
  useEffect(() => {
    // // console.log(lastPrice, 'updated lastPrice');
  }, [market]);
  

   // ARCHIVE ACCOUNT

   const [theAccountId, setTheAccountID] = useState('');

   const handleArchive = async (e) => {
     e.preventDefault();
     // console.log(e.target.value, );
    setTheAccountID(e.target.value); 
    // console.log(ARCHIVE_URL+data);
 
     const tradingConfig = {
       method: "get",
       url: ARCHIVE_URL+data,
       // data: {
       // },
        headers: {
           'Content-Type': 'application/json',
              'accept': '*/*',
               Authorization: `Bearer ${token}`,
           },
           // withCredentials: true
     };
 
     try {
       await axios(tradingConfig).then((result) => {
         // console.log(result);
         // const decoded = jwtDecode(result.data.token);
         // console.log(result.data.token);
         // console.log(result);
         
         setSuccessful(true);
         alert("Account archived successfully");
         window.location.href = "/";
       }).catch((error )=> {
         // console.log("this error");
         // console.log(error);
         // console.log(error.response.data.message);  
         
       }
       )
 
     } catch (err) {
 
       // this part might just be redundant but leave it here
        
       // errRef.current.focus();
     }
   
   }

   // MAKE PAIR LOWER CASE
  //  function toLowerCase(currencyPair) {
  //   if (typeof currencyPair !== 'string') {
  //     throw new Error('The argument must be a string');
  //   }
  
  //   if (currencyPair.toLowerCase() === currencyPair) {
  //     return currencyPair;
  //   }
  
  //   return currencyPair.toLowerCase();
  // }

//   const [marketData, setMarketData ] = useState(null)

//   useEffect(() => {
//     const socketIntervals = [];
  
//     theAssetValues.forEach((asset) => {
//       const lowAsset = toLowerCase(asset)+'usdt';
//       const socket = new WebSocket(
//         `wss://stream.binance.com:9443/ws/${lowAsset}@ticker`
//       );
  
//       socket.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         setMarketData(data);
//       };
  
//       const interval = setInterval(() => {
//         if (socket.readyState === WebSocket.OPEN) {
//           socket.send(JSON.stringify({ type: 'ping' }));
//         }
//       }, 90000);
  
//       socketIntervals.push({ socket, interval });
//     });
  
//     return () => {
//       socketIntervals.forEach(({ socket, interval }) => {
//         clearInterval(interval);
//         socket.close();
//       });
//     };
//   }, [theAssetValues]);
  
 
const [marketData, setMarketData] = useState({});
const [tradingAssets, setTradingAssets] = useState([]);

const [tradingAssetLoaded, setTradingAssetLoaded] = useState(false);

// const updateChange = (marketData) => {
//   const updatedAssets = tradingAssets.map(( asset ) => {
//     const symbolKey = asset.symbol.replace("/", "").toLowerCase();
//     const currentPrice = symbolKey in marketData ? parseFloat(marketData[symbolKey]) : null;
//     if (currentPrice) {
//       const orderPrice = parseFloat(asset.price);
//       const orderQty = parseFloat(asset.origQty); // Get the order quantity
//       // let change = (currentPrice - orderPrice) * orderQty; // Calculate change based on the quantity
//       return {
        
//           ...asset,
//           // change,
        
         
//       };
//     } else {
//       return {  asset };
//     }
//   });

//   setTradingAssets(updatedAssets);
// };

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.lanealitrade.com/common/trading-orders/'+location.state);
      // // console.log('https://api.lanealitrade.com/common/trading-orders/'+location.state, 'fetching data');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // console.log('data is available');
      // console.log(data, 'make i see u');
      setTradingAssets(data?.trades || []);
      setTradingAssetLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log("useEffect for fetchData called");
    fetchData();
  }, []);

  // let flag = false;

  useEffect(() => {
    // console.log('useEffect for SocketCalled');
    if (tradingAssets.length === 0) {
      return;
    }

    const client = new w3cwebsocket(
      `wss://stream.binance.com:9443/ws/${tradePairs.join('@ticker/mini/')}@ticker/mini`
    );

    client.onopen = () => {
      // console.log('WebSocket Client Connected');
    };

    client.onmessage = (message) => {
      const data = JSON.parse(message.data);
      setMarketData((prevData) => {
        const updatedData = {
          ...prevData,
          [data.s.toLowerCase()]: data.c,
        };
        // updateChange(updatedData);
        return updatedData;
      });
    };

    client.onclose = () => {
      // console.log('WebSocket Client Disconnected');
    };

    return () => {
      client.close();
    };
  }, [tradingAssetLoaded]);


   

  // take PROFIT
 

  // const [err, setErr] = useState(false);

  const [loading, setLoading ] = useState(false)

  const [stopLoading, setStopLoading ] = useState(false)

  const [cancelLoading, setCancelLoading ] = useState(false)

  const [tp_Price, setTpPrice ] = useState(false)

  // const inputRef = useRef(null);
  

  const takeProfit = async (e, orderId, price, tpPrice) => {
  e.preventDefault();
  // console.log(e.currentTarget.id, );
//   // console.log(e.currentTarget.price, );
 // console.log(tp_Price);
 // console.log(price);
  // console.log(orderId,  'is it?');

  // const getAccountID = theAccountId;
  const tradingConfig = {
    method: "post",
    url: TAKE_PROFIT,
    data: {
      'tradingAssetId': orderId,
      'price': price,
      'stopPrice': tp_Price,
    },
     headers: {
        'Content-Type': 'application/json',
           'accept': '*/*',
            Authorization: `Bearer ${token}`,
        },
        // withCredentials: true
  };

  try {
    setLoading(true)
    await axios(tradingConfig).then((result) => {
      // console.log(result);
      // const decoded = jwtDecode(result.data.token);
      // console.log(result.data.token);
      // console.log(result);
      //     setSuccessful(true);

      // const theError = result.data
      
      window.location.reload();
       
      setLoading(false);
      notyf.success("successful")
      // alert("yes");
    }).catch((error )=> {
      setLoading(false);
      notyf.error(error.response.data)
      // console.log("this error");
      // console.log(error);
      // console.log(error.message);  
      // // console.log(users.id);
    }
    )
  } catch (err) {
    setLoading(false);
    // this part might just be redundant but leave it here
    // errRef.current.focus();
  }
}

  const stopLoss = async (e, orderId, price, tpPrice) => {
  e.preventDefault();
  // console.log(e.currentTarget.id, );
//   // console.log(e.currentTarget.price, );
 // console.log(tp_Price);
 // console.log(price);
  // console.log(orderId,  'is it?');

  // const getAccountID = theAccountId;
  const tradingConfig = {
    method: "post",
    url: STOP_LOSS,
    data: {
      'tradingAssetId': orderId,
      'price': price,
      'stopPrice': tp_Price,
    },
     headers: {
        'Content-Type': 'application/json',
           'accept': '*/*',
            Authorization: `Bearer ${token}`,
        },
        // withCredentials: true
  };

  try {
    setStopLoading(true)
    await axios(tradingConfig).then((result) => {
      // console.log(result);
      // const decoded = jwtDecode(result.data.token);
      // console.log(result.data.token);
      // console.log(result);

    window.location.reload();
    
      setStopLoading(false);
      notyf.success("successful")

      // alert("yes");
    }).catch((error )=> {
      setStopLoading(false);
      notyf.error("failed")
      // console.log("this error");
      // console.log(error);
      // console.log(error.message);  
      // // console.log(users.id);
    }
    )
  } catch (err) {
    setStopLoading(false);
    // this part might just be redundant but leave it here
    // errRef.current.focus();
  }
}


// CANCEL TRADE

  const handleCancel = async (e, orderId, asset) => {
  e.preventDefault();
  // setCancelLoading((prevCancelLoading) => ({
  //   ...prevCancelLoading,
  //   [asset]: true,
  // }));
  // console.log(e.currentTarget.id, );
//   // console.log(e.currentTarget.price, );
//  // console.log(tp_Price);
//  // console.log(price);
  // console.log(orderId,  'is it?');

  // const getAccountID = theAccountId;
  const tradingConfig = {
    method: "post",
    url: CANCEL_ORDER+orderId,
    
     headers: {
        'Content-Type': 'application/json',
           'accept': '*/*',
           Authorization: `Bearer ${token}`,
        }, 
      };
      // console.log(CANCEL_ORDER+orderId)

  try {
    setCancelLoading(true)
    await axios(tradingConfig).then((result) => {
      // console.log(result); 
      // console.log(result);
      
      if (result.status !== 200 || result.status !== 201) {
        notyf.success("successful")
        // console.log('first')
        // window.location.reload();
      }
      
      setCancelLoading(false);
      // alert("yes");
    }).catch((error )=> {
      notyf.error("failed")
      setCancelLoading(false);
      // console.log("this error");
      // console.log(error.response.data); 
    }
    )
  } catch (err) {
    // this part might just be redundant but leave it here
    // errRef.current.focus();
  } 
  // finally {
  //   setCancelLoading(false);
  //   // setCancelLoading((prevCancelLoading) => ({
  //   //   ...prevCancelLoading,
  //   //   [asset]: false,
  //   // }));
  // }
}

 


 
  return (
    <div>
        <div>
            <TopMenu />
        </div>
        <div className=' pt-6 ml-6 mr-4  h- '>
            <div className=' lg-flex pt-1 j-sb pb-1'>
                <h1 className=''>{account.name}</h1>
                
                <div className='lg-flex align-center pt-1'>
                  <div>
                    <Popup trigger={<button  className=" popup-btn bg-red white mr-1 h-100 fw-100 pointer" >
                              Archive
                          </button>}
                            modal
                            nested
                        >
                            {
                                close => (
                                    <div className="modal lg-flex mb-flex j-center align-center">
                                        <div className=" lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-50 w-50 bg-white">
                                            <div className=" pl-2 pr-2 text-center">
                                                <div className="">
                                                    <img src={Good} alt="account creation successful"/>
                                                </div>
                                                <div className="pt-4">
                                                    <h1 className="f-14 fw-700 pr-1 ">Are you sure you want to archive {account.name}? </h1>
                                                </div>
                                                <div className="pt-4">
                                                <div className="lg-flex mb-flex j-center">
                                                
                                                    <Link to={'/home'}>
                                                        <button onClick={handleArchive} className=" popup-btn bg-purple white mr-1 h-100 fw-100"   ><p className=" f-12 fw-100">Archive</p></button>
                                                    </Link>
                                                      
                                                    <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={close}><p className="f-12">Cancel</p></button>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                    </Popup>
                  </div>
                    <div>
                      <button  className=" popup-btn bg-purple white mr-1 h-100 fw-100 pointer"  onClick={goBack} >
                          Back
                      </button>
                    </div>
                    <div>
                      <Link to={"/trade-futures"} className='popup-btn   text-none  bg-purple white mr-1 h-100 fw-100 pointer' state={account.id} id={account.id} >
                          Goto Trade
                      </Link>
                    </div>
                </div>
            </div> 
            <div className=' '>

            <div className='lg-flex pt-1 j-sb w-20'>
                  <p className={limitTab === "position" ? "active  pointer red f-12 mr-1 fw-400  pt-1 pb-1" : " pointer  mr-1 fw-400 f-12 pt-1 pb-1"} onClick={() => handleLimitClick("position")}>Open Position</p>
                  <p className={limitTab === "order" ? "active pointer red f-12 mr-1 fw-400  pt-1 pb-1" : " pointer mr-1 fw-400 f-12 pt-1 pb-1"} onClick={() => handleLimitClick("order")}>Open Order</p>
                  {/* <p className={limitTab === "STOP" ? "active  pointer red  mr-1 fw-400  pt-1 pb-1" : " pointer  mr-1 fw-400  pt-1 pb-1"} onClick={() => handleLimitClick("STOP")}>STOP_LIMIT</p> */}
                
                </div>
                       
                
                <div className='h-50 w-100 lg-flex '>
                  {limitTab === 'position' && (
                    <div className=' w-100 border-1 border-radius-5 mb-2 mr-1'>
                        <div class=" fw-600 pt-1 pb-1 pl-1 pr-1 history-color">
                            <div class="f-12">Open Position</div> 
                        </div>
                        <OpenPositionAssetRealTime accountId={location.state} />
                        
                    </div>
                  )}

                  {/* order */}
                  {limitTab === 'order' && (
                    <div className=' w-100 border-1 border-radius-5 mb-2 mr-1'>
                        <div class=" fw-600 pt-1 pb-1 pl-1 pr-1 history-color">
                            <div class="f-12">Open Orders</div> 
                        </div>
                        <div class="table w 0">
                        
                            {/* <div class="row"> */}
                                <div class="row f-12 fw-700">
                                    <div class="cell">Symbol</div>
                                    {/* <div class="cell">Order ID</div> */}
                                    <div class="cell">Price</div>
                                    <div class="cell">Stop Price</div>
                                    {/* <div class="cell">Stop Price</div> */}
                                    {/* <div class="cell">Trade Type</div> */}
                                    {/* <div class="cell">Quantity</div> */}
                                    <div class="cell">Status</div>
                                    <div class="cell">Type</div>
                                    <div class="cell">Trade Type</div>
                                    {/* <div class="cell">Change</div> */}
                                    <div class="cell">TP/SL</div>
                                </div>
                            {/* </div> */}
                            {/* <div class="row"> */}
                                {/* {account.tradingAssets.filter(singleTrade => singleTrade.status !== "FILLED")
                                // .sort((a, b) => b.time - a.time)
                                .map((item , index) => {
                                  // // console.log(tradingAssets, ' this herer')
                                  return (
                                    <div className='row' key={index}>
                                        <div class="cell">{item.symbol}</div>
                                        <div class="cell">{item.orderId}</div>
                                        <div class="cell">{item.price}</div>
                                        <div class="cell">{item.stopPrice}</div>
                                        <div class="cell">{item.tradeType}</div>
                                        <div class="cell">{item.origQty}</div>
                                        <div class="cell">{item.status}</div>
                                        <div class="cell">{item.type}</div>
                                        <div class="cell">{item.change.toFixed(2)}</div>
                                        <div onClick={cancelOrder} id={item.id}> x </div>
                                    </div>)}
                                )} */}
                                {account.tradingAssets?.length > 0 ? (
                                  account.tradingAssets
                                    .filter(singleTrade => singleTrade.status === "NEW")
                                    .map((item, index) => {
                                      // const theSymbol = item.symbol.replace('/', '');
                                      // const priceChange = parseFloat(item.price) - parseFloat(market[theSymbol]?.lastPrice); // Calculate price change
                                      // // console.log('Item Price:', theSymbol);
                                      // // console.log('Last Price:', market[theSymbol]?.lastPrice);
                                      // // console.log('Last market:', market);
                                      const { id, 
                                        // price, 
                                        // tpPrice, 
                                        symbol, 
                                        // tradeType, 
                                        // placedTakeProfit,
                                        //  placedStopLoss
                                         } = item;
                                      const asset = `${symbol}_${id}`; // Create a unique key for each trading asset
                                      // // console.log(asset, 'asset')
                                      return (
                                        <form className='row f-12'>
                                          <div class="cell"><p className='fw-400 f-12'>{item.symbol}</p> </div>
                                          {/* <div value={item.orderId} class="cell"><p className='fw-400'>{item.orderId}</p> </div> */}
                                          <div class="cell"><p className='fw-400 f-12'>{item.price}</p> </div>
                                          <div class="cell"><p className='fw-400 f-12'> {item.stopPrice}</p></div>
                                          <div class="cell"><p className='fw-400 f-12'>{item.status}</p> </div>
                                          <div class="cell"><p className='fw-400 f-12'>{item.type}</p> </div>
                                          <div class="cell"><p className='fw-400 f-12'> {item.tradeType}</p></div>
                                          {/* <div className="cell"><p className='fw-400 f-12'>{priceChange.toFixed(2)}</p> </div> */}
                                          {/* <div class="cell">{item.change.toFixed(2)}</div> */}
                                          {/* {item.placedTakeProfit === true || item.placedStopLoss === true ? ( */}
                                            {/* <div class="cell">
                                              {cancelLoading ? (
                                                 
                                                <CustomLoader />
                                              ) : (
                                                <button
                                                type='submit'
                                                onClick={(e) => handleCancel(e, item.id, asset)}
                                                className='button f-12 bg-red white border-radius-10 border-0 pl-1 pr-1'
                                              >
                                                cancel
                                              </button>
                                              )  }
                                            </div> */}
                                          {/* ) : item.tradeType === 'TAKE_PROFIT' || item.tradeType === 'STOP_LOSS' ? ( */}
                                            {/* <>
                                              <div class="cell">
                                              {cancelLoading ? (
                                                
                                                <CustomLoader />
                                              ) : (
                                                <button
                                                type='submit'
                                                onClick={(e) => handleCancel(e, item.id, item.price, item.tpPrice)}
                                                className='button f-12 bg-red white border-radius-10 border-0 pl-1 pr-1'
                                              >
                                                cancel
                                              </button>
                                              )  }
                                            </div>
                                            </> */}
                                          {/* ) : ( */}
                                            <div class="cell f-12">
                                              <input
                                                value={item.tpPrice}
                                                onChange={(event) => setTpPrice(event.target.value)}
                                                type='text'
                                                required
                                              />
                                            </div>
                                          {/* )} */}
                                          {/* {item.placedTakeProfit === true || item.placedStopLoss === true ? ( */}
                                          <>
                                          
                                          </>
                                          {/* ) : item.tradeType === 'TAKE_PROFIT' || item.tradeType === 'STOP_LOSS' ? ( */}
                                            <></>
                                          {/* ) : ( */}
                                            <>
                                            {loading ?  
                                                <CustomLoader />
                                                 :
                                            <div class="cell f-12">
                                            <button
                                              type='submit'
                                              onClick={(e) => takeProfit(e, item.id, item.price, item.tpPrice)}
                                              className='button bg-purple white border-radius-10 border-0 pl-1 pr-1'
                                              // disabled={item.placedTakeProfit || item.placedStopLoss} // Disable the button if TP or SL is already placed
                                              >
                                              tp
                                            </button>
                                          </div>
                                          }
                                              {stopLoading ? 
                                              <CustomLoader /> 
                                                  :
                                          <div class="cell f-12">
                                            <button
                                              type='submit'
                                              onClick={(e) => stopLoss(e, item.id, item.price, item.tpPrice)}
                                              className='button bg-red white border-radius-10 border-0 f-12 pl-1 pr-1'
                                              disabled={item.placedTakeProfit || item.placedStopLoss} // Disable the button if TP or SL is already placed
                                            >
                                              sl
                                            </button>
                                          </div>
                                          }
                                          {cancelLoading ? (
                                            <CustomLoader /> 
                                              ) : (
                                                <button
                                                type='submit'
                                                onClick={(e) => handleCancel(e, item.id, item.price, item.tpPrice)}
                                                className='button bg-red white border-radius-10 f-12 border-0 pl-1 pr-1'
                                              >
                                                cancel
                                              </button>
                                              )  }
                                            </>
                                          {/* )
                                        } */}
                                        </form>
                                      );
                                    })
                                  ) : loading ? (
                                    <><CustomLoader /></>
                                  ) : (
                                    <>
                                      {/* nada */}
                                    </>
                                  )}

                             
                                                    
                        </div>
                    </div>
                  )}
                    

                    {/* BALANCE */}
                    <div className='w-30 border-1 border-radius-5 mb-2'>
                        <div class=" fw-600 pt-1 pb-1 pl-1 pr-1 history-color">
                            <div class="f-12">BALANCE</div>
                            
                        </div>
                        <div class="ta ble w-100">
                                <div class="row fw-600 ">
                                    {/* <div class="cell">SN</div> */}
                                    <div class="cell f-12">Currency</div>
                                    <div class="cell f-12">Balance</div>
                                    
                                </div>
                                {theMergedBalances?.length > 0 ? (
                                  // // console.log( "free balances", account.accountBalances.filter(freeBalances => freeBalances < 0.00)),
                                  
                                  // // console.log(theMergedBalances, 'merger'),
                                  theMergedBalances.map((groupOne, index) => {
                                    return (
                                        <div class="row f-12">
                                            <div class="cell">{groupOne.asset}</div>
                                            <div class="cell">
                                              <div>
                                              {groupOne.availableBalance}
                                              </div>
                                              {groupOne.convertedBalance}
                                              </div>
                                        </div>                     
                                    )
                                })) : (
                                    <div></div>
                                )}
                                {/* {theFilteredBalance?.length > 0 ? (
                                  // // console.log( "free balances", account.accountBalances.filter(freeBalances => freeBalances < 0.00)),
                                  
                                  theFilteredBalance.map((groupOne, index) => {
                                    return (
                                        <div class="row f-14">
                                            <div class="cell">{groupOne.asset}</div>
                                            <div class="cell">{groupOne.freeBalance}</div>
                                        </div>                     
                                    )
                                })) : (
                                    <div></div>
                                )} */}
                                {/* {account.accountBalances?.length > 0 ? (
                                  // // console.log( "free balances", account.accountBalances.filter(freeBalances => freeBalances < 0.00)),
                                  
                                  account.accountBalances.map((groupOne, index) => {
                                    return (
                                        <div class="row f-14">
                                            <div class="cell">{groupOne.asset}</div>
                                            <div class="cell">{groupOne.freeBalance}</div>
                                        </div>                     
                                    )
                                })) : (
                                    <div></div>
                                )} */}
                                {/* {account.accountBalances.filter(freeBalances => freeBalances !== "0.00") */}
                                {/* // // console.log(object); */}
                                {/* } */}
                                                    
                        </div>
                    </div>
                </div>
            </div>
            <div>
              <div className='w-100 border-1 border-radius-5 mb-2'>
                <div className="fw-600 f-12 pt-1 pb-1 pl-1 pr-1 history-color">
                  <div>TRADING HISTORY</div>
                </div>
                <div className="table w-100">
                  <div className="row fw-700">
                    <div className="cell f-12">Symbol</div>
                    <div className="cell f-12">Order ID</div>
                    <div className="cell f-12">Price</div>
                    <div className="cell f-12">Stop Price</div>
                    <div className="cell f-12">Quantity</div>
                    <div className="cell f-12">Status</div>
                    <div className="cell f-12">Type</div>
                    <div className="cell f-12">Trade Type</div>
                    {/* <div className="cell">Change</div> */}
                     {/* Added new column */}
                  </div>
                  {account.tradingAssets?.length > 0 ? (
                    account.tradingAssets
                      .filter(singleTrade => singleTrade.status !== "NEW")
                      .map((item, index) => {
                        // const symbol = item.symbol.replace('/', '');
                        // const priceChange = item.price - market[symbol]?.lastPrice; // Calculate price change
                        // // console.log('Item Price:', symbol);
                        // // console.log('Last Price:', market[symbol]?.lastPrice);
                        // // console.log('Last market:', market);

                        return (
                          <div className="row" key={index}>
                            <div className="cell fw-400 f-12">{item.symbol}</div>
                            <div className="cell fw-400 f-12">{item.orderId}</div>
                            <div className="cell fw-400 f-12">{item.price}</div>
                            <div className="cell fw-400 f-12">{item.stopPrice}</div>
                            <div className="cell fw-400 f-12">{item.origQty}</div>
                            <div className="cell fw-400 f-12">{item.status}</div>
                            <div className="cell fw-400 f-12">{item.type}</div>
                            <div className="cell fw-400 f-12">{item.tradeType}</div>
                            {/* <div className="cell">{priceChange.toFixed(2)}</div>  */}
                            {/* Display price change */}
                          </div>
                        );
                      })
                  ) : tradeLoading ? (
                    <>
                      <CustomLoader />
                    </>
                  ) : (
                    <>
                      No active trades
                    </>
                  )}
                </div>
              </div> 
            </div>
        </div>
    </div>
  )
}

export default ViewFuturesAccount
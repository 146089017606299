// import { Route, Navigate } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";

const ProtectedRoutes = () => {
    const cookies = new Cookies();
    const token = cookies.get("TOKEN");

    // let auth = {'token':false}
return (
    // auth.token
    token ? <Outlet/> : <Navigate to='/welcome'/>
  )
    }


export default ProtectedRoutes

import { useState } from 'react'
import TopMenu from '../../components/TopMenu'


import {  Link, useLocation, useNavigate } from 'react-router-dom';


import Good from '../../assets/red-mark.svg'

import Cookies from 'universal-cookie'
import axios from 'axios'
import { useEffect } from 'react';
import Popup from 'reactjs-popup';
// import { client } from 'websocket';


import { w3cwebsocket as WebSocket, w3cwebsocket } from "websocket";
// import { io } from 'socket.io-client';

// import Binance from 'node-binance-api';

// const binance = new Binance();


const cookies = new Cookies();
const token = cookies.get("TOKEN");

// const SINGLE_GOUP_URL = 'https://api.lanealitrade.com/common/trading-accounts/';
const TRADE_HISTORY = 'https://api.lanealitrade.com/common/trade-history?accountId=';
const TRADE_ORDERS = 'https://api.lanealitrade.com/common/trading-orders/'
const CANCEL_ORDER = 'https://api.lanealitrade.com/common/cancel-order/'

const ARCHIVE_URL = 'https://api.lanealitrade.com/common/flag-account/';



const API_URL = "https://api.binance.com/api/v3";


const ViewAccount = (props) => {

    
    
  const [successful, setSuccessful] = useState(false);
//   const [theAccountId, setTheAccountID] = useState('');

  const [history, getHistory ] = useState('');
  const [account, getAccount ] = useState('');

  const [theFilteredBalance, getFilteredBalance ] = useState('');

  const [sortedFilered, getStorts] = useState('');

  const [theMergedBalances, getMergedBalances ] = useState('');

  const [trades, getTrades ] = useState(''); 
  
  const [error, getError ] = useState('');

  const [market, setMarket ] = useState('')
  const [theAssetValues, setAssetValues ] = useState([])

  const location = useLocation();
  const toNavigate = useNavigate();



//   // console.log(props, "this props yo");
//   // console.log(location, "this location set");
  
  const data = location.state;

  const goBack = () => {
    toNavigate(-1);
  }

    // // console.log(SINGLE_Group_URL+data, "it is?");
//   const getGroupMembers = () => {
//     axios.get( SINGLE_GOUP_URL+data,
//         // console.log(SINGLE_GOUP_URL+data),
//         {
//             headers: {
//             'Content-Type': 'application/json',
//               'accept': '*/*',
//               Authorization: `Bearer ${token}`,
//               }
//         }
//         ).then(
//         (response) => {
//             // // console.log(response);
//             // // console.log('single user response.data');
//             // // console.log(response.data.result);
//             const theGroup = response.data.result;
//             getGroup(theGroup);
//         }
//     ).catch((error) => {
//         // console.log(error);
//         // console.log(error.message);
//         const theError = error.message;
//         getError(theError);
//     } )
// }
  const tradingHistory = () => {
    axios.get( TRADE_HISTORY+data,
        // console.log(TRADE_HISTORY+data),
        {
            headers: {
            'Content-Type': 'application/json',
              'accept': '*/*',
              Authorization: `Bearer ${token}`,
              }
        }
        ).then(
        (response) => {
            // // console.log(response);
            // // console.log(TRADE_HISTORY+data, );
            // // console.log('single history response.data');
            // // console.log(response.data);
            const theGroup = response.data;
            getHistory(theGroup);
        }
    ).catch((error) => {
        // console.log(error);
        // console.log(error.message);
        const theError = error.message;
        getError(theError);
    } )
}

const [tradePairs, setTradePairs ] = useState([])
 
const tradeOrders = () => {
    axios.get( TRADE_ORDERS+data,
        // console.log(TRADE_ORDERS+data),
        {
            headers: {
            'Content-Type': 'application/json',
              'accept': '*/*',
              Authorization: `Bearer ${token}`,
              }
        }
        ).then(
        (response) => {
            // // console.log(response);
            // // console.log(TRADE_ORDERS+data, );
            // console.log('single TRade order response.data');
            // // console.log(response.data.account.accountBalances);
            // console.log(response.data, 'th data');
            const theAccount = response.data.account;
            const theTrades = response.data.trades;
            // console.log(theAccount, "the accounts");
            // console.log(theTrades, "the trades");
            const assetValues = theAccount.accountBalances.map(ass => ass.asset); 
            // // console.log(assetValues, 'sets b')
 
            const mike = theTrades.map(trade => trade.symbol.replace('/', '').toLowerCase());
            // .filter(trade => trade.item.status === 'FILLED');
            // console.log(mike,'mike ????????????????????????????????????');

            const symbols = theTrades.filter(trade => trade.status === "FILLED").map(trade => trade.symbol.replace('/',''));
            // // console.log(symbols, 'shanks');
            const filteredSortedAssets = theAccount.tradingAssets
  .filter(asset => asset.status === 'FILLED')
  .sort((a, b) => a.time - b.time);

  // console.log(filteredSortedAssets, 'storts')
             
            setTradePairs(mike)
      
            setAssetValues(assetValues)
  
            getAccount(theAccount);
            getStorts(filteredSortedAssets);

            
            const filteredBalances =  theAccount.accountBalances?.filter(balance => parseFloat(balance.freeBalance) > 0.00000000)
            // // console.log(filteredBalances, 'not zero?');
            
            getFilteredBalance(filteredBalances);

 
          // Helper function to retrieve the conversion rate for an asset to USDT
async function getConversionRate(asset) {
  try {
    const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=${asset}USDT`);
    const conversionRate = parseFloat(response.data.price);
    // console.log(asset, 'what?');
    return conversionRate;
  } catch (error) {
    console.error(`Error retrieving conversion rate for ${asset}:`, error);
    return null;
  }
}

// Convert all asset freeBalances to USDT
async function convertBalancesToUSDT(accountBalances) {
  for (const balance of accountBalances) {
    const asset = balance.asset;
    // // console.log(asset, 'again what?');
    const freeBalance = parseFloat(balance.freeBalance);
    
    if (asset !== 'USDT' && freeBalance > 0.00000000) {
      const conversionRate = await getConversionRate(asset);
      if (conversionRate !== null) {
        const usdtBalance = freeBalance * conversionRate;
        balance.convertedBalance = usdtBalance.toFixed(8);
        balance.convertedAsset = 'USDT';
      }
    }
  }
  
  return accountBalances;
}

// Usage example
convertBalancesToUSDT(theAccount.accountBalances)
  .then(updatedBalances => {
    const filteredMass = updatedBalances?.filter(balance => parseFloat(balance.freeBalance) > 0.00000000)
    // console.log(filteredMass, 'converts');
    getMergedBalances(filteredMass)
  })
  .catch(error => {
    console.error('Error converting balances to USDT:', error);
  });



              

              // const filteredStatus = theTrades?.filter(item => item.status !== 'FILLED' )
              // const notFilteredStatus = theTrades?.filter(client => client.status === 'FILLED' )
              
              getTrades(theTrades); 
          }
      ).catch((error) => {
          // console.log(error);
          // console.log(error.message, 'na you?');
          const theError = error.message;
          getError(theError);
      } )
} 

useEffect(() => {

}, [trades])

useEffect(() => {

}, [sortedFilered])


// NORMAL AXIOS

// useEffect(() => {
//     const filteredSymbols = theAssetValues.filter(symbol => symbol !== "USDT");
//     const marketData = {};
//     // console.log(filteredSymbols, 'where you');
//     filteredSymbols.forEach((asset) => {
//       const symbol = asset + 'USDT';
//       axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=${symbol}`)
//         .then((response) => {
//           marketData[symbol] = response.data;
//           setMarket(marketData);
//         })
//         .catch((error) => {
//           console.error(`Error fetching market data for ${symbol}:`, error);
//         });
//     });
//   }, [theAssetValues]);
  

  
//   const [marketData, setMarketData] = useState({});

//   useEffect(() => {
//     // const filteredSymbols = tradePairs.filter(symbol => symbol !== "USDT");
//     const tradingPairs = tradePairs.map(symbol => symbol.toLowerCase());
//     // console.log(tradingPairs, 'tpaitres');
//     const client = new WebSocket(
//       `wss://stream.binance.com:9443/ws/${tradingPairs.join('@ticker/mini/')}@ticker/mini`
//     );
  
//     client.onopen = () => {
//       // console.log('WebSocket Client Connected');
//     };
  
//     client.onmessage = (message) => {
//       const data = JSON.parse(message.data);
//       setMarketData((prevData) => ({
//         ...prevData,
//         [data.s]: data.c,
//       }));
//       // console.log(marketData, 'market');
//     };
  
//     client.onclose = () => {
//       // console.log('WebSocket Client Disconnected');
//     };
  
//     return () => {
//       client.close();
//     };
//   }, [tradePairs]);

// WEBSOCKET 

// useEffect(() => {
//     // const socket = io('https://stream.binance.com:9443');
//     const socket = io('https://stream.binance.com:9443', { transports: ['websocket'], upgrade: false, path: "/ws" });

//     const filteredSymbols = theAssetValues.filter(symbol => symbol !== "USDT");

//     socket.on('connect', () => {
//       // console.log('Connected to Binance WebSocket');
//       filteredSymbols.forEach((asset) => {
//         const symbol = asset + 'USDT';
//         socket.emit('subscribe', `${symbol.toLowerCase()}@ticker`);
//       });
//     });

//     socket.on('ticker', (data) => {
//       const symbol = data.s;
//       setMarket(prevMarket => ({ ...prevMarket, [symbol]: data }));
//     });

//     socket.on('disconnect', () => {
//       // console.log('Disconnected from Binance WebSocket');
//     });

//     return () => {
//       socket.close();
//     };
//   }, [theAssetValues]);



  useEffect(() => {
    //   getGroupMembers();
      tradingHistory();
      tradeOrders();
      // startTrade();
  },[])
  useEffect(() => {
    // // console.log(lastPrice, 'updated lastPrice');
  }, [market]);
  

   // ARCHIVE ACCOUNT

   const [theAccountId, setTheAccountID] = useState('');

   const handleArchive = async (e) => {
     e.preventDefault();
     // console.log(e.target.value, );
    setTheAccountID(e.target.value); 
    // console.log(ARCHIVE_URL+data);
 
     const tradingConfig = {
       method: "get",
       url: ARCHIVE_URL+data,
       // data: {
       // },
        headers: {
           'Content-Type': 'application/json',
              'accept': '*/*',
               Authorization: `Bearer ${token}`,
           },
           // withCredentials: true
     };
 
     try {
       await axios(tradingConfig).then((result) => {
         // console.log(result);
         // const decoded = jwtDecode(result.data.token);
         // console.log(result.data.token);
         // console.log(result);
         
         setSuccessful(true);
         alert("Account archived successfully");
         window.location.href = "/";
       }).catch((error )=> {
         // console.log("this error");
         // console.log(error);
         // console.log(error.response.data.message);  
         
       }
       )
 
     } catch (err) {
 
       // this part might just be redundant but leave it here
        
       // errRef.current.focus();
     }
   
   }

   // MAKE PAIR LOWER CASE
   function toLowerCase(currencyPair) {
    if (typeof currencyPair !== 'string') {
      throw new Error('The argument must be a string');
    }
  
    if (currencyPair.toLowerCase() === currencyPair) {
      return currencyPair;
    }
  
    return currencyPair.toLowerCase();
  }

//   const [marketData, setMarketData ] = useState(null)

//   useEffect(() => {
//     const socketIntervals = [];
  
//     theAssetValues.forEach((asset) => {
//       const lowAsset = toLowerCase(asset)+'usdt';
//       const socket = new WebSocket(
//         `wss://stream.binance.com:9443/ws/${lowAsset}@ticker`
//       );
  
//       socket.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         setMarketData(data);
//       };
  
//       const interval = setInterval(() => {
//         if (socket.readyState === WebSocket.OPEN) {
//           socket.send(JSON.stringify({ type: 'ping' }));
//         }
//       }, 90000);
  
//       socketIntervals.push({ socket, interval });
//     });
  
//     return () => {
//       socketIntervals.forEach(({ socket, interval }) => {
//         clearInterval(interval);
//         socket.close();
//       });
//     };
//   }, [theAssetValues]);
  
 
const [marketData, setMarketData] = useState({});
const [tradingAssets, setTradingAssets] = useState([]);

const [tradingAssetLoaded, setTradingAssetLoaded] = useState(false);

const updateChange = (marketData) => {
  const updatedAssets = tradingAssets.map(( asset ) => {
    const symbolKey = asset.symbol.replace("/", "").toLowerCase();
    const currentPrice = symbolKey in marketData ? parseFloat(marketData[symbolKey]) : null;
    if (currentPrice) {
      const orderPrice = parseFloat(asset.price);
      const orderQty = parseFloat(asset.origQty); // Get the order quantity
      let change = (currentPrice - orderPrice) * orderQty; // Calculate change based on the quantity
      return {
        
          ...asset,
          change,
        
         
      };
    } else {
      return {  asset };
    }
  });

  setTradingAssets(updatedAssets);
};

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.lanealitrade.com/common/trading-orders/'+location.state);
      // console.log('https://api.lanealitrade.com/common/trading-orders/'+location.state, 'fetching data');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // console.log('data is available');
      // console.log(data?.trades, 'make i see u');
      setTradingAssets(data?.trades || []);
      setTradingAssetLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log("useEffect for fetchData called");
    fetchData();
  }, []);

  let flag = false;

  useEffect(() => {
    // console.log('useEffect for SocketCalled');
    if (tradingAssets.length === 0) {
      return;
    }

    const client = new w3cwebsocket(
      `wss://stream.binance.com:9443/ws/${tradePairs.join('@ticker/mini/')}@ticker/mini`
    );

    client.onopen = () => {
      // console.log('WebSocket Client Connected');
    };

    client.onmessage = (message) => {
      const data = JSON.parse(message.data);
      setMarketData((prevData) => {
        const updatedData = {
          ...prevData,
          [data.s.toLowerCase()]: data.c,
        };
        updateChange(updatedData);
        return updatedData;
      });
    };

    client.onclose = () => {
      // console.log('WebSocket Client Disconnected');
    };

    return () => {
      client.close();
    };
  }, [tradingAssetLoaded]);


  
  const cancelOrder = async (e) => {
    e.preventDefault();
    // console.log(e.target.id,'id' );
  //  setTheAccountID(e.target.value); 
   // console.log(CANCEL_ORDER+e.target.id);

    const tradingConfig = {
      method: "post",
      url: '',
      url: CANCEL_ORDER+e.target.id,
      // data: {
      // },
       headers: {
          'Content-Type': 'application/json',
             'accept': '*/*',
              Authorization: `Bearer ${token}`,
          },
          // withCredentials: true
    };

    try {
      await axios(tradingConfig).then((result) => {
        // console.log(result);
        // const decoded = jwtDecode(result.data.token);
        // console.log(result.data.token);
        // console.log(result);
        
        setSuccessful(true);
        alert("Trade Canceled");
        // window.location.href = "/";
        window.location.reload(true)
      }).catch((error )=> {
        // console.log("this error");
        // console.log(error);
        // console.log(error.response.data.message);  
        
      }
      )

    } catch (err) {

      // this part might just be redundant but leave it here
       
      // errRef.current.focus();
    }
  
  }

    

 
  return (
    <div>
        <div>
            <TopMenu />
        </div>
        <div className=' pt-6 ml-6 mr-4  h- '>
            <div className=' lg-flex j-sb pb-1'>
                <h1 className=''>{account.name}</h1>
                
                <div className='lg-flex'>
                <Popup trigger={<button  className=" popup-btn bg-red white mr-1 h-100 fw-100 pointer" >
                        Archive
                    </button>}
                      modal
                      nested
                  >
                      {
                          close => (
                              <div className="modal lg-flex mb-flex j-center align-center">
                                  <div className=" lg-flex mb-flex j-center border-radius-30 border-1-purple align-center h-50 w-50 bg-white">
                                      <div className=" pl-2 pr-2 text-center">
                                          <div className="">
                                              <img src={Good} alt="account creation successful"/>
                                          </div>
                                          <div className="pt-4">
                                              <h1 className="f-14 fw-700 pr-1 ">Are you sure you want to archive {account.name}? </h1>
                                          </div>
                                          <div className="pt-4">
                                          <div className="lg-flex mb-flex j-center">
                                          
                                              <Link to={'/home'}>
                                                  <button onClick={handleArchive} className=" popup-btn bg-purple white mr-1 h-100 fw-100"   ><p className=" f-12 fw-100">Archive</p></button>
                                              </Link>
                                                 
                                              <button className="pointer pl-1 pr-1 pt-1 pb-1 popup-btn bg-white purple" onClick={close}><p className="f-12">Cancel</p></button>
                                              </div>
                                          </div>
                                          
                                      </div>
                                  </div>
                              </div>
                          )
                      }
              </Popup>
                    
                    <button  className=" popup-btn bg-purple white mr-1 h-100 fw-100 pointer"  onClick={goBack} >
                        Back
                    </button>
                    <Link to={"/trade"} className='popup-btn pt-1 text-none pb-1 bg-purple white mr-1 h-100 fw-100 pointer' state={account.id} id={account.id} >
                        Goto Trade
                    </Link>
                </div>
            </div> 
            <div className=' '>
  
                <div className='h-50 w-100 lg-flex '>
                    <div className=' w-100 border-1 border-radius-5 mb-2 mr-1'>
                        <div class=" fw-600 pt-1 pb-1 pl-1 pr-1 history-color">
                            <div class="">ACTIVE TRADES</div>
 
                        </div>
                        <div class="table w 0">
                        
                            {/* <div class="row"> */}
                                <div class="row fw-700">
                                    <div class="cell">Symbol</div>
                                    <div class="cell">Order ID</div>
                                    <div class="cell">Price</div>
                                    <div class="cell">Stop Price</div>
                                    <div class="cell">Trade Type</div>
                                    <div class="cell">Quantity</div>
                                    <div class="cell">Status</div>
                                    <div class="cell">Side</div>
                                    <div class="cell">Change</div>
                                </div>
                            {/* </div> */}
                            {/* <div class="row"> */}
                                {tradingAssets.filter(singleTrade => singleTrade.status !== "FILLED").sort((a, b) => b.time - a.time).map(({ item }, index) => {
                                  // console.log(tradingAssets, ' this herer')
                                  return (
                                    <div className='row' key={index}>
                                        {/* <div class="cell">{item.symbol}</div>
                                        <div class="cell">{item.orderId}</div>
                                        <div class="cell">{item.price}</div>
                                        <div class="cell">{item.stopPrice}</div>
                                        <div class="cell">{item.tradeType}</div>
                                        <div class="cell">{item.origQty}</div>
                                        <div class="cell">{item.status}</div>
                                        <div class="cell">{item.type}</div>
                                        <div class="cell">{item.change.toFixed(2)}</div>
                                        <div onClick={cancelOrder} id={item.id}> x </div> */}
                                    </div>)}
                                )}
                             
                                                    
                        </div>
                    </div>
                
                    <div className='w-30 border-1 border-radius-5 mb-2'>
                        <div class=" fw-600 pt-1 pb-1 pl-1 pr-1 history-color">
                            <div class="">BALANCE</div>
                            
                        </div>
                        <div class="table w-100">
                                <div class="row fw-600 ">
                                    {/* <div class="cell">SN</div> */}
                                    <div class="cell">Currency</div>
                                    <div class="cell">Balance</div>
                                    
                                </div>
                                {theMergedBalances?.length > 0 ? (
                                  // // console.log( "free balances", account.accountBalances.filter(freeBalances => freeBalances < 0.00)),
                                  
                                  theMergedBalances.map((groupOne, index) => {
                                    return (
                                        <div class="row f-14">
                                            <div class="cell">{groupOne.asset}</div>
                                            <div class="cell">
                                              <div>
                                              {groupOne.freeBalance}
                                              </div>
                                              {groupOne.convertedBalance}
                                              </div>
                                        </div>                     
                                    )
                                })) : (
                                    <div></div>
                                )}
                                {/* {theFilteredBalance?.length > 0 ? (
                                  // // console.log( "free balances", account.accountBalances.filter(freeBalances => freeBalances < 0.00)),
                                  
                                  theFilteredBalance.map((groupOne, index) => {
                                    return (
                                        <div class="row f-14">
                                            <div class="cell">{groupOne.asset}</div>
                                            <div class="cell">{groupOne.freeBalance}</div>
                                        </div>                     
                                    )
                                })) : (
                                    <div></div>
                                )} */}
                                {/* {account.accountBalances?.length > 0 ? (
                                  // // console.log( "free balances", account.accountBalances.filter(freeBalances => freeBalances < 0.00)),
                                  
                                  account.accountBalances.map((groupOne, index) => {
                                    return (
                                        <div class="row f-14">
                                            <div class="cell">{groupOne.asset}</div>
                                            <div class="cell">{groupOne.freeBalance}</div>
                                        </div>                     
                                    )
                                })) : (
                                    <div></div>
                                )} */}
                                {/* {account.accountBalances.filter(freeBalances => freeBalances !== "0.00") */}
                                {/* // // console.log(object); */}
                                {/* } */}
                                                    
                        </div>
                    </div>
                </div>
            </div>
            <div>
            <div className='w-100 border-1 border-radius-5 mb-2'>
                    <div class=" fw-600 pt-1 pb-1 pl-1 pr-1 history-color">
                            <div class="">TRADING HISTORY</div>
                            
                        </div>
                        <div class="table w-100">
                        
                            {/* <div class="row"> */}
                                <div class="row fw-700">
                                    <div class="cell">Symbol</div>
                                    <div class="cell">Order ID</div>
                                    <div class="cell">Price</div>
                                    <div class="cell">Stop Price</div>
                                    <div class="cell">Trade Type</div>
                                    <div class="cell">Quantity</div>
                                    <div class="cell">Status</div>
                                    <div class="cell">Side</div>
                                    <div class="cell">Change</div>
                                </div>
                            {/* </div> */}
                            {/* <div class="row"> */}
                                {account.tradingAssets?.length > 0 ? 
                                (
                                  account.tradingAssets.map((item, index) => ( 
                                    <div className='row' key={index}>
                                        <div class="cell">{item.symbol}</div>
                                        <div class="cell">{item.orderId}</div>
                                        <div class="cell">{item.price}</div>
                                        <div class="cell">{item.stopPrice}</div>
                                        <div class="cell">{item.tradeType}</div>
                                        <div class="cell">{item.origQty}</div>
                                        <div class="cell">{item.status}</div>
                                        <div class="cell">{item.type}</div>
                                        <div class="cell">{item.change.toFixed(2)}</div>
                                        
                                    </div>)
                                )
                                ) : <>
                                nada
                                </>
                              }
                            {/* </div> */}
                        
                                {/* <div class="row fw-600 ">
                                    <div class="cell"> Symbol</div>
                                    <div class="cell"> Order ID</div>
                                    <div class="cell">Price</div>
                                    <div class="cell">Market Price</div>
                                    <div class="cell">Amount</div>
                                    <div class="cell">Status</div>
                                    
                                </div> */}
                                {/* {trades?.length > 0 ? (
                                    trades.filter(singleTrade => singleTrade.item.status === "FILLED")
                                        .sort((a, b) => b.item.time - a.item.time)
                                        .map((singleTrade, index) => {
                                            const symbol = singleTrade.item.symbol;
                                            const marketPrice = market[symbol]?.lastPrice;
                                            return (
                                                <div class="row f-14" key={index}>
                                                    <div class="cell">{symbol}</div>
                                                    <div class="cell">{singleTrade.item.side}</div>
                                                    <div class="cell">{singleTrade.item.price}</div>
                                                    <div class="cell">{marketPrice - singleTrade.item.price}</div>
                                                   
                                                    <div class="cell">{singleTrade.item.origQty}</div> 
                                                    <div class="cell">{singleTrade.item.status}</div> 
                                                    
                                                </div>
                                            );
                                        })
                                ) : (
                                    <div>No History</div>
                                )} */}
                                                    
                        </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default ViewAccount
import React, { useEffect, useState } from 'react'

// import TakeProfit from '../../assets/take-profit.svg'
import Eye from '../../assets/eye.svg'
// import Trade from '../../assets/trade.svg'
// import StopLoss from '../../assets/stop-loss.svg'  

import { Link } from 'react-router-dom'
import SideNav from '../../components/SideNav'
import TopMenu from '../../components/TopMenu'

// import Good from '../../assets/red-mark.svg'
// import Popup from 'reactjs-popup'
import Header from '../../components/Header'
import axios from 'axios'

import Cookies from 'universal-cookie'
import CustomLoader from '../../components/CustomLoader'

const cookies = new Cookies();
const token = cookies.get("TOKEN");

const TradingGroups = () => {

    const [groups, getGroups ] = useState('');
    
    const [loading, setLoading] = useState(false);

    const [filteredFeature, setFilteredFeatures] = useState([]);

    const [filteredSpot, setFilteredSpot] = useState([]);

    
  const [spotTab, setSpotTab] = useState('future');


    const getAllGroups = async () =>  {
        try {
            setLoading(true)
            await axios.get(
                'https://api.lanealitrade.com/common/trading-segment-accounts',
                {
                    headers: {
                    'Content-Type': 'application/json',
                     'accept': '*/*',
                     Authorization: `Bearer ${token}`,
                     }
                }
                ).then(
                (response) => {
                    // console.log(response);
                    // console.log('response.data');
                    // console.log(response.data, 'all');
                     
                    const allGroups = response.data;
                  allGroups.map((group) => {
                        group.accountSegments.map((type) => {
                            // console.log(type.account.accountType, 'now')
                        })
                    })

                                        
                    const spot = allGroups.filter((group) => {
                        return group.accountSegments.some((type) => {
                        return type.account.accountType === "spot";
                        });
                    });
                    
                    const futures = allGroups.filter((group) => {
                        return group.accountSegments.some((type) => {
                        return type.account.accountType === "features";
                        });
                    });

                    setFilteredFeatures(futures);
                    setFilteredSpot(spot);
                    // console.log(spot,'spot')
                    // console.log(futures,'features')
                    getGroups(allGroups);
                    setLoading(false)
                }
            ).catch((error) => {
                // console.log(error);
                setLoading(false)
            } )
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getAllGroups();
    },[])

    return (
        <div>
            <TopMenu />
            <SideNav />
            <Header />
            <div className='main-doc'> 
                <div className='pt-1 pb-1 pl-1 pr-1'>
                     
                    {/*  */}
                    
                    {loading ? ( 
                    <CustomLoader />
                    ) : groups.length > 0 ? (

                        groups.map((group, index) => {
                           // console.log(group.accountSegments?.length, 'this length?');
                            return (
                    <div className='pt-1 pb-2'>
                        <div className='lg-flex border-1 border-radius-5  pl-2 pr-2 pb-1'>

                            <div className=' pt-2'>
                                <h2 className='fw-700   pr-2'>{index + 1}</h2> 
                            </div>
                            <div className='lg-flex flex-wrap'>
                                <div className='pr-2 pt-2'>
                                <h2 className='fw-700  '>Account name</h2>
                                <p>{group.name}</p>
                                </div>
                                {/* <div className='pr-2 pt-2'>
                                    <h2 className='fw-700  '>Actions</h2>
                                        <p className='' value={'archive'}>Archive group</p>
                                    
                                </div> */}
                                <div className='pr-2 pt-2'>
                                    <h2 className='fw-700 f-14'>Total number of accounts </h2>
                                    <p>{group?.accountSegments?.length}</p>
                                </div>
                                {/* <div className='pr-2 pt-2'>
                                    <h2 className='fw-700 f-14'>Group ID </h2>
                                    <p>BI8273799</p>
                                </div> */}
                               
                                {/* <div className='pr-2 pt-2 pr-4'>
                                    <h2 className='fw-700 f-14'>Sum Profits</h2>
                                    <p>$99999999999</p>
                                </div> */}

                                {/* second */}
                                <div className='lg-flex mb-flex '>
                                    {/* <div className='pr-2 pt-2'>
                                        <h2 className='fw-700  '>Total loss</h2>
                                        <p>$0</p>
                                    </div> */}
                                    <div className='  pr-2 pt-2 text-center pointer grid ji-center '>
                                        {/* <Link to={'/group-trade'} state={group.id} id={group.id} className='link' >
                                        <div className='icon-box mb-flex j-center lg-flex align-center'>
                                            <img src={Trade} alt='trade' />
                                        </div>
                                        <h2 className='fw-700  '>Trade</h2>
                                        </Link> */}
                                    </div>
                                    {/* <div className='  pr-2 pt-2 text-center pointer grid ji-center'> */}
                                        

                                        {/* POPUP */}



                                        
                                    {/* VIEW */}

                                    <Link className='text-none' to={'/view-trading-groups'} state={group.id} >
                                        <div className='pr-2 pt-2 text-center pointer grid ji-center'>
                                            <div className='icon-box mb-flex j-center lg-flex align-center'>
                                                <img src={Eye} alt='stoploss' />
                                            </div>
                                            <h2 className='fw-700  '>View</h2>
                                        </div>
                                    </Link>
                                    
                                </div>

                                {/* <div className='text-end lg-flex align-end flex mb-flex w-100 j-end pt- -2'>
                                <div className=''>
                                    <div className='gain'>
                                    +
                                    </div>
                                    <p>Gain</p>
                                </div>
                                <div className=''>
                                    <div className='loss'>
                                    -
                                    </div>
                                    <p>loss</p>
                                </div>
                                </div> */}
                            </div>
                        
                        </div>
                    </div>
                            )
                        })
                    ) : 
                    (
                        <div>
                            No groups
                        </div>
                    )

                    }
                    
                </div>
            </div>
        </div>
      )
}

export default TradingGroups